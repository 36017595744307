import React from 'react'
import './Features.css'
const Features = ({heading1,heading2,features}) => {
  return (
    <div className="row">
      <h5 className='mb-lg-4 mb-3'>{heading1}</h5>
      <h1 className='mb-lg-5 mb-3 fw-bold'>{heading2}</h1>
      <ul className='feature-list'>
        { features }
      </ul>
  </div>
  )
}

export default Features
