import React from 'react'
import './Card.css'
const Card = ({class_name,image,image_name,heading,content}) => {
  return (
             <div className="col-lg-4 col-md-4 col-sm-6">
              <div className={`p-5  corporation-card ${class_name} `}>
                <img className='img-fluid mb-2' src={image} alt={image_name} />
                <h5 className='mb-3'>{heading}</h5>
                <p>{content} </p>
              </div>
            </div>
  )
}

export default Card
