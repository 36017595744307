import './ITConsultancy.css';
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/it-consultancy/Hero.jpg'
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/it-consultancy/TopImage.jpg'
import Research from '../../assets/images/it-consultancy/search.png'
import planning from '../../assets/images/it-consultancy/planning-strategy.png'
import rocket from '../../assets/images/it-consultancy/rocket.png'
import growth from '../../assets/images/it-consultancy/growth.png'
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const ITConsultancy = () => {



  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="IT Consultancy"
        page_name="IT Consultancy"
        heading="Your goals, our expertise"
      />

      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          class_name="top-lower"
          heading="Guiding Businesses Through Digital Transformation with IT Consulting"
          content="At TechTorch Solutions, our Information Technology Consulting service is designed to guide businesses through their digital transformation journey. We consult with organizations on how to effectively adapt to digitalization and modernize their existing systems, ensuring they remain competitive in an ever-evolving marketplace."
          image={TopImage}
          image_name=""
          paras={[
            <p>
              Our comprehensive consulting approach includes conducting thorough IT audits, where
              we perform random vigilance drives tailored to the specific needs of each business. This
              helps identify potential vulnerabilities, inefficiencies, and areas for improvement, enabling
              organizations to enhance their IT infrastructure and operational effectiveness. Through
              strategic insights and actionable recommendations, we empower our clients to leverage
              technology for sustainable growth and innovation.
            </p>

          ]}

        />
      </div>

      {/* End Top Section  */}

      {/* Start Our approach  */}
      <div className="bg-light-beetroot py-lg-5 py-3">
        <div className="container">
          <div className="row mb-4">
            <h5 className='our-approach-heading'>How We Deliver</h5>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="our-approach-content">
                <img src={Research} alt="Research" />
                <p>Activate Research </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="our-approach-content">
                <img src={planning} alt="planning" />
                <p>Drawing Strategy </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="our-approach-content">
                <img src={rocket} alt="rocket" />
                <p>Kickstart implementation</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6">
              <div className="our-approach-content">
                <img src={growth} alt="growth" />
                <p>Boost Next-Gen</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Our approach  */}


      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Value"
          heading2="Empower your vision, unlock your business’s full potential."
          features={[
            <li>
              <span>Innovative Solutions: </span>
              Embrace creativity to drive your business forward.
            </li>,
            <li>
              <span>Strategic Growth: </span>
              Unleash strategies that amplify your market reach.
            </li>,
            <li>
              <span>Sustainable Success: </span>
              Build a foundation for long-term profitability.
            </li>,
            <li>
              <span>Enhanced Efficiency: </span>
              Optimize operations for maximum productivity.
            </li>,
            <li>
              <span>Unmatched Expertise: </span>
              Leverage industry knowledge for superior results.
            </li>,



          ]}
        />
      </div>
      {/* end Key Features section   */}

          <DeliveringSolutions/>
    </>
  );
};

export default ITConsultancy;
