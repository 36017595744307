import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Cyber/Cyber Security.jpg'
import cyber1 from '../../assets/images/Cyber/cyber1.png'
import cyber2 from '../../assets/images/Cyber/cyber2.png'
import cyber3 from '../../assets/images/Cyber/cyber3.png'
import cyber4 from '../../assets/images/Cyber/cyber4.png'
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';


const Cyber_Security = () => {
  return (
    <div>
      {/* Hero Section  */}
      <Hero
      image={heroImage}
      image_name="Cyber Security"
      page_name="Cyber Security"
      heading="Securing Your Future: Reliable Cybersecurity Services"
      />
    {/* End Hero Section  */}


    {/* Second Section  */}
    <div className='bg-light-beetroot mt-5 py-lg-5 py-md-4 py-3'>
      <div className="container">
        <div className="row">
          <h2 className='mb-4'>Comprehensive Cybersecurity for Unyielding Digital Protection</h2>
          <div className="col-12">
            <p>
              At TechTorch Solutions, we prioritize the protection of your digital assets through our comprehensive cybersecurity services. In an era where cyber threats are ever-evolving, our solutions are designed to safeguard your organization against data breaches, malware, and other security vulnerabilities. We offer a range of services, including identity management, threat detection, and secure access control, tailored to meet the unique needs of your business.
            </p>
            <p>
            Our proactive approach involves continuous monitoring and risk assessment, ensuring that your systems remain resilient against potential threats. With our expertise, you can focus on your core business operations while we protect your sensitive information and maintain your compliance with industry regulations.
            </p>

          </div>
        </div>
      </div>
    </div>
    {/* End Second Section  */}

    {/* Third Section  */}
    <div className="container py-lg-5 py-md-4 py-3">
      <div className="row flex-column-reverse flex-md-row">
        <div className="col-lg-7 col-md-7 col-sm-6">
          <ul>
           <li className='mb-lg-4'>
            <h5>Managed Detection and Response (MDR):</h5>
              <p>
              Provide 24/7 monitoring, threat detection, and incident response. Use AI-powered
              analytics to detect anomalies and respond to potential breaches in real time.
              </p>
           </li> 

            <li className='mb-lg-4'>
            <h5>Security Information and Event Management (SIEM):</h5>
            <p>
              Offer a SIEM platform that collects, analyzes, and reports on log data from various sources to identify suspicious activities and provide actionable insights.
              </p>

            </li>

            <li>
            <h5>Identity and Access Management (IAM):</h5>
            <p>
              Develop solutions to help businesses manage user identities and control access to critical systems, ensuring the right people have the right access at the right time.
              </p>
            </li>
            
           
           </ul>
            
        </div>

        <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber1} alt="" />
        </div>
      </div>

       <div className="row mt-5">
      <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber2} alt="" />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-6">
        <ul>
           <li className='mb-lg-4'>
            <h5>Endpoint Protection:</h5>
              <p>Provide cloud-based security solutions to protect clients' endpoints (laptops, mobile devices, servers) from malware, ransomware, and phishing attacks. 
              </p>
           </li> 

            <li className='mb-lg-4'>
            <h5>Vulnerability Management and Scanning:
            </h5>
            <p>
            Offer continuous vulnerability scanning to identify potential weaknesses in a client’s network, applications, and infrastructure. Provide actionable reports to fix these vulnerabilities.
              </p>

            </li>

            <li>
            <h5>Cloud Security:</h5>
            <p>
            Help businesses secure their cloud environments (AWS, Azure, Google Cloud) by offering services like encryption, compliance monitoring, and secure configuration management.

            </p>
            </li>
            
           
           </ul>
        </div>
      </div>


      <div className="row mt-5 flex-column-reverse flex-md-row">
        <div className="col-lg-7 col-md-7 col-sm-6">
           <ul>
           <li className='mb-lg-4'>
            <h5>Data Loss Prevention (DLP):</h5>
            <p>Provide SaaS solutions that help companies monitor and control the movement of sensitive data, protecting against accidental or malicious leaks.</p>
           </li> 

            <li className='mb-lg-4'>
            <h5>Email Security:</h5>
            <p>Offer advanced email filtering services to prevent phishing, spam, and malware from
            entering corporate email systems.</p>
            </li>

            <li>
            <h5>Threat Intelligence:</h5>
            <p>Provide real-time threat intelligence that collects and analyzes global cyber threat data to help clients proactively defend against emerging risks.</p>
            </li>
          
           </ul>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber3} alt="" />
        </div>
      </div>


      <div className="row mt-5">
      <div className="col-lg-5 col-md-5 col-sm-6">
            <img className='img-fluid' src={cyber4} alt="" />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-6">
        <ul>
           <li className='mb-lg-4'>
            <h5>Penetration Testing as a Service:</h5>
            <p>Offer regular, automated penetration testing to simulate cyberattacks and identify weaknesses before they are exploited by malicious actors.
          </p>
           </li> 

            <li className='mb-lg-4'>
            <h5>Compliance Management Solutions:</h5>
            <p>Help companies stay compliant with regulations such as GDPR, HIPAA, or PCI DSS by
            providing automated tools for audit tracking, risk assessments, and policy enforcement.
            </p>
            </li>

            <li>
              <h5>Cybersecurity Awareness Training:</h5>
              <p>Provide training platforms to help employees recognize and avoid cybersecurity threats like phishing and social engineering.</p>
            </li>
          
           </ul>
      </div>
      </div>

    </div>
    {/* End Third Section  */}

    <DeliveringSolutions/>
    </div>
  )
}

export default Cyber_Security
