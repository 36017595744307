import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Aviation Management/Aviation Management.jpg'
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/Aviation Management/Aviation Management1.jpg';
import Item from '../../components/Our Approach/Item';
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';



const Aviation_Management = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Aviation Management"
        page_name="Aviation Management"
        heading="Aviation Operations and Administration"
      />

      {/* end hero section  */}


      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Elevating Aviation with TechTorch Solutions"
          content="TechTorch Solutions Private Limitedprovides innovative Aviation Management solutions designed to enhance operational efficiency, safety, and compliance within the aviation industry. Our SaaS platform caters to airlines, airports, and aviation service providers, offering tools that streamline operations and improve overall service quality."
          image={TopImage}
          image_name="Aviation Management"
          paras={[
            <p>
              <span>Industries We Serve with Aviation Management Solutions: </span>
              Our solutions are tailored for various sectors within the aviation industry, including commercial airlines, cargo carriers, airports, and aviation service providers, addressing unique operational challenges and regulatory compliance requirements.

            </p>,
            <p>
              <span>Why Choose TechTorch Solutions Private Limitedfor Aviation Management? </span>
              Our Aviation Management solutions are built on deep industry expertise and a commitment to innovation. We provide comprehensive implementation support, continuous training, and updates to ensure that clients remain at the forefront of the aviation industry. With our flexible and scalable platform, organizations can adapt to changing market dynamics while enhancing operational efficiency and passenger satisfaction.
            </p>
           
          ]}

        />
      </div>

      {/* End Top Section  */}

       {/* Start Our approch section  */}
       <div className="bg-light-beetroot py-lg-5 py-3">
        <div className="container">
          <div className="row">
            <h5 className='our-approach-heading'>Our Approach</h5>
          </div>
          <div className="row">
            <Item
            icon={<i class="bi bi-cloud"></i>}
            text="Enable business vision"
            />

          <Item
            icon={<i class="bi bi-box"></i>}
            text="Empower data-led decisions"
            />

          <Item
            icon={<i class="bi bi-clouds"></i>}
            text="Build resilience"
            />
             <Item
            icon={<i class="bi bi-person"></i>}
            text="Accelerate innovation"
            />
            
          </div>
        </div>
      </div>
      {/* End Our approch section  */}

     {/* start Key Features section  */}
     <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Aviation Management Solutions:"
          features={[
            <li>
              <span>Flight Operations Management: </span>
              Automates and optimizes flight planning, scheduling, and monitoring, ensuring timely departures and arrivals while maximizing aircraft utilization.
             
            </li>,
            <li>
              <span>Maintenance Management: </span>
              Facilitates effective tracking of aircraft maintenance schedules, compliance with regulatory requirements, and management of spare parts inventory, enhancing aircraft safety and reliability.
            </li>,

            <li>
              <span>Passenger Services:</span>
              Enhances passenger experience through efficient check-in processes, real-time updates, and personalized services, improving customer satisfaction and loyalty.
            </li>,
            <li>
              <span>Revenue Management: </span>
              Utilizes advanced analytics to optimize pricing strategies, forecast demand, and maximize revenue opportunities, ensuring competitive positioning in the market.
            </li>,
            <li>
                <span>Safety and Compliance Monitoring: </span>
                Incorporates tools for monitoring safety protocols and compliance with aviation regulations, ensuring adherence to industry standards and promoting a culture of safety.
            </li>


          ]}
        />
      </div>
      {/* end Key Features section   */}
        
        <DeliveringSolutions/>
    </>
  )
}

export default Aviation_Management
