import React from 'react'
import './BackgroundVideo.css';
import Slider from '../../components/Slider/CarouselComponent'
const BackgroundVideo = () => {
  return (
    <div className="video-container">
      <video autoPlay loop muted playsInline className="background-video">
        <source src="https://videos.pexels.com/video-files/7688001/7688001-uhd_2560_1440_30fps.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <Slider/>
      </div>
    </div>
  )
}

export default BackgroundVideo
