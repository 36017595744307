import React from 'react'
import { Link } from 'react-router-dom'
import './TabCard.css'

const TabCard = ({heading,content,link}) => {
  return (
    <div className='card tab-card p-4'>
      <h4 className='mb-lg-3'>{heading}</h4>
      <p>{content}</p>
      <p className='mb-0'><Link to={link}><button className='learn-more-btn'>LEARN MORE</button></Link></p>
    </div>
  )
}

export default TabCard
