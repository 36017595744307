import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FaSearch } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Logo.png';
import './Navbar.css';
import PdfDownloadButton from '../PdfDownloadButton/PdfDownloadButton';
import QuickBrochure from '../Quick Brochure/Quick_Brochure';


const NavigationBar = () => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const submenuRef = useRef(null);
  const [isActiveService, setIsActiveService] = useState(false);
  const [isActiveDS, setIsActiveDS] = useState(false);
  const [isActivePP, setIsActivePP] = useState(false);
 

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchClick = () => {
    setSearchVisible(!searchVisible);
  };

  const openSubMenu = (id) => {
    setActiveSubMenu(id);


  };

  const closeSubMenu = () => {
    setActiveSubMenu(null);

  };

  const handleNavClick = () => {
    setIsNavbarCollapsed(true);
    closeSubMenu();
   
   
  };

  const handleClick = ()=>{
    closeSubMenu();
    handleNavClick();
  }

  const showOurService=()=>{
     setIsActiveService(!isActiveService)
     
  }

  const showDS=()=>{
    setIsActiveDS(!isActiveDS)
    
    

 }

 const showPP=()=>{
  setIsActivePP(!isActivePP)
}

const hideMenu=()=>{
  setIsNavbarCollapsed(!isNavbarCollapsed)
  closeSubMenu();
}

  
  
  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className={`custom-navbar  ${scrolled ? 'scrolled' : ''}`}
        expanded={!isNavbarCollapsed}
      >
        <div className='container'>

          <Navbar.Brand as={Link} to="/" onClick={handleNavClick}>
            <img src={logo} alt="Logo" className="navbar-logo" />
          </Navbar.Brand>

          <i className="bi bi-list navbar-toggler" onClick={hideMenu}></i>


          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto menu-link-container">
              {['capabilities', 'industries', 'insights', 'discover'].map((item) => (
                <Nav.Link
                  key={item}
                  className='nav-link'
                  onMouseEnter={() => openSubMenu(item)}
                  // onClick={handleNavClick}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                  <IoIosArrowDown className={`dropdown-arrow ${activeSubMenu === item ? 'open' : ''}`} />
                </Nav.Link>
              ))}

            <Nav.Link
                as={Link}
                to="/career"
                className='nav-link'
                onMouseLeave={closeSubMenu}
                onClick={handleNavClick}
              >
                Career
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/clients"
                className='nav-link'
                onMouseLeave={closeSubMenu}
                onClick={handleNavClick}
              >
                Clients
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                className='nav-link'
                onMouseLeave={closeSubMenu}
                onClick={handleNavClick}
              >
                Contact Us
              </Nav.Link>
              <Nav.Link onClick={handleSearchClick} className="d-lg-none nav-link">
                <FaSearch /> Search
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Button
            variant="outline-secondary"
            onClick={handleSearchClick}
            className="search-icon-btn"
          >
            <FaSearch />
          </Button>
        </div>

      </Navbar>

      <div className={`search-bar-container  ${searchVisible ? 'visible' : ''}`}>
        <Container>
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Container>
      </div>

      {activeSubMenu && (
        <div
          className="submenu-overlay"
          ref={submenuRef}
          onMouseLeave={closeSubMenu} 
        >

          {/*start Sub menu  */}
          <div
            className="submenu-content "
            onMouseEnter={() => openSubMenu(activeSubMenu)}
            onMouseLeave={closeSubMenu} 
          >
            {activeSubMenu === 'capabilities' && (
              <div className='capabilities-container'>

                <div className='our-services-container'>
                  <h4 onClick={showOurService}>Our Services <i className="bi bi-chevron-down"></i></h4>
                  <ul className={isActiveService?'':'hide'}>
                    <li><Link to="/it-consultancy" onClick={handleClick}>IT Consultancy</Link></li>
                    <li><Link to="/artificial-intelligence" onClick={handleClick}>Artificial Intelligence (AIaas)</Link></li>
                    <li><Link to="/cloud-infrastructure" onClick={handleClick}>Cloud Infrastructure Services (IaaS)</Link></li>
                    <li><Link to="/cyber-security" onClick={handleClick}>Cyber Security</Link></li>
                    <li><Link to="/software-engineering-services" onClick={handleClick}>Software Engineering Services</Link></li>
                    <li><Link to="/business-process-outsourcing" onClick={handleClick}>Business Process Outsourcing </Link></li>
                    <li><Link to="/software-developer" onClick={handleClick}>Software Development & Support </Link></li>
                    <li><Link to="/resource-staffing" onClick={handleClick}>Resource and Staffing</Link></li>
                  </ul>
                </div>

                <div className='sub-menu-digital-container'>
                  <h4 onClick={showDS}>Digital Solutions(Saas) <i className="bi bi-chevron-down"></i></h4>
                  <ul className={isActiveDS?'':'hide'}>
                    <li><Link to="/erp" onClick={handleClick}>ERP</Link></li>
                    <li><Link to="/operations-management" onClick={handleClick}>Operations Management</Link></li>
                    <li><Link to="/supply-chain" onClick={handleClick}>Supply Chain Management</Link></li>
                    <li><Link to="/aviation-management" onClick={handleClick}>Aviation Management</Link></li>
                    <li><Link to="/people-resources" onClick={handleClick}>People Resources</Link></li>
                    <li><Link to="/web-portals" onClick={handleClick}>Web Portals</Link></li>
                    <li><Link to="/financial-management" onClick={handleClick}>Financial Management</Link></li>
                    <li><Link to="/payment-management" onClick={handleClick}>Payment Management</Link></li>
                    <li><Link to="/healthcare-management" onClick={handleClick}>HealthCare & Hospital Management</Link></li>
                    <li><Link to="/crm" onClick={handleClick}>CRM</Link></li>
                    <li><Link to="/ecommerce" onClick={handleClick}>Ecommerce</Link></li>
                    <li><Link to="/project-management" onClick={handleClick}>Project Management</Link></li>
                  </ul>
                </div>

                <div className='products-and-platforms-container'>
                  <h4 onClick={showPP}>Products and Platforms <i className="bi bi-chevron-down"></i></h4>
                  <ul className={isActivePP?'':'hide'}>
                    <li><Link to="/corporation" onClick={handleClick}>Corporation24x7</Link></li>
                    <li><Link to="/eduficera" onClick={handleClick}>Eduficera</Link></li>
                    <li><Link to="/tech-mate" onClick={handleClick}>TechMate</Link></li>
                  </ul>
                </div>


              </div>
            )}
            {activeSubMenu === 'discover' && (
              <div>
                <h4>Discover TechTorch</h4>
                <ul>
                  <li><Link to="/company-overview" onClick={handleClick}>Company Overview</Link></li>
                  <li><Link to="/leadership" onClick={handleClick}>Leadership</Link></li>
                  <li><Link to="/idea" onClick={handleClick}>Idea</Link></li>
                  <li><Link to="/investors" onClick={handleClick}>Investors</Link></li>
                  <li><Link to="/partners-ecosystem" onClick={handleClick}>Partners Ecosystem</Link></li>
                </ul>
              </div>
            )}
            {activeSubMenu === 'industries' && (
              <div>
                <h4>Industries</h4>
                <ul>
                  <li><Link to="/finance" onClick={handleClick}>Finance</Link></li>
                  <li><Link to="/education" onClick={handleClick}>Education</Link></li>
                  <li><Link to="/healthcare" onClick={handleClick}>Healthcare</Link></li>
                  <li><Link to="/e-commerce" onClick={handleClick}>E-Commerce</Link></li>
                  <li><Link to="/it" onClick={handleClick}>Information Technology</Link></li>
                  <li><Link to="/manufacturing" onClick={handleClick}>Manufacturing</Link></li>
                  <li><Link to="/telecommunications" onClick={handleClick}>Telecommunications</Link></li>
                  <li><Link to="/energy" onClick={handleClick}>Energy</Link></li>
                  <li><Link to="/transportation" onClick={handleClick}>Transportation</Link></li>
                  <li><Link to="/fmcg" onClick={handleClick}>FMCG</Link></li>
                </ul>
              </div>
            )}
            {activeSubMenu === 'insights' && (
              <div>
                <h4>Insights</h4>
                <ul>
                <li><Link to="/case-studies" onClick={handleClick}>Case Studies</Link></li>
                <PdfDownloadButton btn_name="Company Profile" onClick={handleClick} />
                <QuickBrochure btn_name="Quick Brochure" onClick={handleClick}/>
                </ul>
              </div>
            )}
          </div>
          {/*end Sub menu  */}

        </div>
      )}
    </>
  );
};

export default NavigationBar;
