import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/HealthCare/HealthCare & Hospital Management.jpg';
import Top from '../../components/Top Section/Top/Top';
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const HealthCare = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="HealthCare & Hospital Management"
        page_name="HealthCare & Hospital Management"
        heading="Comprehensive HealthCare Solutions"
      />


      {/* end hero section  */}
    
    {/* Start Top Section  */}

   

    <div className="container py-lg-5 py-md-4 py-3">
      <div className="row">
      <Top
      heading="Healthcare and Hospital Management Solutions"
      content="Our Healthcare and Hospital Management Solutions are designed to transform the way medical facilities operate, providing an integrated platform to streamline operations, improve patient care, and ensure regulatory compliance. These solutions cater to hospitals, clinics, diagnostic centers, and other healthcare providers, offering a comprehensive suite of tools to manage their day-to-day and strategic functions efficiently."
      />
      </div>
    </div>
    {/* End Top Section  */}



      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features "
          features={[
            <li>
              <span>Patient Management: </span>
              Centralized patient registration and data storage. Comprehensive Electronic Medical Records (EMR). Appointment scheduling and queue management.
            </li>,
            <li>
              <span>Staff and Operations Management: </span>
              HR and payroll systems tailored for healthcare staff.
              Duty roster and shift scheduling.
              Performance monitoring and resource allocation.
            </li>,
            <li>
              <span>Inventory and Supply Chain Management: </span>
              Real-time tracking of medical supplies and equipment.
              Automated restocking alerts for critical inventory.
              Vendor and procurement management.
            </li>,
            <li>
              <span>Billing and Financial Management: </span>
              Streamlined patient billing with insurance integration.
              Multi-currency and multi-payment options.
              Financial analytics and reporting for hospital operations.
            </li>,
            <li>
              <span>Clinical and Laboratory Modules: </span>
              Laboratory Information Management Systems (LIMS) for diagnostic workflows.
              Integration with radiology, pharmacy, and pathology systems.
              Seamless sharing of reports and test results with patients and physicians.
            </li>,
            <li>
              <span>Compliance and Security: </span>
              Adherence to healthcare standards like HIPAA and GDPR.
Secure patient data encryption and role-based access control.
Audit trails and reporting for legal and regulatory requirements.
            </li>,
            <li>
              <span>Telemedicine and Patient Engagement: </span>
              Virtual consultations with video conferencing.
Patient portals for access to records, test results, and prescriptions.
Automated follow-ups, reminders, and wellness tips.
            </li>,
            <li>
              <span>Analytics and Insights: </span>
              Data dashboards for tracking key performance indicators (KPIs).
Predictive analytics for patient trends and resource needs.
Customizable reporting for strategic decision-making.
            </li>

          ]}
        />
      </div>
      {/* end Key Features section   */}

        <DeliveringSolutions/>


    </>
  )
}

export default HealthCare
