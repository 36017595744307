import React from 'react'
import Card from '../../components/Youtube/Card';
import image from '../../assets/images/Client/image.png';



const Clients = () => {
  return (
    <>
      <div className="container py-lg-5 py-md-4 py-3">
        <div className="row">
          <h1 className='fw-bold text-center text-dark-beetroot'>Our Client</h1><hr className="main-hr"/>
        </div>

        <div className="row">
          <Card
            image={image}
            image_name="Youtube"
            // video_url="https://www.youtube.com/watch?v=V-UsGuZHAMA"
            client_name="Davidan"
            description="Owner, Pharmaceutical E-commerce Website, New York."
          />

          <Card
            image={image}
            image_name="Youtube"
            // video_url="https://www.youtube.com/watch?v=oe492FoGYng"
            client_name="Shilpi Dixit"
            description="Director, Dizital Mantras & Employment Mantras, Bareilly, India"
         
          />

          <Card
            image={image}
            image_name="Youtube"
            // video_url="https://www.youtube.com/watch?v=LRKodKH456o"
            client_name="Ankit Goel"
            description="Mumbai, India"
         
          />

         
        </div>
      </div>

      
    </>
  )
}

export default Clients