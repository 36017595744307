import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Leadership/hero.jpg'
import Card from '../../components/Leadership/Card';
import img1 from '../../assets/images/Leadership/image1.png'
import img2 from '../../assets/images/Leadership/image2.png'
import img3 from '../../assets/images/Leadership/image3.jpeg'

const Leadership = () => {
    return (
        <div className='about-us'>
            {/* start hero section  */}
            <Hero
                // class_name='hide-image'
                image={heroImage}
                image_name="Leadership"
                page_name="Leadership"
                heading="Leadership"
            />

            {/* end hero section  */}


            {/* Start Executive Members Section  */}
            <div className="container py-lg-5 py-md-4 py-3">
                <div className="row mb-lg-4 mb-3">
                <h1 className='fw-bold text-center text-dark-beetroot'>Executive Members</h1><hr className='main-hr'/>
                </div>
                <div className="row">
                    <Card
                    image={img1}
                    image_name="Executive Members"
                    name="Siddharth Pathak"
                    position="Founder & CEO"
                    linkedin_link="https://www.linkedin.com/in/siddharth-pathak-19b025149?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                    link='/ceo'
                    />

                    <Card
                    image={img2}
                    image_name="Executive Members"
                    name="Amit Gaur"
                    position="Co-Founder & (COO)"
                    linkedin_link="https://www.linkedin.com/in/amit-gaur-4754a278/"
                    link='/coo'
                    />
                </div>
            </div>
            {/* End Executive Members Section  */}


            {/* Start Management Team Sectin  */}
            <div className="container py-lg-5 py-md-4 py-3">
            <div className="row mb-lg-4 mb-3">
                <h1 className='fw-bold text-center text-dark-beetroot'>Management Team</h1><hr className='main-hr'/>
                </div>
            
                <div className="row">
                        <Card
                        image={img3}
                        image_name="Human Resource Director"
                        name="Riya Mishra"
                        position="Human Resource Director"
                        linkedin_link=""
                        link='/hr'
                        />
                </div>
            </div>
            {/* End Management Team Sectin  */}

            {/* Start  Engineering Team Sectin  */}
                {/* <div className="container py-lg-5 py-md-4 py-3">
                    <div className="row mb-lg-4 mb-3">
                        <h1 className='fw-bold text-center text-dark-beetroot'>Engineering Team</h1><hr className='main-hr'/>
                    </div>
                   
                    <div className="row">
                        <Card
                        image={img4}
                        image_name="Human Resource Director"
                        name="Zeeshan Khan"
                        position="Software Engineering "
                        linkedin_link=""
                        class_name="d-none"
                        />
                </div>
                
                </div> */}
            {/* End   Engineering Team Sectin  */}

             {/* Start Management Team Sectin  */}
             {/* <div className="container py-lg-5 py-md-4 py-3">
            <div className="row mb-lg-4 mb-3">
                <h1 className='fw-bold text-center text-dark-beetroot'>Operational Team</h1><hr className='main-hr'/>
                </div>
            
             <div className="row">
                    <Card
                    image={img4}
                    image_name="Operation Specialist"
                    name="Nand Kishor"
                    position="Operation Specialist"
                    linkedin_link=""
                    class_name='d-none'
                    />

                <Card
                    image={img3}
                    image_name="Charted Accountant"
                    name=""
                    position="Charted Accountant"
                    linkedin_link=""
                    class_name='d-none'
                    />
            </div>
            </div> */}
            {/* End Management Team Sectin  */}
           

           

        </div>
    )
}

export default Leadership
