import React from 'react'
import './Artifical_Intelligence.css'
import Card from '../../components/Card/Card'
import image from '../../assets/images/AI/image.jpg'
import ai1 from '../../assets/images/AI/AI1.png'
import ai2 from '../../assets/images/AI/AI2.png'
import ai3 from '../../assets/images/AI/AI3.png'
import ai4 from '../../assets/images/AI/AI4.png';
import ai5 from '../../assets/images/AI/AI5.jpg'
import ai6 from '../../assets/images/AI/AI6.jpg'
import ai7 from '../../assets/images/AI/AI7.jpg'
import ai8 from '../../assets/images/AI/AI8.jpg'
import ai9 from '../../assets/images/AI/AI9.jpg'
import ai10 from '../../assets/images/AI/AI10.jpg'
import ai11 from '../../assets/images/AI/AI11.jpg'
import ai12 from '../../assets/images/AI/AI12.jpg'
import ai13 from '../../assets/images/AI/AI13.jpg'
import ai14 from '../../assets/images/AI/AI14.jpg'
import ai15 from '../../assets/images/AI/AI15.jpg'
import ai16 from '../../assets/images/AI/AI16.jpg'
import nc from '../../assets/images/AI/nc logo.svg'
import dell from '../../assets/images/AI/Dell_Logo.svg.png'


import AICard from './AI Card/AI_Card'
const Artifical_Intelligence = () => {
  return (
    <div className='ai-main-container'>

      {/* start hero section  */}
      <div className='ai-top-image-container'>
        <h1>Shape the future with<br /> human-centric AI.</h1>
        <div className="square square-1"></div>
        <div className="square square-2"></div>
        <div className="square square-3"></div>
        <div className="square square-4"></div>
        <div className="square square-5"></div>
        <div className="square square-6"></div>
        <div className="square square-7"></div>
        <div className="square square-8"></div>
        <div className="square square-9"></div>
        <div className="square square-10"></div>
        <div className="square square-11"></div>
        <div className="square square-12"></div>
      </div>

      {/* end hero section  */}

      {/* start card section  */}
      <div className='ai-container py-lg-5 py-md-4 py-3' >
        <div className="container">
          <div className="row">
            <h1 className='mb-lg-5'>Latest AI Developments</h1>
          </div>
          <div className="row">
              <AICard
                heading="Generative AI"
                content="AI that creates new content (e.g., text, images, audio) based on patterns learned from existing data. It can generate content that resembles human output."
              />
            
              <AICard
                heading="Transformers"
                content="A type of neural network architecture that underpins many generative models. Transformers are designed to handle sequences of data and have significantly improved the performance of NLP and other tasks."
              />
              <AICard
                heading="Diffusion Models"
                content="These are a type of generative model that creates images by gradually adding noise to a data sample and learning to reverse this process to generate new data."
              />
              <AICard
                heading="Generative Adversarial Networks"
                content=" A class of AI models comprising two neural networks (a generator and a discriminator) that contest with each other, creating high-quality synthetic data."
              />

              <AICard
                heading="Autoregressive Models"
                content="Models that predict future sequences based on past data, often used for generating text and images."
              />

              <AICard
                heading="Variational Autoencoders (VAEs)"
                content="A type of generative model that learns to encode data into a lower-dimensional space and decode it back to reconstruct the input data, enabling the creation of new, similar data."
              />
           

          </div>
         

        </div>
      </div>

      {/* end card section  */}

      {/* Start Merging Human Ingenuity Section  */}
      <div className='ai-container py-lg-5 py-md-4 py-3' >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <img className='img-fluid ai-second-img' src={image} alt="" />
            </div>
            <div className="col-lg-8 col-md-8 py-5">
              <h2>Merging Human Ingenuity and AI to Transform Businesses</h2>
              <p>
                At TechTorch Solutions, we provide cutting-edge Artificial Intelligence as a Service (IaaS) that enables businesses to harness the power of AI without the complexity of building and maintaining their own infrastructure. Our AI solutions are designed to enhance decisionmaking, improve operational efficiency, and drive innovation across various industries.
              </p>
              <p>
                We offer a range of services, including machine learning model development, natural language processing, and data analytics, all tailored to meet your specific business needs. Our team of AI experts works collaboratively with clients to identify opportunities for automation and intelligent insights, allowing you to stay ahead of the competition.
              </p>

              <p>
                With TechTorch’s AIaaS, you can easily integrate advanced AI capabilities into your existing processes, unlocking new levels of productivity and customer engagement while reducing operational costs. Let us help you transform your data into actionable intelligence and achieve your business objectives.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End Merging Human Ingenuity Section  */}



    {/* Start Latest AI Developments Section  */}
      <div className='ai-container py-lg-5 py-md-4 py-3 latest-at-section'>
        <div className="container">
          <div className="row">
            <h1 className='my-5'>AI for Business Transformation</h1>
          </div>
          <div className="row">

            <Card
              image={ai1}
              image_name="Machine Learning"
              heading="Machine Learning (ML) Model Development"
              text="Offer custom machine learning solutions that analyze data and make predictions for businesses, such as forecasting demand, detecting anomalies, or recommending products."
            />

            <Card
            image={ai2}
            image_name="Natural Language Processing"
              heading="Natural Language Processing (NLP)"
              text="Provide NLP services for text analysis, sentiment analysis, speech recognition, and language translation. Use AI to process and interpret large volumes of unstructured text data."
            />

            <Card 
              image={ai3}
              image_name="Computer Vision Solutions"
              heading="Computer Vision Solutions"
              text="Develop AI-driven computer vision systems for image recognition, facial recognition, object detection, and video analysis. This can be applied to industries like retail, healthcare, and security."
            />

            <Card
              image={ai4}
              image_name="Predictive Analytics"
              heading="Predictive Analytics"
              text="Help businesses harness their data with AI models that predict future trends, customer behavior, and market conditions to improve decision-making and strategic planning."
            />

            <Card
              image={ai5}
              image_name="Recommendation Engines"
              heading="Recommendation Engines"
              text="Build AI-powered recommendation systems for e-commerce, entertainment, or media platforms to deliver personalized content, products, or services based on user behavior."
            />

            <Card
              image={ai6}
              image_name="AI for Process Automation"
              heading="AI for Process Automation"
              text="Implement AI solutions to automate repetitive tasks in business processes, such as invoice processing, document verification, and email triaging, reducing human effort and errors."
            />

            <Card
              image={ai7}
              image_name="Speech and Voice Recognition"
              heading="Speech and Voice Recognition"
              text="Develop voice-enabled solutions for various applications, including virtual assistants, transcription services, and voice-controlled devices, enhancing accessibility and user interaction."
            />

            <Card
              image={ai8} 
              image_name="AI for Data Analytics and Insights"
              heading="AI for Data Analytics and Insights"
              text="Provide AI-driven analytics platforms to help businesses derive actionable insightsfrom large datasets, offering advanced visualization, trend analysis, and business intelligence"
            />

            <Card
            image={ai9}
            image_name="Robotic Process Automation with AI Integration"
              heading="Robotic Process Automation with AI Integration"
              text="Combine RPA with AI to enhance automation capabilities, enabling robots to handle unstructured data, learn from processes, and adapt to new inputs over time."
            />

            <Card
            image={ai10}
            image_name="Fraud Detection and Risk Management"
              heading="Fraud Detection and Risk Management"
              text="Use AI models to detect fraudulent activities, identify suspicious patterns, and assess risks in industries such as banking, insurance, and e-commerce."
            />

            <Card
            image={ai11}
            image_name="AI for Healthcare"
              heading="AI for Healthcare"
              text="Develop AI tools for medical diagnostics, patient monitoring, personalized treatment plans, and medical image analysis, improving outcomes and efficiency in healthcare services."
            />

            <Card
            image={ai12}
            image_name="AI for Cybersecurity"
              heading="AI for Cybersecurity"
              text="Offer AI solutions for threat detection, intrusion prevention, and anomaly detection to enhance cybersecurity defenses and protect against evolving threats."
            />

            <Card
            image={ai13}
            image_name="AI-Powered Personalization Engines"
              heading="AI-Powered Personalization Engines"
              text="Help businesses deliver highly personalized customer experiences through AI, whether it’s personalized content, advertisements, or product recommendations."
            />

            <Card
            image={ai14}
            image_name="AI in Supply Chain Optimization"
              heading="AI in Supply Chain Optimization"
              text="Use AI for optimizing supply chain processes, including demand forecasting, inventory management, and logistics planning, to reduce costs and improve efficiency."
            />

            <Card
            image={ai15}
            image_name="AI Ethics and Compliance Consulting"
              heading="AI Ethics and Compliance Consulting"
              text="Provide consultation on ethical AI use, ensuring businesses develop and deploy AI systems that comply with legal standards, data privacy laws, and fair use practices."
            />

            <Card
              image={ai16}
              image_name="AI-Driven Robotics"
              heading="AI-Driven Robotics"
              text="Develop AI-based robotic systems for industries like manufacturing, healthcare, and logistics, enabling automation in physical environments."
            />


          </div>
         
           
        </div>
      </div>
      {/* End Latest AI Developments Section  */}


      {/* Start Partners in change Section  */}
      <div className='ai-container py-lg-5 py-md-4 py-3  mb-0'>
        <div className="container">
          <div className="row ">
            <h1 className='mb-lg-5'>Delivering solutions powered by</h1>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src="https://img.icons8.com/androidL/200/FFFFFF/amazon-web-services.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img google-img' src="https://white.logodownload.org/wp-content/uploads/2020/11/google-white-logo.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img microsoft-img' src="https://logodix.com/logo/1069296.png" alt="" />
            </div>
            
            
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={nc} alt="nc" />
            </div>

            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={dell} alt="dell" />
            </div>
          </div>
        </div>
      </div>
       {/* End Partners in change Section  */}

    </div>
  )
}

export default Artifical_Intelligence
