import React from 'react'
import './Software_Developer.css'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/software-developer/software-developer.jpg'
import Top from '../../components/Top Section/Top';
import image1 from '../../assets/images/software-developer/software.jpg'
import TopImage from '../../assets/images/software-developer/TopImage.jpg'
import Features from '../../components/Features/Features'
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const Software_Developer = () => {
    return (
        <>

            {/* start hero section  */}
            <Hero
                image={heroImage}
                image_name="Engineering Solutions"
                page_name="Engineering Solutions"
                heading="TechTorch Software Engineering Solutions"
            />

            {/* end hero section  */}

            {/* Start Top Section  */}
            <div className="container py-lg-5 py-3">
                <Top
                heading="End-to-End Software Development and Support Services for Operational Excellence"
                content="At TechTorch Solutions, we provide end-to-end software development and support services to help businesses achieve operational excellence and drive innovation. Our approach focuses on understanding your unique business requirements and delivering solutions that align with your goals."
                image={TopImage}
                image_name="Software Development and Support"
                paras={[
                    <h5>Industries Served</h5>,
                    <p>We cater to diverse industries, including healthcare, education, e-commerce, finance, logistics, and more.</p>,
                    <h5>Our Process</h5>,
                    <p>1.<span>Requirement Analysis: </span> Understanding your business needs and objectives.</p>,
                    <p>2.<span>Design and Development: </span> Creating intuitive and efficient software architecture.</p>,
                    <p>3.<span>Testing and Deployment: </span> Rigorous quality checks before launching the product. </p>,
                    <p>4.<span>Support and Maintenance: </span> Ensuring smooth operation and continuous improvement.</p>

                ]}

                />
            </div>
            {/* End Top Section  */}

            {/* Start Why Choose Us? Section  */}
            <div className="container py-lg-5 py-md-4 py-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded' src={image1} alt="" />
                    </div>
                    <div className="col-lg-6 col-md-6 p-3">
                        <h5 className='fw-bold'>Why Choose Us?</h5>
                        <p className='font-size'>1.Expertise in modern technologies and development methodologies.</p>
                        <p className='font-size'>2.Agile project management to ensure timely delivery.</p>
                        <p className='font-size'>3.Commitment to delivering secure, scalable, and cost-effective solutions.</p>
                        <p className='font-size'>4.Dedicated support team for reliable assistance.</p>
                        <p className='font-size'>Whether you're a startup or an established enterprise, we are here to help you build innovative software solutions that drive success and ensure business continuity.</p>
                    </div>
                </div>
            </div>
            {/* End Why Choose Us? Section  */}

            {/* start Key Features section  */}
            <div className="container py-lg-5 py-3">
                <Features
                    heading1="Value"
                    heading2="Key Offerings"
                    features={[
                        <li>
                            Custom Software Development
                            Designing and developing tailor-made software to address specific business needs.
                            Focused on scalability, flexibility, and long-term usability.
                            Technologies: Modern frameworks (React, Angular, Vue), backend systems (Node.js, Python, Java), and databases (SQL, NoSQL).
                        </li>,
                        <li>
                            Mobile and Web Application Development
                            Building responsive, cross-platform, and feature-rich mobile and web applications. Ensuring seamless user experience and top-notch performance across devices.
                        </li>,
                        <li>
                            Enterprise Software Solutions
                            Crafting software to manage core business processes like ERP, CRM, and HRMS.Integration with existing tools to streamline workflows and enhance productivity.
                        </li>,
                        <li>
                            Software Modernization Upgrading outdated systems to modern architectures.
                            Migrating to cloud-based solutions for improved accessibility and cost-efficiency.
                        </li>,
                        <li>
                            API Development and System Integration
                            Developing APIs for secure and reliable data exchange between applications.
                            Enabling seamless integration with third-party platforms and services.
                        </li>,
                        <li>
                            Quality Assurance and Testing
                            Comprehensive testing for functionality, performance, security, and usability.
                            Automated and manual testing to ensure bug-free deployment.
                        </li>,
                        <li>
                            Ongoing Maintenance and Support
                            Post-deployment support to address performance issues and technical challenges.

                            Regular software updates, performance monitoring, and feature enhancements.
                            24/7 technical support for troubleshooting and issue resolution.
                        </li>
                    ]}
                />
            </div>
            {/* end Key Features section   */}
            <DeliveringSolutions/>
        </>
    )
}

export default Software_Developer
