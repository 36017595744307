import React from 'react'
import Card from '../../components/Career/Card'
const Career = () => {
  return (
   <>
      <div className="container py-lg-5 py-md-4 py-3">
        <div className="row">
          <h1 className='text-dark-beetroot text-center'>Join Our Team</h1><hr className='main-hr'/>
        </div>
        <div className="row">
          <Card
          jp="Software Engineer Intern"
          eligibility="Currently pursuing a Bachelor's/Master's degree in Computer Science, Software Engineering, or a related field."
          skills={["Proficiency in at least one programming language (JavaScript, PHP, .Net).","Familiarity with web development frameworks or tools (React,native.js, Angular, Node.js).","Understanding of database concepts (SQL/NoSQL).","Knowledge of version control systems ( Git).","Basic understanding of software development lifecycle (SDLC) and Agile methodologies."]}
          Preferred_Qualifications={
            ["Experience with personal or academic projects in software development.","Knowledge of APIs and RESTful services."]
          }
          link="https://docs.google.com/forms/d/e/1FAIpQLSfo81qoMdZs42Clsz9Zbz4PCgoYL161rHck59igH-zR7krU4w/viewform"
          />
        </div>
      </div>
   </>
  )
}

export default Career
