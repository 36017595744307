import React from 'react'
import Hero from '../../components/Hero/Hero';
import Industries from '../../components/Industries/Industries';
import heroImage from '../../assets/images/Transportation/Hero.jpg'
import image from '../../assets/images/Transportation/image.jpg'
const Transportation = () => {
  return (
    <div>
    {/* start hero section  */}
    <Hero
        image={heroImage}
        image_name="Transportation"
        page_name="Transportation"
        heading="Your Trusted Partner in Transportation"
    />
  {/* end hero section  */}


{/* Start Content Section  */}
  <div className="container py-lg-5 py-md-4 py-3">
    <Industries
        heading1="SERVICES For "
        heading2="Transportation"
        content=" We assist transportation and logistics companies with tools to streamline operations, improve fleet management, and enhance supply chain visibility. Our solutions support efficient route planning, real-time tracking, and superior customer service for a seamless transportation experience."
        image={image}
        image_name="Servies for Transportation "
    />
  </div>
  {/* End Content Section  */}

    </div>
  )
}

export default Transportation
