import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Cloud/hero.jpg'
import image from '../../assets/images/Cloud/image.jpg'
import Card from './Cloud Card/Card';
import Top from '../../components/Top Section/Top';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';


const Cloud_Infrastructure = () => {
    return (
        <div>

            {/* hero section  */}
            <Hero
            image={heroImage}
            image_name="Cloud Infrastructure"
            page_name="Cloud Infrastructure"
            heading="Empowering Your Business with Cloud Infrastructure"
            />
            {/* end hero section  */}


            {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
          <Top
          heading="Empower Your Business with Scalable Cloud Infrastructure Solutions"
          content="At TechTorch Solutions, we provide robust Cloud Infrastructure as a Service (IaaS) solutions that empower businesses to scale and optimize their IT operations seamlessly. Our IaaS offerings include flexible computing resources, storage solutions, and networking capabilities, all delivered through a secure and reliable cloud environment."
            image={image}
            image_name="Cloud Computing"
            paras={[
               <p>
                    By leveraging our cloud infrastructure, organizations can reduce the costs associated with traditional hardware investments while enjoying the agility to deploy applications and services quickly. Our dedicated team ensures that your cloud environment is tailored to your specific requirements, enabling efficient resource management and improved performance. With TechTorch’s IaaS, you can focus on innovation and growth while we handle the complexities of your IT infrastructure.
               </p>
            
            ]}
            
          />
      </div>
     
      {/* End Top Section  */}
           

         


        {/* Card Section  */}
        <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                    <Card
                        heading="Cloud Architecture and Design"
                        content=" Help clients design and build scalable, secure, and high-performance cloud infrastructures tailored to their needs using AWS, Azure, or Google Cloud."
                    />
                    <Card
                        heading="Infrastructure-as-a-Service (IaaS)"
                        content="Offer virtualized computing resources, including servers, storage, and networking, allowing businesses to scale their infrastructure without managing physical hardware."
                    />

                    <Card
                        heading="Cloud Migration Services"
                        content="Provide end-to-end migration solutions to help businesses move their applications, data,and workloads to the cloud seamlessly, with minimal downtime."
                    />
                     <Card
                        heading="Multi-Cloud and Hybrid Cloud Management"
                        content="Offer solutions for managing resources across multiple cloud platforms or hybrid environments, allowing businesses to optimize their cloud usage and costs"
                    />
                     <Card
                        heading="Cloud Monitoring and Performance Optimization:"
                        content="Provide real-time monitoring of cloud infrastructure, helping clients track usage, performance metrics, and potential bottlenecks to optimize their resources."
                    />
                     <Card
                        heading="Backup and Disaster Recovery"
                        content="Offer cloud-based backup services to ensure business continuity and help clients recover quickly from outages or data loss with automated disaster recovery solutions."
                    />
                     <Card
                        heading="Cloud Security Solutions"
                        content="Ensure clients’ cloud environments are secure by offering cloud-native security solutions like encryption, firewalls, identity management, and compliance monitoring."
                    />
                     <Card
                        heading="Serverless Computing"
                        content="Help businesses develop and deploy applications without needing to manage theunderlying infrastructure, using serverless platforms like AWS Lambda, Azure Functions,or Google Cloud Functions."
                    />
                    <Card
                        heading="DevOps and Continuous Integration/Continuous Deployment "
                        content="Provide DevOps services to automate and streamline the development, testing, and deployment of applications, including setting up CI/CD pipelines in cloud environments."
                    />
                    <Card
                        heading="Cost Optimization and Cloud Financial Management"
                        content="Offer cloud cost management services to help businesses analyze their cloud spending, optimize resource allocation, and reduce unnecessary expenses.
"
                    />
                    <Card
                        heading="Containerization and Kubernetes Services:"
                        content="Help clients deploy and manage containerized applications using Docker and Kubernetes for scalable, flexible application management across cloud environments."
                    />
                    <Card
                        heading="Cloud Storage Solutions"
                        content="Offer scalable cloud storage options, including object storage (e.g., AWS S3), file storage, and block storage solutions, depending on the client's needs."
                    />
                    <Card
                        heading="Load Balancing and Auto-Scaling"
                        content="Provide cloud-based load balancing and auto-scaling solutions to ensure high availability and handle varying levels of traffic and workloads."
                    />
                    <Card
                        heading="Compliance and Governance in the Cloud:"
                        content="Help businesses implement best practices for cloud governance, ensuring compliance with regulatory requirements and enforcing security policies."
                    />
                    
               
            </div>
        </div>
        {/* End Card Section  */}

            <DeliveringSolutions/>

        </div>
    )
}

export default Cloud_Infrastructure
