import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Education/Hero.jpg';
import Industries from '../../components/Industries/Industries';
import image from '../../assets/images/Education/image.jpg'
const Education = () => {
  return (
    <div>
        {/* start hero section  */}
        <Hero
            image={heroImage}
            image_name="Education"
            page_name="Education"
            heading="Education for Every Generation"
        />
      {/* end hero section  */}


    {/* Start Content Section  */}
      <div className="container py-lg-5 py-md-4 py-3">
        <Industries
            heading1="SERVICES For "
            heading2="Education"
            content="Our education solutions empower institutions with tools for effective learning management, student engagement, and administrative efficiency. We help educational organizations leverage technology to enhance the learning experience and improve outcomes."
            image={image}
            image_name="Education"
        />
      </div>
      {/* End Content Section  */}

    </div>
  )
}

export default Education
