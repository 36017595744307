import React from 'react';
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Investors/hero.jpg';
import Top from '../../components/Top Section/Top/Top'
const Investors = () => {
    return (
        <div>
            {/* start hero section  */}
            <Hero
                image={heroImage}
                image_name="Investors"
                page_name="Investors"
                heading="Empowering Investors for a Prosperous Future"
            />
            {/* end hero section  */}

            {/* Start Top Section  */}
            <div className="container py-lg-5 py-md-4 py-3">
                <Top
                    heading="Investors: Partner with TechTorch Solutions"
                    content="At TechTorch Solutions, we are driven by the ambition to revolutionize how businesses embrace technology. As an early-stage, bootstrapped startup, we have laid a strong foundation through innovation, dedication, and strategic growth. Since our inception, we have successfully built a portfolio of cutting-edge IT solutions, a growing client base, and a team of passionate professionals committed to delivering excellence."
                />
            </div>
            {/* End Top Section  */}

            {/* Start Why Invest in TechTorch Solutions section  */}
            <div className='bg-light-beetroot'>
                <div className="container py-lg-5 py-md-4 py-3">
                    <div className="row">
                        <h2 className='text-center mb-lg-5 mb-md-4 mb-3'>Why Invest in TechTorch Solutions?</h2>
                    </div>
                    <div className="row font-size">
                        <div className="col-lg-6 col-md-6 mb-3">
                            <h5>Proven Market Potential</h5>
                            <ul>
                                <li>Operating since 2019, formally established in 2024.</li>
                                <li>Serving PAN India and expanding our global footprint.</li>
                                <li>Diverse service offerings, including IT Consultancy, SaaS solutions, BPO services, and more.</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <h5>Innovation-Driven Vision</h5>
                            <ul>
                                <li>Developing scalable SaaS products like Corporation24x7 and Eduficera.</li>
                                <li>Industry-specific solutions tailored to meet niche market demands.</li>
                                <li>A strong focus on integrating emerging technologies like AI and Cloud Computing.</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <h5>Thriving Business Model</h5>
                            <ul>
                                <li>Revenue growth through a mix of services and proprietary platforms.</li>
                                <li>Efficient cost management and operational excellence.</li>
                                <li>Strong client retention and long-term partnerships.</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-3">
                            <h5>Untapped Growth Opportunities</h5>
                            <ul>
                                <li>Potential to scale into global markets with strategic investment.</li>
                                <li>Expanding offerings in high-demand areas like cybersecurity and healthcare management solutions.
                                </li>
                                <li>Vision to collaborate with like-minded partners to fuel rapid expansion.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* end Why Invest in TechTorch Solutions section  */}

            {/* Start Join Our Journey Section  */}
            <div>
                <div className="container py-lg-5 py-md-4 py-3">
                    <div className="row font-size">
                        <h1 className='text-center'>Join Our Journey</h1>
                        <p >As we move forward, we seek strategic investors who share our passion for technology and our belief in creating value through innovation. By partnering with TechTorch Solutions, you are not just investing in a company—you are joining a mission to shape the future of technology-driven business solutions.</p>
                        <p >
                        We invite investors to explore our growth potential, collaborate on expanding our horizons, and help us achieve our vision of becoming a global leader in IT solutions.
                        </p>
                        <p>Let’s build the future, together.</p>
                       <div className="row ">
                       <h6 className='fw-bold font-size'>For investment inquiries, contact us at:</h6>
                        <div><span className='fw-bold'>Email: </span> siddharth@techtorch.solutions</div>
                        <div><span className='fw-bold'>Phone: </span> +91 8057695273</div>
                       </div>
                    </div>
                </div>
            </div>
            {/* End Join Our Journey Section  */}

        </div>
    )
}

export default Investors
