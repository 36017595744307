import React from 'react'
import { Link } from 'react-router-dom'

const Privacy = () => {
  return (
    <div>
        <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">

            <h1 className='fw-bold'>Privacy Policy</h1>
            <h6>Effective Date: Oct,01,2024</h6>
            <p>TechTorch Solutions values your trust and is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this policy carefully to understand our practices regarding your personal information.</p>


            <h4 className='fw-bold mt-lg-4 mt-3'>1. Information We Collect</h4>
            <ul className="list-unstyled">
                <li>We may collect the following types of information:</li>
                <li className='fw-bold'>a. Personal Information</li>
                <li>Name, email address, phone number, and other contact details.</li>
                <li>Billing and payment information (where applicable).h</li>
                <li className='fw-bold'>b. Non-Personal Information</li>
                <li>Browser type, operating system, and IP address.</li>
                <li>Pages viewed, time spent on our website, and clickstream data.</li>
                <li className='fw-bold'>c. Cookies and Similar Technologies</li>
                <li>We use cookies to enhance your browsing experience and analyze website traffic. For more information, please refer to our <Link to="/cookie">Cookie Policy.</Link></li>
            </ul>

            <h4 className='fw-bold mt-lg-4 mt-3'>2. How We Use Your Information</h4>
            <ul className="list-unstyled">
                <li>We use your information for the following purposes:</li>
                <li>To provide and improve our products and services.</li>
                <li>To communicate with you, including responding to inquiries and providing updates.
                </li>
                <li>To personalize your experience on our website.</li>
                <li>To comply with legal obligations and protect against fraud or misuse.</li>
            </ul>

            <h4 className='fw-bold mt-lg-4 mt-3'>3. How We Share Your Information</h4>
            <ul className="list-unstyled">
                <li>We do not sell your personal information. However, we may share it in the following circumstances:
                </li>
                <li><span className='fw-bold'>With Service Providers: </span>To assist in delivering our services, such as payment processors and IT support.</li>
                <li><span className='fw-bold'>For Legal Compliance: </span>When required by law, regulation, or legal process.</li>
                <li><span className='fw-bold'>With Your Consent: </span>When you explicitly agree to share your information.</li>
            </ul>

            <h4 className='fw-bold mt-lg-4 mt-3'>4. Data Retention</h4>
            <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy or as required by law. When no longer needed, we securely delete or anonymize your data.</p>

            <h4 className='fw-bold mt-lg-4 mt-3'>5. Security Measures</h4>
            <p>We employ industry-standard security measures to protect your data from unauthorized access, disclosure, or destruction. However, no system is entirely foolproof, and we cannot guarantee absolute security.</p>

            <h4 className='fw-bold mt-lg-4 mt-3'>6. Your Rights</h4>
            <ul className="list-unstyled">
                <li>Depending on your jurisdiction, you may have the following rights regarding your personal information:</li>
                <li>Access and review your information.</li>
                <li>Request corrections or updates to your data.
                </li>
                <li>Opt-out of certain data collection or processing activities.
                </li>
                <li>Request data deletion or transfer.</li>
                <li>To exercise your rights, please contact us using the details provided below.</li>
            </ul>

            <h4 className='fw-bold mt-lg-4 mt-3'>7. Third-Party Links</h4>
            <p>Our website may contain links to third-party websites. We are not responsible for their privacy practices and encourage you to review their policies.</p>

            <h4 className='fw-bold mt-lg-4 mt-3'>8. Updates to This Policy</h4>
            <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and we recommend reviewing it regularly.</p>

            <h4 className='fw-bold mt-lg-4 mt-3'>9. Contact Us</h4>
            <ul className='list-unstyled'>
                  <li>If you have questions or concerns about this Privacy Policy or how your information is handled, please contact us:
                  </li>
                  <li><span className='fw-bold'>Email: </span>contact@techtorch.solutions</li>
                  
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Privacy
