import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Eduficera/hero.jpg'
import TopImage from '../../assets/images/Eduficera/topImage.jpg'
import ImageSection from '../../components/Image Section/Image_Section';
import image from '../../assets/images/Eduficera/image.jpg'
import Card from '../../components/Eduficera Card/Card'
import access from '../../assets/images/Eduficera/access-control.png'
import lock from '../../assets/images/Eduficera/lock.png';
import session from '../../assets/images/Eduficera/session.png'
import data from '../../assets/images/Eduficera/data-recovery.png'
import cloud_hosting from '../../assets/images/Eduficera/cloud-hosting.png'
import multi_factor from '../../assets/images/Eduficera/multi-factor.png'
import Threat from '../../assets/images/Eduficera/threat-detection.png'
import SoftwareUpdates from '../../assets/images/Eduficera/circular.png'
import secure_api from '../../assets/images/Eduficera/gateway.png'
import activity from '../../assets/images/Eduficera/people.png'
import masking from '../../assets/images/Eduficera/encryption.png'
import ip from '../../assets/images/Eduficera/ip-address.png'
import secure_document from '../../assets/images/Eduficera/Secure Document Sharing.png'
import Incident from '../../assets/images/Eduficera/Incident Response Plan.png'
import User_Awareness from '../../assets/images/Eduficera/User Awareness Programs.png'
import data_anonymization from '../../assets/images/Eduficera/Data Anonymization.png'
import Unified_Dashboard from '../../assets/images/Eduficera/Unified Dashboard.png'
import asi from '../../assets/images/Eduficera/Advanced Student Information System.png';
import ai from '../../assets/images/Eduficera/AI-Powered Insights.png';
import aam from '../../assets/images/Eduficera/Automated Admission Management.png';
import lms from '../../assets/images/Eduficera/Learning Management System.png';
import FM from '../../assets/images/Eduficera/Faculty Management.png';
import Financial_Management from '../../assets/images/Eduficera/Financial Management.png';
import ata from '../../assets/images/Eduficera/Attendance & Timetable.png';
import psp from '../../assets/images/Eduficera/Parent and Student Portals.png';
import Multi_Campus from '../../assets/images/Eduficera/Multi-Campus Management.png';
import Mobile_First from '../../assets/images/Eduficera/Mobile-First Design.png';
import Compliance from '../../assets/images/Eduficera/Compliance & Accreditation Support.png';
import ILM from '../../assets/images/Eduficera/Integrated Library Management.png';
import cloud from '../../assets/images/Eduficera/cloud-based-infrastructure.png';
import gamification from '../../assets/images/Eduficera/Gamification Features.png';
import data_security from '../../assets/images/Eduficera/Data Security & Privacy.png';
import Customizable from '../../assets/images/Eduficera/Customizable Reporting & Analytics.png';
import ic from '../../assets/images/Eduficera/Integration Capabilities.png';
import Community from '../../assets/images/Eduficera/Community and Alumni Engagement.png';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';
import factor from '../../assets/images/Eduficera/two-factor-authentication.png'


const Eduficera = () => {

  return (
    <>
      {/* Start Hero Section  */}
      <Hero
        image={heroImage}
        image_name="Eduficera"
        page_name="Eduficera"
        heading="Eduficera: Empowering Minds, Enriching Lives"
      />

      {/* End Hero Section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-md-4 py-3">
        <div className="row">
          <div className="col-lg-6 col-md-6">
              <img className='img-fluid rounded' src={TopImage} alt="" />
          </div>
          <div className="col-lg-6 col-md-6">
            <h5>Edufic Era: Transforming Education with a Comprehensive ERP Solution</h5>
            <p>Edufic Era is a comprehensive ERP solution tailored specifically for the education sector, encompassing schools, colleges, and universities. Our platform is designed to streamline administrative processes, enhance student engagement, and facilitate effective communication within educational institutions.</p>
            <ul className='ps-3'>
              <li className='mb-3'>Integrated Management: Edufic Era offers a unified platform to manage various functions
                such as admissions, attendance, grading, and curriculum planning. This integration reduces
                administrative burdens and improves operational efficiency.</li>
              <li className='mb-3'> Student Information System: Keep track of student data, including enrollment details,
                academic records, and attendance history. Our system provides educators with valuable
                insights to support student success and personalized learning experiences.</li>
              <li className='mb-3'>Learning Management System (LMS): Facilitate online learning and classroom
                management with our intuitive LMS. It allows educators to create, deliver, and manage
                educational content while tracking student progress and engagement.</li>
              <li className='mb-3'>Financial Management: Streamline financial processes with tools for fee collection, budgeting, and reporting. Edufic Era provides real-time financial insights to help institutions make informed financial decisions.</li>
              <li className='mb-3'>Communication and Collaboration: Enhance communication among students, parents, and
                faculty through integrated messaging and notification systems. Foster a collaborative
                environment that supports academic growth and community engagement.</li>
            </ul>
          </div>
        </div>
      </div>
      {/* End Top Section  */}


        {/* Start Image Section  */}
          <ImageSection
            image={image}
            image_name="Transform Education with Edufic Era"
            heading="Transform Education with Edufic Era"
            content="With Edufic Era, educational institutions can transform their operations, improve administrative efficiency, and provide a better learning experience for students. Our solution is designed to adapt to the unique needs of each institution, ensuring scalability and flexibility for future growth. "
            />
            {/* Start Image Section  */}


      {/* Start Complete Features Section  */}
      <div className="bg-light-beetroot">
        <div className="container py-lg-5 py-md-4 py-3 ">
          <div className="row">
            <h1 className='main-heading'>Complete Features</h1><hr className='main-hr' />
          </div>
          <div className="row">

            <Card
              image={access}
              heading='Role-Based Access Control (RBAC)'
              content="Users (administrators, teachers, students, parents) are assigned roles with specific access permissions. Limits access to sensitive data based on user roles, ensuring only authorized personnel can view or edit certain information."
            />

          <Card
              image={lock}
              heading='Data Encryption'
              content="In-Transit: All data transmitted between users and the platform is encrypted using HTTPS and SSL protocols.At Rest: Data stored in databases is encrypted using advanced algorithms like AES-256 to prevent unauthorized access."
            />

          <Card
              image={multi_factor}
              heading='Multi-Factor Authentication (MFA)'
              content="Adds an extra layer of security by requiring users to verify their identity using two or more methods, such as passwords and one-time passcodes (OTP)."
            />

          <Card
              image={data}
              heading='Regular Data Backups'
              content="Automated daily backups stored securely in redundant locations. Backup data is also encrypted to prevent unauthorized access."
            />

          <Card
              image={cloud_hosting}
              heading='Secure Cloud Hosting'
              content="Hosted on trusted cloud platforms with advanced security measures, including firewalls, intrusion detection systems, and disaster recovery protocols."
            />

          <Card
              image={factor}
              heading='GDPR and FERPA Compliance'
              content="Adheres to data protection regulations to ensure user privacy and proper handling of sensitive information. Provides transparent data usage policies for users."
            />

          <Card
              image={Threat}
              heading='Real-Time Threat Detection'
              content="Integrated systems monitor suspicious activities, such as repeated failed login attempts or unusual access patterns. Alerts administrators immediately and temporarily suspends accounts if necessary."
            />

          <Card
              image={SoftwareUpdates}
              heading='Regular Software Updates'
              content="Continuous updates to address vulnerabilities and ensure the platform uses the latest security patches. Zero-downtime deployments to maintain uninterrupted service."
            />

          <Card
              image={secure_api}
              heading='Secure API Access'
              content="APIs are protected with OAuth2.0 and other advanced protocols, ensuring that external integrations follow strict security guidelines."
            />

          <Card
              image={activity}
              heading='Activity Logs & Monitoring'
              content="Comprehensive logging of all user activities, including logins, data access, and system modifications. Allows administrators to audit and detect unauthorized actions."
            />

          <Card
              image={masking}
              heading='Data Masking'
              content="Sensitive information (e.g., personal identification numbers, financial details) is masked to limit exposure during data processing or reporting."
            />

            <Card
              image={ip}
              heading='Restricted IP Access'
              content="Administrators can whitelist specific IP ranges to restrict access to trusted locations, particularly for sensitive operations."
            />

          
          
          <Card
              image={session}
              heading='Session Timeouts'
              content="Automatic logout after periods of inactivity to prevent unauthorized access if a device is left unattended."
            />

            <Card
              image={secure_document}
              heading='Secure Document Sharing'
              content="Encrypted file-sharing capabilities ensure documents such as student records and financial reports remain confidential. Watermarking to prevent unauthorized reproduction"
            />

            <Card
              image={Incident}
              heading='Incident Response Plan'
              content="A robust plan to handle potential data breaches, including containment, notification, and recovery protocols. Immediate communication to affected users with steps for resolution."
            />

            <Card
              image={User_Awareness}
              heading='User Awareness Programs'
              content="Built-in user guides and periodic reminders about password hygiene, phishing risks, and other security best practices."
            />

            <Card
              image={data_anonymization}
              heading='Data Anonymization for Reporting'
              content="Data used for analytics and reporting is anonymized to prevent any traceability to individual users."
            />

            <Card
              image={Unified_Dashboard}
              heading='Unified Dashboard'
              content="A centralized, role-based dashboard for administrators, faculty, students, and parents. Customizable widgets to display real-time analytics, notifications, and key performance indicators (KPIs)."
            />

          <Card
              image={asi}
              heading=' Advanced Student Information System (SIS)'
              content="End-to-end management of student data, from admissions to alumni tracking. Dynamic profiles with academic history, attendance records, and extracurricular achievements."
            />

          <Card
              image={ai}
              heading='AI-Powered Insights'
              content="Predictive analytics for student performance and dropout risk. AI-driven recommendations for personalized learning paths and resource allocation."
            />

          <Card
              image={aam}
              heading='Automated Admission Management'
              content="Online application forms with document uploads and automated shortlisting. Integration with payment gateways for secure fee collection."
            />

          <Card
              image={lms}
              heading='Learning Management System (LMS)'
              content="Virtual classrooms with live streaming, video conferencing, and recorded sessions. Course content management, quizzes, and real-time grading. Integration with third-party tools like Google Classroom and Zoom."
            />

          <Card
              image={FM}
              heading='Faculty Management'
              content="Scheduling and workload distribution tools for efficient time management. Performance tracking and professional development modules."
            />

          <Card
              image={Financial_Management}
              heading='Financial Management'
              content="Fee management with automated reminders and payment tracking. Budgeting, payroll, and expense tracking in one system. Multi-currency support for international campuses."
            />

          <Card
              image={ata}
              heading='Attendance & Timetable Automation'
              content="Biometric or RFID-enabled attendance systems for students and faculty. Smart timetable generators to optimize resources and avoid conflicts."
            />

          <Card
              image={psp}
              heading='Parent and Student Portals'
              content="Dedicated access for parents to monitor attendance, grades, and fee payments. Students can access study materials, submit assignments, and interact with faculty."
            />

            <Card
              image={Multi_Campus}
              heading='Multi-Campus Management'
              content="Seamless oversight of multiple campuses under one platform. Shared resources and centralized reporting."
            />

          <Card
              image={Mobile_First}
              heading='Mobile-First Design'
              content="Online exam scheduling and proctoring tools. Digital evaluation system with instant results and feedback."
            />

        <Card
              image={Compliance}
              heading='Compliance & Accreditation Support'
              content="Built-in frameworks for accreditation processes and audits. Detailed reports aligned with educational standards and policies."
            />

          <Card
              image={ILM}
              heading=' Integrated Library Management'
              content="Digital cataloging of books and resources. Borrowing and return tracking with automated reminders."
            />

         <Card
              image={cloud}
              heading='Cloud-Based Infrastructure'
              content="24/7 accessibility with scalable cloud hosting. Secure backups and disaster recovery options."
            />

        <Card
              image={gamification}
              heading=' Gamification Features'
              content="Reward systems for academic achievements and extracurricular participation. Leaderboards to encourage healthy competition among students."
            />

            <Card
              image={data_security}
              heading='Data Security & Privacy'
              content="GDPR and FERPA compliance for data protection. Role-based access control to safeguard sensitive information."
            />

          <Card
              image={Customizable}
              heading='Customizable Reporting & Analytics'
              content="Generate insightful reports on student performance, finances, and operations. Export data in multiple formats for external use."
            />

          <Card
              image={ic}
              heading='Integration Capabilities'
              content="API integrations with existing tools like CRMs, accounting software, and third-party apps. Compatibility with ed-tech platforms for seamless functionality."
            />

          <Card
              image={Community}
              heading='Community and Alumni Engagement'
              content="Forums and social networking features for student and alumni interactions. Event management for reunions, webinars, and workshops."
            />



            
           

          </div>
        </div>
      </div>
      {/* End Complete FeaturesSection  */}

      <DeliveringSolutions/>
    </>
  )
}
export default Eduficera;
