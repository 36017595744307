import React from 'react'
import { Link } from 'react-router-dom'
import image from '../../assets/images/Leadership/image6.svg'
const HR = () => {
  return (
    <div className="container py-lg-5 py-md-4 py-3">
    <div className="row">
        <div className="col-lg-6 col-md-6 d-flex align-items-center">
            <img className='img-fluid' src={image} alt="Riya Mishra" />
        </div>
        <div className="col-lg-6 col-md-6 ">
        <div className="ceo">
            <h6>Leadership</h6>
            <h1 className='fw-bold mb-3'>Riya Mishra</h1>
            <h4>Human Resource Director TechTorch Solutions</h4>
            <div className='social-media'>
            <Link  className='link'><i className="bi bi-twitter-x"></i></Link>
            <Link className='link'><i className="bi bi-linkedin"></i></Link>
            </div>
            <p>
            In TechTorch Solutions, Riya Mishra has five years of devoted experience in dealing peoples. She is skilled in promoting a happy work environment and good organizational behavior. As the director of human resources, she plays a key role in creating and enforcing efficient work procedures, improving benefits for employees, and fostering a positive work atmosphere. Known for her upbeat demeanor, Riya places a high value on the happiness and engagement of her staff members and thinks that work-life balance should be a real practice rather than just an abstract idea.
            </p>
            <p>
            Her leadership ethos places a strong emphasis on the value of acknowledging coworkers as unique people with their own families and making sure they have the time and space to flourish in both their personal and professional lives.
            </p>

        </div>
        </div>
    </div>
</div>
  )
}

export default HR
