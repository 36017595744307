import React from 'react'
import './Software_Engineering_Services.css'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/software/hero.jpg'
import Top from '../../components/Top Section/Top'
import TopImage from '../../assets/images/software/top.jpg'
import Card from '../../components/Corporation/Card'
import web from '../../assets/images/software/web-development.png'
import web2 from '../../assets/images/software/web-development (1).png'
import app from '../../assets/images/software/app-development.png'
import api from '../../assets/images/software/api.png'
import dev from '../../assets/images/software/devops.png'
import testing from '../../assets/images/software/testing.png'
import iot from '../../assets/images/software/internet-of-things.png'
import cloud from '../../assets/images/software/cloud-computing.png';
import ai from '../../assets/images/software/artificial-intelligence.png';
import software from '../../assets/images/software/software.png';
import blockchain from '../../assets/images/software/blockchain.png';
import legacy from '../../assets/images/software/legacy-system.png';
import erp from '../../assets/images/software/erp.png';
import Saas from '../../assets/images/software/saas.png';
import SoftwareArchitecture from '../../assets/images/software/3d.png';
import counseling from '../../assets/images/software/counseling.png';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';



const Software_Engineering_Services = () => {
    return (
        <div>
            {/* Hero Section  */}
            <Hero
                image={heroImage}
                image_name="Software Engineering Services"
                page_name="Software Engineering Services"
                heading="Comprehensive Software Engineering Services for Modern Solutions"
            />
            {/* End Hero Section  */}


            {/* Start Top Section  */}
            <div className="container py-lg-5 py-3">
                <Top
                    heading="Empowering Businesses with Comprehensive Engineering Services"
                    content="At TechTorch Solutions, we offer comprehensive Engineering Services designed to support businesses across various sectors in achieving their technical and operational goals. Our expertise spans a wide range of engineering disciplines, including software engineering, systems design, and product development."
                    image={TopImage}
                    image_name="Engineering Services"
                    paras={[
                        <p>
                            We collaborate closely with clients to understand their unique challenges and provide innovative
                            solutions that enhance efficiency and effectiveness. Our engineering team utilizes advanced
                            methodologies and cutting-edge technologies to deliver high-quality results, whether it’s
                            developing custom software solutions, optimizing existing systems, or conducting rigorous testing
                            and validation processes.

                        </p>,
                        <p>
                            By choosing TechTorch’s Engineering Services, you benefit from our commitment to excellence,
                            ensuring that your projects are completed on time and within budget, ultimately driving your
                            success in the competitive landscape.

                        </p>

                    ]}

                />
            </div>

            {/* End Top Section  */}

            {/* Start Card Section  */}
            <div className="contaienr-fluid py-lg-5 py-md-4 py-3 bg-light-beetroot">
                <div className="container">
                    <div className="row">
                        <Card
                            class_name="eng-card"
                            image={web}
                            heading="Custom Software Development"
                            content="Provide end-to-end custom software development services, including requirement analysis, design, development, testing, and deployment for web, mobile, and desktop applications."
                        />
                        <Card
                            class_name="eng-card"
                            image={web2}
                            heading="Web Application Development"
                            content="Build scalable, high-performance web applications using modern technologies like React, Angular, Node.js, or Python, with a focus on user experience and functionality."
                        />

                        <Card
                            class_name="eng-card"
                            image={app}
                            heading="Mobile App Development"
                            content="Offer native (iOS, Android) and cross-platform (React Native, Flutter) mobile app development services, tailored to the specific needs of businesses and their customers."
                        />

                        <Card
                            class_name="eng-card"
                            image={api}
                            heading="API Development and Integration"
                            content="Develop secure, scalable APIs and integrate third-party APIs to ensure seamless data exchange between different applications and systems.
"
                        />

                        <Card
                            class_name="eng-card"
                            image={counseling}
                            heading="Software Consulting"
                            content="Provide expert consulting services to guide businesses in choosing the right technology stack, architecture, and development strategies for their projects."
                        />

                        <Card
                            class_name="eng-card"
                            image={dev}
                            heading="DevOps and Continuous Delivery"
                            content="Offer DevOps services to automate the software development lifecycle, including setting up CI/CD pipelines, infrastructure automation, and performance monitoring."
                        />

                        <Card
                            class_name="eng-card"
                            image={testing}
                            heading="Software Testing and Quality Assurance"
                            content="Provide manual and automated testing services to ensure that applications are free of bugs, secure, and meet quality standards before deployment.
"
                        />

                        <Card
                            class_name="eng-card"
                            image={erp}
                            heading="Enterprise Software Solutions"
                            content="Develop enterprise-level applications like ERP systems, CRM platforms, and HR management systems tailored to the specific needs of large organizations."
                        />

                        <Card
                            class_name="eng-card"
                            image={Saas}
                            heading="SaaS Application Development"
                            content="Build cloud-based SaaS applications that businesses can offer as a service to their customers, ensuring scalability, security, and flexibility."
                        />

                        <Card
                            class_name="eng-card"
                            image={legacy}
                            heading="Legacy System Modernization"
                            content="Help businesses modernize their outdated software by refactoring code, migrating to modern architectures, or transitioning to cloud-based environments"
                        />

                        <Card
                            class_name="eng-card"
                            image={ai}
                            heading="Artificial Intelligence and Machine Learning Solutions"
                            content="Offer AI/ML solutions, including predictive analytics, natural language processing (NLP), computer vision, and data-driven decision-making models.
"
                        />

                        <Card
                            class_name="eng-card"
                            image={blockchain}
                            heading="Blockchain Development"
                            content="Provide blockchain development services, including smart contract development, decentralized applications (DApps), and secure transaction solutions for industries like finance and healthcare."
                        />

                        <Card
                            class_name="eng-card"
                            image={cloud}
                            heading="Cloud-Native Application Development"
                            content="Develop applications optimized for cloud environments, leveraging cloud-native services like serverless computing, containerization (Docker, Kubernetes), and microservices architecture."
                        />

                        <Card
                            class_name="eng-card"
                            image={software}
                            heading="Software Maintenance and Support"
                            content=" Provide ongoing maintenance, updates, bug fixes, and support to ensure software solutions remain functional, secure, and up-to-date with the latest technology trends."
                        />

                        <Card
                            class_name="eng-card"
                            image={SoftwareArchitecture}
                            heading="Software Architecture Design"
                            content="Design robust, scalable, and secure software architectures that align with the business goals and ensure long-term growth and sustainability of applications."
                        />

                        <Card
                            class_name="eng-card"
                            image={iot}
                            heading="Internet of Things (IoT) Solutions"
                            content="Develop IoT applications that enable devices to communicate and share data in real-time, providing innovative solutions for industries like manufacturing, healthcare, and logistics."
                        />


                    </div>
                </div>
            </div>
            {/* End Card Section  */}
                
                <DeliveringSolutions/>
        </div>
    )
}

export default Software_Engineering_Services
