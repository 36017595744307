import React from 'react';
import Hero from '../../components/Hero/Hero';
import Top from '../../components/Top Section/Top';
import heroImage from '../../assets/images/ERP/erp.jpg'
import Item from '../../components/Our Approach/Item';
import Features from '../../components/Features/Features';
import erp4 from '../../assets/images/ERP/erp4.jpg'
import nc from '../../assets/images/AI/nc logo.svg'
import dell from '../../assets/images/AI/Dell_Logo.svg.png'
const ERP = () => {
  return (
    
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Enterprise resource planning"
        page_name="Enterprise resource planning"
        heading="Optimize Operations with Advanced Enterprise Resource Planning"
      />

      {/* end hero section  */}


      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
          <Top
          heading="Streamline Your Business with TechTorch ERP Solutions"
          content="Our ERP solutions at TechTorch Solutions designed to streamline and
            integrate various business processes into a single, unified system. These systems support core business functions such as inventory management, finance, human resources, customer
            relations, and supply chain management, allowing companies to improve efficiency,
            transparency, and decision-making."
            image={erp4}
            image_name="ERP"
            paras={[
              <p>
              Industries We Serve with ERP Solutions: We offer ERP software across multiple sectors, including IT, manufacturing, retail, healthcare, education, and logistics, FMCG with industry-specific functionalities that align with each sector’s unique operational needs.
              </p>,
              <p>
              Our ERP products, such as Corporation24x7 (featuring HRM, CRM, and Accounts modules) and Edufic Era (an ERP designed specifically for educational institutions), demonstrate our commitment to tailored and impactful solutions.
              </p>,
               <p>
               Why Choose TechTorch Solutions for ERP? Our ERP offerings stand out due to our hands-on implementation support, post-deployment training, and ongoing maintenance services. We work closely with our clients to ensure seamless integration into their existing systems and operational workflows.
               </p>
            
            ]}
            
          />
      </div>
     
      {/* End Top Section  */}


      {/* Start Our approch section  */}
      <div className="bg-light-beetroot py-lg-5 py-3">
        <div className="container">
          <div className="row">
            <h5 className='our-approach-heading'>Our Approach</h5>
          </div>
          <div className="row">
            <Item
            icon={<i class="bi bi-cloud"></i>}
            text="Enable business vision"
            />

          <Item
            icon={<i class="bi bi-box"></i>}
            text="Empower data-led decisions"
            />

          <Item
            icon={<i class="bi bi-clouds"></i>}
            text="Build resilience"
            />
             <Item
            icon={<i class="bi bi-person"></i>}
            text="Accelerate innovation"
            />
            
          </div>
        </div>
      </div>
      {/* End Our approch section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our ERP Solutions:"
          features={ [
          <li>
            <span>Centralized Data Management: </span> Consolidates critical data across departments, ensuring seamless access to real-time information and improving cross-functional coordination.
            </li>,
          <li>
            <span>Scalability & Customization: </span>Adaptable to meet the specific needs of growing
          businesses, with modular options that allow companies to scale and customize based on
          industry requirements.
          </li>,
          <li>
            <span>Enhanced Decision-Making: </span>
            With integrated reporting and analytics, our ERP systems provide data-driven insights, enabling faster and more informed decision-making at all organizational levels.
          </li>,
          <li>
            <span>Automation of Routine Tasks: </span>
            Reduces manual work and minimizes errors, enhancing productivity and enabling teams to focus on strategic tasks.
          </li>,
          <li>
            <span>Compliance and Security: </span>
            Built with compliance in mind, our ERP solutions adhere to
            industry standards and incorporate robust security measures to protect sensitive data.
          </li>


            ]}
        />
      </div>
      {/* end Key Features section   */}


      {/* Start Partners in change Section  */}
      <div className='ai-container py-lg-5 py-md-4 py-3  mb-0'>
        <div className="container">
          <div className="row ">
            <h1 className='mb-lg-5'>Delivering solutions powered by</h1>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src="https://img.icons8.com/androidL/200/FFFFFF/amazon-web-services.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img google-img' src="https://white.logodownload.org/wp-content/uploads/2020/11/google-white-logo.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img microsoft-img' src="https://logodix.com/logo/1069296.png" alt="" />
            </div>
            
            
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={nc} alt="nc" />
            </div>

            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={dell} alt="dell" />
            </div>
          </div>
        </div>
      </div>
       {/* End Partners in change Section  */}
    </>
    
  )
}

export default ERP
