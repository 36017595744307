import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Partners Ecosystem/Hero.jpg';
import Top from '../../components/Top Section/Top/Top';
import image1 from '../../assets/images/Partners Ecosystem/image.jpg';

const Partners_Ecosystem = () => {
  return (
    <div>
      {/* start hero section  */}
    <Hero
        image={heroImage}
        image_name="Partners Ecosystem"
        page_name="Partners Ecosystem"
        heading="Empowering growth through partnerships."
      />
      {/* end hero section  */}

    {/* Start Top Section  */}
    <div className="container py-lg-5 py-md-4 py-3">
        <Top
            heading="Partner Ecosystem: Collaborating for Success"
            content="At TechTorch Solutions, we believe in the power of collaboration to drive innovation and achieve shared success. Our Partner Ecosystem is built on a foundation of trust, expertise, and mutual growth, bringing together a network of like-minded organizations, industry leaders, and innovative thinkers to deliver comprehensive solutions for businesses worldwide."
        />
    </div>
    {/* End Top Section  */}

    {/* Start Why Partner with TechTorch Solutions Section  */}
        <div className='bg-light-beetroot'>
        <div className="container py-lg-5 py-md-4 py-3">
                <div className="row text-center">
                <h1 className='mb-lg-5 mb-md-4 mb-3'>Why Partner with TechTorch Solutions?</h1>
                </div>
                <div className="row font-size">
                    <div className="col-lg-6 col-md-6 mb-3">
                            <h5>Shared Vision for Growth</h5>
                            <ul>
                                <li>Collaborate with a company dedicated to delivering cutting-edge technology solutions.</li>
                                <li>Work alongside a team committed to innovation, quality, and mutual success.</li>
                            
                            </ul>
                    </div>

                    <div className="col-lg-6 col-md-6 mb-3">
                        <h5>Diverse Expertise</h5>
                        <ul>
                            <li>Access our extensive experience in IT consultancy, SaaS solutions, cloud services, and more.</li>
                            <li>Benefit from our multidisciplinary approach that combines technology, strategy, and execution.
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                        <h5>Global Reach</h5>
                        <ul>
                            <li>Join a network that spans industries and geographies, expanding opportunities for all stakeholders.
                            </li>
                            <li>Leverage our growing presence in the global technology market.
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                        <h5>Scalable Solutions</h5>
                        <ul>
                            <li>Collaborate on projects ranging from startups to enterprises, ensuring scalability and flexibility.</li>
                            <li>Integrate your capabilities with our innovative platforms like Corporation24x7 and Eduficera.
                            </li>
                        </ul>
                    </div>
                
                    
                </div>
            </div>
        </div>
     {/* End Why Partner with TechTorch Solutions Section  */}


    {/* Start Our Partnering Approach Section  */}
     <div className="container py-lg-5 py-md-4 py-3">
        <div className="row">
            <div className="col-lg-6 col-md-6">
                <img className='img-fluid' src={image1} alt="Our Partnering Approach" />
            </div>
            <div className="col-lg-6 col-md-6 d-flex flex-column justify-content-center">
                <h3 className='mb-lg-3'>Our Partnering Approach</h3>
                <h5 className='mb-lg-3'>We strive to create win-win partnerships where:</h5>
                <p className='font-size'><span className='fw-bold'>Innovation thrives </span> through joint efforts in product development and service delivery.</p>
                <p className='font-size'><span className='fw-bold'>Value is added </span> for clients through integrated solutions and shared expertise.</p>
                <p className='font-size'><span className='fw-bold'>Growth opportunities </span> emerge by tapping into new markets and expanding service portfolios.</p>
            </div>
        </div>
     </div>
     {/* End Our Partnering Approach Section  */}



    {/* Start Types of Partnerships Section  */}
   <div className="bg-light-beetroot">
   <div className="container py-lg-5 py-md-4 py-3">
        <div className="row text-center">
            <h1 className='mb-lg-5 mb-md-4 mb-3'>Types of Partnerships</h1>
        </div>
        <div className="row text-center font-size">
            <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                <h5>Technology Partners</h5>
                <p>Collaborate with us on developing and deploying innovative IT and SaaS solutions.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                <h5>Business Associates</h5>
                <p>Partner with us to expand market reach and jointly deliver value-driven services.
                </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                <h5>Channel Partners</h5>
                <p>Be part of our reseller program and help bring TechTorch Solutions' offerings to a broader audience.
                </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                <h5>Strategic Alliances</h5>
                <p>Form long-term partnerships focused on co-creating industry-specific solutions and scaling globally.
                </p>
            </div>
        </div>
    </div>
   </div>

   {/* End Types of Partnerships Section   */}

   <div className="container py-lg-5 py-md-4 py-3">
    <div className="row font-size">
        <h3>Partner with Us</h3>
        <p>At TechTorch Solutions, we see our partners not just as collaborators but as integral contributors to our vision of driving technological innovation. Together, we can create solutions that empower businesses, enhance productivity, and transform industries.
        </p>
        <h5>Let’s build the future together.</h5>
        <h6>For partnership opportunities, reach out to us at:</h6>
        <p><span className='fw-bold'>Email: </span>siddharth@techtorch.solutions</p>
        <p><span className='fw-bold'>Phone: </span>+91 8057695273</p>
    </div>
   </div>


    </div>
  )
}

export default Partners_Ecosystem
