import React, { useState} from 'react';
import './Accordion.css'
const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);
 
 

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>

      {items.map((item, index) => (
        <div key={index} className="accordion-item">

          <div className="accordion-header" onClick={() => handleClick(index)}>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='fw-bold'>{item.title}</h6>
              {
               index === activeIndex?<i class="bi bi-dash-circle"></i>:<i class="bi bi-plus-circle"></i>
              }
            </div>
            
          </div>

          {index === activeIndex && (
            <div className="accordion-content">
              {
              item.content.map((item,index)=>(
                <div key={index} className='p-3'>
                    <h5 className='fw-bold'>{item.heading}</h5>
                    <p>{item.text}</p>
                </div>
              ))
              }
            </div>
          )}

        </div>
      ))}

    </div>
  );
};

export default Accordion;
