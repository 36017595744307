import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Payment Management/Payment Management.jpg'
import Top from '../../components/Top Section/Top'
import TopImage from '../../assets/images/Payment Management/Payment Management1.jpg';
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const Payment_Management = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Payment Management"
        page_name="Payment Management"
        heading="Optimizing your payment processes for success"
      />

      {/* end hero section  */}


      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Effortless Payment Management for Secure and Efficient Transactions"
          content="TechTorch Solutions provides advanced Payment Management solutions that simplify and streamline the payment process for businesses across various industries. Our SaaS platform enhances transaction efficiency, security, and visibility, allowing organizations to manage their payment operations with ease and confidence."
          image={TopImage}
          image_name="Payment Management"
          paras={[
            <p>
              <span>Industries We Serve with Payment Management Solutions: </span>
              Our Payment Management
              solutions are tailored for various sectors, including e-commerce, retail, hospitality, healthcare,
              and financial services, addressing specific payment processing needs and regulatory
              requirements.

            </p>,
            <p>
              <span>Why Choose TechTorch Solutions for Payment Management? </span>
              Our Payment
              Management solutions are designed to enhance the overall payment experience for businesses
              and their customers. With a focus on security, efficiency, and user-friendliness, we ensure that
              our clients can adapt to the rapidly changing payment landscape. Our team provides full
              implementation support, training, and continuous optimization, enabling organizations to
              harness the power of our solutions effectively. Partnering with TechTorch Solutions Private
              Limitedallows businesses to simplify payment processes, enhance cash flow management, and
              drive customer satisfaction.

            </p>
          ]}

        />
      </div>
      {/* End Top Section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Payment Management Solutions: "
          features={[
            <li>
              <span>Multi-Channel Payment Processing: </span>
              Supports a wide range of payment methods,
              including credit/debit cards, digital wallets, bank transfers, and ACH payments, ensuring
              flexibility for both businesses and their customers.
            </li>,
            <li>
              <span>Automated Reconciliation: </span>
              Automates the reconciliation process by matching
              transactions in real-time, reducing manual effort and minimizing discrepancies, thus
              enhancing financial accuracy.
            </li>,
            <li>
              <span>Fraud Detection and Security: </span>
              Integrates advanced security measures and fraud
              detection algorithms to protect sensitive financial information and reduce the risk of
              fraudulent activities.
            </li>,
            <li>
              <span>Recurring Billing and Subscription Management: </span>
              Facilitates the management of
              recurring billing cycles and subscription services, providing businesses with tools to
              handle automatic payments and customer billing preferences.
            </li>,
            <li>
              <span>Detailed Reporting and Analytics: </span>
              Offers comprehensive reporting features that
              provide insights into payment trends, transaction volumes, and customer behavior,
              enabling informed decision-making and strategy development.

            </li>
          ]}
        />
      </div>
      {/* end Key Features section   */}
        
          <DeliveringSolutions/>
    </>
  )
}

export default Payment_Management
