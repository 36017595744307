import React from 'react';
import './Slider.css'
import { Carousel } from 'react-bootstrap';

const Slider = () => {
    const testimonials=[
        {   id:1,
            text:"TechTorch Solutions transformed our business processes with their innovative IT solutions. Their expertise in SaaS and CRM tools significantly boosted our productivity and customer satisfaction. We’re now more efficient than ever!",
           
        },
        {   
            id:2,
            text:"As a growing company, we needed a partner who could scale with us. TechTorch delivered cloud infrastructure and ERP solutions that are not only secure but adaptable to our expanding needs. A true technology partner!"
        },
        {   
            id:3,
           text:"We were looking for a seamless transition to digital, and TechTorch Solutions provided us with a tailor-made e-commerce platform and integrated CRM. Their support team has been fantastic, always available and responsive."
        },
        {   
            id:4,
           text:"Cybersecurity was a major concern for us, and TechTorch Solutions provided cutting-edge security-as-a-service. Their proactive monitoring and threat detection have given us peace of mind, knowing our data is secure."
        },
        {
            id:5,
            text:"TechTorch helped us optimize our workflow with their advanced project management tools and custom development. Their team was with us every step of the way, ensuring the project met all deadlines and exceeded our expectations."
        }
    ]
  return (
    <div className='py-lg-5 py-md-4 py-3 bg-light-beetroot'>
      <div className="container bpo-slider">
        <div className="row">
            <h1 className='text-center  mb-4'>Why Clients Choose TechTorch Solutions</h1>
            <Carousel controls={true} indicators={false}>
            {testimonials.map(({id, text, name }) => (
                <Carousel.Item key={id}>
                    <div className=" text-center m-auto pt-3 w-lg-50 w-75">
                        <h6>{text}</h6>
                        <p>{name}</p>  
                    </div>
                </Carousel.Item>
        ))}
            </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Slider
