import React from 'react'

const Item = ({icon,text}) => {
  return (
    <div className="col-lg-3 col-md-3 col-6 ">
    <div className="our-approach-content">
        {icon}
      <p>{text}</p>
    </div>
  </div>
  )
}

export default Item
