import React from 'react'
import './Top.css'
const Top = ({heading,content}) => {
  return (
     <div className={`row mb-lg-5 mb-md-4 mb-3 top-content `}>
          <h1>{heading}</h1>
          <p>{content} </p>
      </div>
  )
}

export default Top
