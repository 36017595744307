import React from 'react'
import Hero from '../../components/Hero/Hero';
import Industries from '../../components/Industries/Industries';
import heroImage from '../../assets/images/Telecommunications/Hero.jpg'
import image from '../../assets/images/Telecommunications/image.jpg'
const Telecommunications = () => {
  return (
    <div>
        {/* start hero section  */}
        <Hero
            image={heroImage}
            image_name="Telecommunications"
            page_name="Telecommunications"
            heading="Empowering Communication, Anywhere, Anytime"
        />
      {/* end hero section  */}

        <div className="container py-lg-5 py-md-4 py-3">
            <Industries
                heading1="Services For "
                heading2="Telecommunications"
                content="We support telecommunications companies with solutions that enhance service delivery, improve customer interactions, and streamline operations. Our platforms help telecom providers adapt to market demands and improve service quality."
                image={image}
                image_name="Services For Telecommunications"
            />
         </div>
    </div>
  )
}

export default Telecommunications
