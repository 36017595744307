import React from 'react'
import './AI_Card.css'

const AI_Card = ({heading,content}) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center">
      <div className='card ai-card'>
        <div className="circle"></div>
        <h4>{heading}</h4>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default AI_Card
