import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Information Technology/Hero.jpg'
import Industries from '../../components/Industries/Industries';
import image from '../../assets/images/Information Technology/image.jpg'
const Information_Technology = () => {
  return (
    <div>
        {/* start hero section  */}
        <Hero
            image={heroImage}
            image_name="Information Technology"
            page_name="Information Technology"
            heading="Future-Ready IT Services for Modern Businesses"
        />
      {/* end hero section  */}

         <div className="container py-lg-5 py-md-4 py-3">
        <Industries
            heading1="Services For "
            heading2="Information Technology"
            content="We offer IT consultancy and service solutions that enable organizations to optimize their technology infrastructure, enhance cybersecurity, and adapt to the ever-changing digital landscape."
            image={image}
            image_name="Information Technology"
        />
         </div>
    </div>
  )
}

export default Information_Technology
