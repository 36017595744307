import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/medical/Hero.jpg';
import Industries from '../../components/Industries/Industries';
import image from '../../assets/images/medical/image.jpg'
const Medical = () => {
  return (
    <div>
        {/* start hero section  */}
        <Hero
            image={heroImage}
            image_name="Healthcare"
            page_name="Healthcare"
            heading="Advanced Medical Services, Close to Home"
        />
      {/* end hero section  */}


      <div className="container py-lg-5 py-md-4 py-3">
        <Industries
            heading1="Services For "
            heading2="Healthcare"
            content="Our healthcare solutions focus on improving patient care and operational efficiency. We provide systems for managing patient records, appointments, and billing, helping healthcare providers deliver quality service while maintaining compliance."
            image={image}
            image_name="Services For Healthcare"
        />
      </div>

    </div>
  )
}

export default Medical
