import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/SCM/Supply chain.jpg'
import Top from '../../components/Top Section/Top'
import TopImage from '../../assets/images/SCM/SCM1.jpg'
import Item from '../../components/Our Approach/Item'
import Features from '../../components/Features/Features'
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const SCM = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Supply Chain Management"
        page_name="Supply Chain Management"
        heading="Streamlining Your Supply Chain for Maximum Efficiency"
      />

      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="TechTorch Solutions: Revolutionizing Supply Chain Management"
          content="TechTorch Solutions  offers comprehensive Supply Chain Management (SCM) solutions designed to enhance visibility, improve efficiency, and reduce costs throughout the supply chain. Our SaaS platform provides businesses with the tools they need to manage and optimize their supply chain processes from procurement to delivery"
          image={TopImage}
          image_name=""
          paras={[
            <p>
              <span>Industries We Serve with Supply Chain Management Solutions: </span>
              Our SCM solutions are tailored to various industries, including manufacturing, retail, pharmaceuticals, and food and beverage, each designed to address specific supply chain challenges and regulatory requirements.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions Private Limitedfor Supply Chain Management?</span>
              We combine industry expertise with innovative technology to deliver solutions that drive results. Our
              dedicated team provides end-to-end support, from initial implementation and customization to
              ongoing optimization and training. Clients benefit from our flexible, scalable solutions that
              integrate seamlessly with their existing systems, enhancing their overall supply chain efficiency.
            </p>


          ]}

        />
      </div>

      {/* End Top Section  */}


       {/* Start Our approch section  */}
       <div className="bg-light-beetroot py-lg-5 py-3">
        <div className="container">
          <div className="row">
            <h5 className='our-approach-heading'>Our Approach</h5>
          </div>
          <div className="row">
            <Item
            icon={<i class="bi bi-cloud"></i>}
            text="Enable business vision"
            />

          <Item
            icon={<i class="bi bi-box"></i>}
            text="Empower data-led decisions"
            />

          <Item
            icon={<i class="bi bi-clouds"></i>}
            text="Build resilience"
            />
             <Item
            icon={<i class="bi bi-person"></i>}
            text="Accelerate innovation"
            />
            
          </div>
        </div>
      </div>
      {/* End Our approch section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Supply Chain Management Solutions:"
          features={[
            <li>
              <span>End-to-End Visibility: </span>
              Gain real-time insights into inventory levels, order status, and supplier performance, enabling better decision-making and responsiveness to market changes.
            </li>,
            <li>
              <span>Demand Forecasting: </span>
              Leverage advanced analytics and machine learning algorithms to predict demand accurately, ensuring that inventory levels align with market needs.
            </li>,

            <li>
              <span>Supplier Collaboration: </span>
              Foster stronger relationships with suppliers through enhanced communication and collaboration tools, streamlining procurement processes and reducing lead times.
            </li>,
            <li>
              <span>Logistics Management: </span>
              Optimize transportation and logistics operations, including route planning, shipment tracking, and freight management, to ensure timely and costeffective delivery.
            </li>,
            <li>
              <span>Risk Management: </span>
              Identify potential risks in the supply chain and develop mitigation
              strategies, ensuring business continuity in the face of disruptions.
            </li>


          ]}
        />
      </div>
      {/* end Key Features section   */}

        <DeliveringSolutions/>
    </>
  )
}

export default SCM
