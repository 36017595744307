import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Idea/Hero.jpg'
import Top from '../../components/Top Section/Top';
import image1 from '../../assets/images/Idea/image1.jpg';
import image2 from '../../assets/images/Idea/image2.jpg';
import image3 from '../../assets/images/Idea/image3.jpg'

const Idea = () => {
  return (
   <div>
    {/* start hero section  */}
    <Hero
        image={heroImage}
        image_name="idea"
        page_name="Idea"
        heading="The Power of an Idea"
      />

      {/* end hero section  */}

      
      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
          <Top
          class_name="top-lower"
          heading="The Idea Behind TechTorch Solutions"
          content="Every great achievement begins with a simple idea—one that sparks innovation and fuels the drive to create something extraordinary. At TechTorch Solutions, our idea was born from a vision to simplify technology for businesses and empower them to achieve their full potential in an increasingly digital world."
            image={image1}
            image_name="Our Inspiration"
            paras={[
            <h1>Our Inspiration </h1>,
              <p>
                The world of technology is constantly evolving, presenting both opportunities and challenges for businesses of all sizes. While large enterprises often have the resources to adapt, small and medium-sized businesses struggle to keep pace with innovation.
              </p>,
              <p>
                This imbalance inspired us to create a platform that bridges this gap—delivering advanced yet accessible IT solutions that drive growth, efficiency, and success for every business.
              </p>
            
            ]}
            
          />
      </div>
     
      {/* End Top Section  */}


     {/* start second section  */}
     <div className="container py-lg-5 py-3">
          <div className="row">
            <div className="col-lg-6 col-md-6">
            <img className='img-fluid rounded-3' src={image2} alt="Our Vision" />
            </div>
            <div className="col-lg-6 col-md-6  d-flex flex-column justify-content-center">
            <h1>Our Vision</h1>
            <p className='lh-lg font-size'>We believe that technology should be an enabler, not a barrier. Our vision is to make cutting-edge digital solutions available to businesses across industries, empowering them to focus on what they do best—while we take care of their technology needs.</p>
            </div>
            
          </div>
      </div>
     {/* end second section  */}


       <div className="container py-lg-5 py-md-4 py-">
            <div className="row flex-column-reverse flex-md-row">
                <div className="col-lg-6 col-md-6">
                    <h1>Turning the Idea into Reality</h1>
                    <p className='font-size'>Since our inception, we have worked tirelessly to transform our vision into actionable solutions</p>
                    <ul className='font-size'>
                        <li className='mb-3'><span className='fw-bold'>Customized IT Services: </span>Tailored strategies that adapt to each client’s unique challenges.</li>
                        <li className='mb-3'><span className='fw-bold'>Innovative SaaS Platforms: </span>Scalable, user-friendly products like Corporation24x7 and Eduficera.</li>
                        <li className='mb-3'><span className='fw-bold'>Global Collaboration: </span>Building a network of partners and clients across diverse industries and regions.</li>
                        <li className='mb-3'><span className='fw-bold'>Sustainable Growth: </span>Operating as a bootstrapped startup with a focus on creating long-term value.</li>
                    </ul>
                </div>
                <div className="col-lg-6 col-md-6">
                    <img className='img-fluid' src={image3} alt="Turning the Idea into Reality" />
                </div>
            </div>
        </div>     

    <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                <h1>A Journey of Innovation</h1>
                <p className='font-size'>What began as an idea has evolved into a thriving ecosystem of services, products, and partnerships. We are proud to be part of a dynamic industry that constantly challenges us to think differently, act boldly, and innovate endlessly. At TechTorch Solutions, our idea isn’t just about technology—it’s about people, progress, and potential. Every project we undertake, every solution we create, and every partnership we build is rooted in our unwavering commitment to turning ideas into impactful realities.
                </p>
            </div>
    </div> 
        

   </div>
  )
}

export default Idea
