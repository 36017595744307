import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/resource-staffing/resource-staffing.jpg';
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/resource-staffing/staff1.jpg';
import image1 from '../../assets/images/resource-staffing/staffing.jpg'
import Features from '../../components/Features/Features'
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const Resource_Staffing = () => {
  return (
    
    <div>

      {/* Start Hero Section  */}

       <Hero
          image={heroImage}
          image_name="Staffing"
          page_name="Staffing"
          heading="Empowering Your Business with Exceptional Talent"
        />
        
      {/* End Hero Section  */}


     {/* Start Top Section  */}
  
      <div className="container py-lg-5 py-3">
          <Top
          heading="Empowering Businesses with Resource and Staffing Excellence"
          content="TechTorch Solutions offers comprehensive Resource and Staffing services designed to provide businesses with skilled professionals and flexible workforce solutions. We help organizations scale efficiently by connecting them with the right talent and offering end-to-end support throughout the hiring and resource management process."
            image={TopImage}
            image_name="Resource and Staffing"
            paras={[
             <h5>Our Approach</h5>,
             <p>Requirement Analysis: Understanding your project needs, goals, and timelines.</p>,
             <p>Talent Sourcing: Accessing our vast talent pool and recruitment network to find the perfect match.</p>,
             <p>Screening and Selection: Conducting rigorous screening processes, including technical assessments and interviews.</p>,
             <p>Onboarding and Integration: Streamlining the onboarding process for quick and efficient integration into your team.</p>
            ]}
            
          />
      </div>
     
     
      {/* End Top Section  */}

         {/* Start Why Choose Us? Section  */}
           <div className="container py-lg-5 py-md-4 py-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded' src={image1} alt="" />
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex flex-column justify-content-center py-lg-0 py-md-0 py-2">
                        <h5 className='fw-bold'>Why Choose Us?</h5>
                       <p className='font-size'>Access to a diverse pool of pre-vetted, highly skilled professionals.</p>
                       <p className='font-size'>Flexible staffing models tailored to your project requirements.</p>
                       <p className='font-size'>Quick turnaround times to meet urgent resource needs.</p>
                       <p className='font-size'>Ongoing support to ensure smooth resource management and performance optimization.</p>
                       <p className='font-size'>At TechTorch Solutions, we ensure that our Resource and Staffing services help your business stay agile, competitive, and well-equipped to handle evolving industry demands.</p>
                    </div>
                </div>
            </div>
           
            {/* End Why Choose Us? Section  */}

     {/* start Key Features section  */}
     <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Offerings"
          features={ [
              <li>
                IT Staffing Services Providing highly qualified IT professionals for short-term or long-term projects. Expertise across various domains, including software development, network management, cybersecurity, data analytics, and more.
              </li>,
              <li>
                Permanent Staffing Helping businesses build a strong team by identifying and hiring the best-fit candidates for full-time roles.Tailored recruitment processes to align with your organizational culture and goals.
              </li>,
              <li>
                Contract Staffing Offering flexible, contract-based staffing solutions for temporary project needs.Ensuring quick onboarding and seamless integration of resources into your team.
              </li>,
              <li>
                Staff Augmentation Extending your in-house team with skilled professionals to bridge skill gaps.Providing experts in technologies like Java, .NET, Python, DevOps, and cloud platforms.
              </li>,
              <li>
                 Executive Search Identifying and recruiting top-tier leadership and specialized talent.Ensuring candidates align with your strategic vision and company objectives.
              </li>,
              <li>
                 Remote Staffing Solutions Sourcing and managing remote talent for global operations.Expertise in remote collaboration tools and frameworks for enhanced productivity.
              </li>,
              <li>
                Resource Management Ongoing monitoring and evaluation of resource performance.Re-skilling and up-skilling programs to ensure alignment with evolving business needs.
              </li>,
              <li>
                Industries Served We cater to a wide range of industries, including IT, healthcare, e-commerce, finance, manufacturing, and more.
              </li>
            ]}
        />
      </div>
    
      {/* end Key Features section   */}

          <DeliveringSolutions/>
    </div>
   
  )
}

export default Resource_Staffing
