import React from 'react';
import './App.css'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import NavigationBar from './components/Navbar/Navbar';

//  Start Capabilities pages 
import ITConsultancy from './pages/IT Consultancy/ITConsultancy';
import Footer from './components/Footer/Footer';
import Artifical from './pages/Artificial Intelligence/Artifical_Intelligence';
import Home from './pages/HomePage/Home';
import CloudInfrastructure from './pages/Cloud Infrastructure Services/Cloud_Infrastructure';
import CyberSecurity from './pages/Cyber Security/Cyber_Security';
import SoftwareEngineering from './pages/Software Engineering Services/Software_Engineering_Services';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SoftwareDeveloper from './pages/Software Developer/Software_Developer';
import ResourceStaffing from './pages/Resource Staffing/Resource_Staffing';
import ERP from './pages/ERP/ERP';
import OperationsManagement from './pages/Operations Management/Operations_Management';
import SCM from './pages/Supply Chain Management/SCM';
import AviationManagement from './pages/Aviation Management/Aviation_Management';
import PeopleResources from './pages/People Resources/People_Resources';
import WebPortals from './pages/Web Portals/Web_Portals';
import FinancialManagement from './pages/Financial Management/Financial_Management';
import PaymentManagement from './pages/Payment Management/Payment_Management';
import HealthCare from './pages/HealthCare & Hospital Management/HealthCare';
import CRM from './pages/CRM/CRM';
import Ecommerce from './pages/Ecommerce/Ecommerce';
import ProjectManagement from './pages/Project Management/Project_Management';
import Eduficera from './pages/Eduficera/Eduficera';
import Corporation from './pages/Corporation24x7/Corporation';
import BPS from './pages/Business Process Services/BPS';
import TechMate from './pages/TechMate/TechMate';
//  End Capabilities pages 

// Discover 
import Overview from './pages/Company Overview/Overview';
import Leadership from './pages/Leadership/Leadership';
import Idea from './pages/Idea/Idea';
import Investors from './pages/Investors/Investors';
import PartnersEcosystem from './pages/Partners Ecosystem/Partners_Ecosystem';
import CEO from './pages/CEO/CEO';
import COO from './pages/COO/COO';
import HR from './pages/HR/HR';

// Industries
import Finance from './pages/Finance/Finance';
import Education from './pages/Education/Education';
import Medical from './pages/Medical/Medical';
import ECommerce from './pages/E-Commerce/E-Commerce';
import InformationTechnology from './pages/Information Technology/Information_Technology';
import Manufacturing from './pages/Manufacturing/Manufacturing';
import Telecommunications from './pages/Telecommunications/Telecommunications';
import Energy from './pages/Energy/Energy';
import Transportation from './pages/Transportation/Transportation';
import FMCG from './pages/FMCG/FMCG';

// Insights 
import CaseStudies from './pages/Case Studies/Case_Studies';

// Clients 
import Clients from './pages/Clients/Clients';
import Contacts from './pages/Contacts/Contacts';

// Footer 
import Terms from './pages/Terms of Use/Terms';
import Cookie from './pages/Cookie/Cookie';
import Accessibility from './pages/Accessibility/Accessibility';
import Privacy from './pages/Privacy/Privacy';

// Career 
import Career from './pages/Career/Career'


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <NavigationBar />
      <Routes>
      {/*Start Capabilities Page  */}
        <Route path="/" element={<Home/>} />
        <Route path='/it-consultancy' element={<ITConsultancy/>}/>
        <Route path='/artificial-intelligence' element={<Artifical/>}/>
        <Route path='/cloud-infrastructure' element={<CloudInfrastructure/>}/>
        <Route path='/cyber-security' element={<CyberSecurity/>}/>
        <Route path="/software-engineering-services" element={<SoftwareEngineering/>}/>
        <Route path="/software-developer" element={<SoftwareDeveloper/>}/>
        <Route path="/resource-staffing" element={<ResourceStaffing/>}/>
        <Route path="/erp" element={<ERP/>}/>
        <Route path="/operations-management" element={<OperationsManagement/>}/>
        <Route path="/supply-chain" element={<SCM/>}/>
        <Route path='/aviation-management' element={<AviationManagement/>}/>
        <Route path='/people-resources' element={<PeopleResources/>}/>
        <Route path='/web-portals' element={<WebPortals/>}/>
        <Route path='/financial-management' element={<FinancialManagement/>}/>
        <Route path='/payment-management' element={<PaymentManagement/>}/>
        <Route path='/healthcare-management' element={<HealthCare/>}/>
        <Route path='/crm' element={<CRM/>}/>
        <Route path='/ecommerce' element={<Ecommerce/>}/>
        <Route path='/project-management' element={<ProjectManagement/>}/>
        <Route path='/eduficera' element={<Eduficera/>}/>
        <Route path='/corporation' element={<Corporation/>}/>
        <Route path='/business-process-outsourcing' element={<BPS/>}/>
        <Route path='/tech-mate' element={<TechMate/>}/>
      {/*End Capabilities Page  */}

      {/* Start Discover Pages  */}
      <Route path='/company-overview' element={<Overview/>} />
      <Route path='/leadership' element={<Leadership/>} />
      <Route path='/idea' element={<Idea/>} />
      <Route path='/investors' element={<Investors/>} />
      <Route path='/partners-ecosystem' element={<PartnersEcosystem/>} />
      <Route path='/ceo' element={<CEO/>} />
      <Route path='/coo' element={<COO/>} />
      <Route path='/hr' element={<HR/>} />
      {/* End Discover Pages  */}

      {/* Start Industries Pages  */}
      <Route path='/finance' element={<Finance/>} />
      <Route path='/education' element={<Education/>} />
      <Route path='/healthcare' element={<Medical/>} />
      <Route path='/e-commerce' element={<ECommerce/>} />
      <Route path='/it' element={<InformationTechnology/>} />
      <Route path='/manufacturing' element={<Manufacturing/>} />
      <Route path='/telecommunications' element={<Telecommunications/>} />
      <Route path='/energy' element={<Energy/>} />
      <Route path='/transportation' element={<Transportation/>} />
      <Route path='/fmcg' element={<FMCG/>} />
      {/* End Industries Pages  */}
      
      {/* Start Insights Pages  */}
      <Route path='/case-studies' element={<CaseStudies/>} />
      {/* end Insights Pages  */}


      <Route path='/clients' element={<Clients/>} />
      <Route path='/contact' element={<Contacts/>} />

      {/* Start Footer Pages  */}
      <Route path='/terms-of-use' element={<Terms/>} />
      <Route path='/cookie' element={<Cookie/>} />
      <Route path='/accessibility' element={<Accessibility/>} />
      <Route path='/privacy' element={<Privacy/>} />
      {/* End Footer Pages  */}

    {/* Start Career page  */}
    <Route path='/career' element={<Career/>} />
    {/* End Career page  */}
     

    
        
      </Routes>
      <Footer /> 
      </BrowserRouter>
  );
}

export default App;


