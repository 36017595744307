import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/CRM/CRM.jpg'
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/CRM/CRM1.jpg';
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';



const CRM = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Customer Relationship Management"
        page_name="Customer Relationship Management"
        heading="Enhancing customer experience and engagement"
      />

      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Building Stronger Connections with Comprehensive CRM Solutions"
          content="TechTorch Solutions offers a comprehensive Customer Relationship Management (CRM) platform designed to help businesses build and maintain strong relationships with their customers. Our SaaS CRM solution provides tools for managing customer interactions, sales processes, and marketing campaigns, ensuring a holistic approach to customer engagement."
          image={TopImage}
          image_name="Customer Relationship Management "
          paras={[
            <p>
              <span>Industries We Serve with CRM Solutions: </span>
              Our CRM solutions are tailored to various sectors, including retail, healthcare, finance, education, and technology, providing industry-specific functionalities to meet unique customer relationship challenges.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions Private Limitedfor CRM? </span>
              With a focus on user experience and adaptability, our CRM solutions empower businesses to optimize their customer interactions and drive growth. We offer comprehensive implementation support, training, and ongoing optimization, ensuring clients can leverage our platform to its fullest potential. By partnering with TechTorch Solutions, organizations can enhance their customer engagement strategies, improve retention rates, and achieve long-term business success.
            </p>
          ]}

        />
      </div>
      {/* End Top Section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our CRM Solutions:"
          features={[
            <li>
              <span>Lead and Opportunity Management: </span>
              Tracks leads and opportunities throughout the
              sales funnel, providing visibility into the sales process and enabling sales teams to
              prioritize efforts and close deals effectively.
            </li>,
            <li>
              <span>Contact Management: </span>
              Centralizes customer information, allowing businesses to
              manage contacts, track interactions, and gain insights into customer behavior and
              preferences.
            </li>,
            <li>
              <span>Sales Automation: </span>
              Streamlines sales processes by automating routine tasks such as
              follow-ups, reminders, and reporting, freeing up time for sales representatives to focus on
              building relationships.
            </li>,
            <li>
              <span>Customer Support and Service:</span>
              Includes tools for managing customer inquiries,
              support tickets, and feedback, enabling businesses to provide exceptional service and
              enhance customer satisfaction.
            </li>,
            <li>
              <span>Marketing Automation: </span>
              Facilitates targeted marketing campaigns with tools for email
              marketing, social media management, and analytics, helping businesses engage with
              customers effectively and measure campaign performance.
            </li>
          ]}
        />
      </div>
      {/* end Key Features section   */}

          <DeliveringSolutions/>
    </>
  )
}

export default CRM
