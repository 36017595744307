import React from 'react'
import './Top.css'
import TopSection from '../../components/Top Section/Top/Top'
const Top = ({class_name,heading,content,image,image_name,paras}) => {
  return (
    
      <>
      <TopSection
      heading={heading}
      content={content}
      />

      <div className="row flex-column-reverse flex-md-row ">
        <div className={`col-lg-6 col-md-6 d-flex flex-column justify-content-center py-lg-0 py-md-0 py-2  top-lower`}>
        {paras}
        </div>
        <div className="col-lg-6 col-md-6 d-flex flex-column justify-content-center">
          <img className='img-fluid top-image' src={image} alt={image_name} />
        </div>
         
        </div>
    </>
  )
}

export default Top
