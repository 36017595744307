import React from 'react'
import { Link } from 'react-router-dom'
import './Card.css'
const Card = ({jp,eligibility,skills,Preferred_Qualifications,link}) => {
  return (
    <div className="col-12">
        <div className='job-card p-lg-4 p-3'>
            <h3>Vaccancy Details</h3>
            <h6 className='mb-3'><span className='fw-bold'>Job Profile: </span>{jp}</h6>
            <h6 className='mb-3'><span className='fw-bold'>Eligibility: </span>{eligibility}</h6>
            <h6 className='mb-3'><span className='fw-bold'>Skills:</span>
            <ul>
                {skills.map((item,index)=>(<li key={index}>{item}</li>))}
            </ul>
            </h6>
            <h6 className='mb-3'><span className='fw-bold'>Preferred Qualifications: </span>
            <ul>
            {Preferred_Qualifications.map((item,index)=>(<li key={index}>{item}</li>))}
            </ul>
            </h6>
            
            <p className='text-end'><Link to={link} className='home-btn link'>Apply Now</Link></p>
        </div>
    </div>
  )
}

export default Card
