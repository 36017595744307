import React from 'react'
import './Industries.css'
const Industries = ({heading1,heading2,content,image,image_name}) => {
  return (
    <div className="row flex-column-reverse flex-md-row industries">
    <div className="col-lg-6 col-md-6 py-3">
        <h1><span className='heading1'>{heading1}</span> <span className='heading2'>{heading2}</span></h1>
        <p>{content}</p>
    </div>
    <div className="col-lg-6 col-md-6">
        <img className='img-fluid' src={image} alt={image_name} />
    </div>
    </div>
  )
}

export default Industries
