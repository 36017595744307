import React from 'react'
import './Overview.css'
import Hero from '../../components/Hero/Hero'
import image from '../../assets/images/Company Overview/TopImage.jpg'

const Overview = () => {
    return (
        <div className='overview'>
            {/* start hero section  */}
            <Hero
                class_name="hide-image"
                page_name="Company Overview"
                heading="Company Overview: Building a Better Future Together"
            />

            {/* Start Top Section  */}
            <div className="section mt-0 py-lg-5 py-md-4 py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <h1>We are TechTorch Solutions</h1>
                        </div>
                        <div className="col-lg-4 col-md-4">
                        <p className='font-size'>
                            TechTorch Solutions . is an innovative IT startup, practicing since 2019 and formally registered as an entity in 2024. We provide cuttingedge  technology solutions across India and are actively expanding our client and partner network globally.
                        </p>
                        </div>
                        <div className="col-lg-4 col-md-">
                            <p className='font-size'>
                            Our expertise spans IT consultancy, artificial intelligence, software
                            development, cloud infrastructure, cyber security, and business process outsourcing (BPO), enabling businesses to achieve digital transformation efficiently and securely
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Top Section  */}


            <div className="section py-lg-5 py-md-4 py-3">
                <div className="container">
                    <div className="row flex-column-reverse flex-md-row">
                        <div className="col-lg-6 col-md-6">
                            <div className="overview-content">
                                <h1>Your Partner in Global Digital Success</h1>
                                <p className='font-size'>Our tailored services include AI-driven solutions, secure digital infrastructures, and a customer-centric approach designed to meet the highest industry standards. Serving clients PAN India, We are strategically expanding our presence in key markets across Europe, the United States, and the Gulf region, Africa, Australia, and Asia, empowering businesses of all sizes to effectively navigate the complexities of the modern digital landscape.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <img className='img-fluid rounded-3' src={image} alt="Your Partner in Global Digital Success" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Image Section  */}
            <div className="section">
                <div className='cloud-second-image'>
                    <div className='cloud-second-image-content'>
                        <h5>
                            At TechTorch Solutions, we are committed to innovation and scalability, delivering solutions that empower businesses to unlock new opportunities and drive growth

                        </h5>
                    </div>
                </div>
                
                
            </div>
            {/* End Image Section  */}

        </div>
    )
}

export default Overview
