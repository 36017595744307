import React from 'react'

const Cookie = () => {
  return (
    <>
        <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                <h1 className='fw-bold'>Cookie Policy</h1>
                <h6>Effective Date: Oct,01,2024</h6>
                <p>At TechTorch Solutions, we are committed to respecting your privacy and ensuring transparency in how we collect and use information. This Cookie Policy explains how we use cookies and similar technologies on our website.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>What Are Cookies?</h5>
                <p>Cookies are small text files stored on your device (computer, tablet, or smartphone) when you visit a website. They help websites function effectively and provide a better user experience by remembering your preferences and activities.</p>

                <h5  className='fw-bold mt-lg-4 mt-3'>Types of Cookies We Use</h5>
                <h6 className='fw-bold mt-3'>Essential Cookies</h6>
                <ul className='list-unstyled'>
                    <li>These cookies are necessary for the website to function and cannot be turned off. They include actions such as enabling navigation and accessing secure areas of the website.</li>
                    <li>Example: Login authentication cookies.</li>
                </ul>
               

                <h6 className='fw-bold mt-lg-4 mt-3'>Performance Cookies</h6>
                <ul className='list-unstyled'>
                    <li>These cookies collect information about how visitors interact with our website, such as the pages they visit most often. This helps us improve our website's functionality.</li>
                    <li>Example: Google Analytics.</li>
                </ul>
                
                <h6 className='fw-bold mt-lg-4 mt-3'>Functional Cookies</h6>
                <ul className='list-unstyled'>
                    <li>These cookies remember your preferences and choices to provide enhanced features, such as remembering your language or region.</li>
                    <li>Example: Storing selected themes or language preferences.</li>
                </ul>
               

                <h6 className='fw-bold mt-lg-4 mt-3'>Targeting and Advertising Cookies</h6>
                <ul className='list-unstyled'>
                    <li>These cookies track your browsing habits to deliver personalized ads relevant to you. They may also limit how often you see an advertisement and measure campaign effectiveness.</li>
                    <li>Example: Cookies set by third-party advertising networks.</li>
                </ul>


                <h6  className='fw-bold mt-lg-4 mt-3'>Why We Use Cookies</h6>
                <ul className='list-unstyled'>
                    <li>To enhance user experience.</li>
                    <li>To analyze website performance and improve functionality. </li>
                    <li>To deliver personalized content and advertisements.</li>
                    <li>To remember your preferences for a seamless browsing experience.</li>
                </ul>

                <h6  className='fw-bold mt-lg-4 mt-3'>Third-Party Cookies</h6>
                <p>We may use third-party services, such as Google Analytics or social media platforms, that set cookies on our website. These third parties have their own privacy policies, and we recommend reviewing them to understand how your data is handled.</p>

                <h6  className='fw-bold mt-lg-4 mt-3'>Managing Cookies</h6>
                <ul className='list-unstyled'>
                    <li>You can control and manage cookies in several ways:</li>
                    <li>Browser Settings: Most browsers allow you to block or delete cookies.</li>
                    <li>Cookie Settings on Our Website: You can adjust your cookie preferences through the cookie consent banner or settings available on our site.
                    Opt-Out Tools: Use tools like Network Advertising Initiative to opt out of targeted advertising.</li>
                    <li>Please note that disabling certain cookies may impact your experience and some features of the website.</li>
                </ul>
                
               
                
                <h6 className='fw-bold mt-lg-4 mt-3'>Updates to This Policy</h6>
                <p>We may update this Cookie Policy periodically to reflect changes in technology, legal requirements, or our practices. Please review this page regularly for the latest updates.</p>

                <h6 className='fw-bold mt-lg-4 mt-3'>Contact Us</h6>
                <ul className='list-unstyled'>
                    <li>If you have questions about this Cookie Policy, please contact us:</li>
                    <li><span className='fw-bold'>Email: </span>contact@techtorch.solutions</li>
                    
                </ul>
                

            </div>
        </div>
    </>
  )
}

export default Cookie
