import React from 'react'
import Hero from '../../components/Hero/Hero';
import Industries from '../../components/Industries/Industries';
import heroImage from '../../assets/images/FMCG/Hero.jpg'
import image from '../../assets/images/FMCG/image.jpg'
const FMCG = () => {
  return (
    <div>
    {/* start hero section  */}
    <Hero
        image={heroImage}
        image_name="FMCG"
        page_name="FMCG"
        heading="Innovating the Way You Shop Daily"
    />
  {/* end hero section  */}


{/* Start Content Section  */}
  <div className="container py-lg-5 py-md-4 py-3">
    <Industries
        heading1="SERVICES For "
        heading2="FMCG"
        content="We help FMCG businesses stay competitive in fast-moving markets by improving inventory management, optimizing supply chains, and enhancing customer engagement. Our tools ensure smooth operations, reduced costs, and faster response to evolving consumer demands."
        image={image}
        image_name="Servies for FMCG "
    />
  </div>
  {/* End Content Section  */}

    </div>
  )
}

export default FMCG
