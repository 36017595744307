import React from 'react'
import { Link } from 'react-router-dom'
import image from '../../assets/images/Leadership/image5.jpg'
const COO = () => {
  return (
    <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 d-flex align-items-center">
                    <img className='img-fluid' src={image} alt="Amit Gaur" />
                </div>
                <div className="col-lg-6 col-md-6 ">
                <div className="ceo">
                    <h6>Leadership</h6>
                    <h1 className='fw-bold mb-3'>Amit Gaur </h1>
                    <h4>Co-Founder & (COO) TechTorch Solutions</h4>
                    <div className='social-media'>
                    {/* <Link  className='link'><i className="bi bi-twitter-x"></i></Link> */}
                    <Link to="https://www.linkedin.com/in/amit-gaur-4754a278/" className='link'><i className="bi bi-linkedin"></i></Link>
                   
                    </div>
                    <p>
                    Amit Gaur provides TechTorch Solutions with a plethora of operational expertise stemming from his more than ten years of experience in networking and database management. As co-founder and chief operating officer, he is essential to maximizing operational effectiveness, fostering  ross-organizational connections, and overseeing significant investments.   
                    </p>
                    <p>
                    Amit makes sure that every day operations run smoothly and that
                    business activities are in line with the company's strategic objectives. His style of leadership is based on accountability; he creates an environment where taking
                    responsibility for one's choices and actions promotes success and progress within the firm.
                    </p>

                </div>
                </div>
            </div>
        </div>
  )
}

export default COO
