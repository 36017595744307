import React from 'react'

const Content = ({heading,content}) => {
    return (
        <div className="col-lg-6 col-md-6 d-flex flex-column justify-content-center bpo-content">
            <h1>{heading}</h1>
            <p>{content}</p>
        </div>
    )
}

export default Content
