import "./Products_and_Platforms.css"
import {Link} from 'react-router-dom'
import { Carousel } from 'react-bootstrap';
import product1 from '../../assets/images/Product and Platforms1.jpg'
import product2 from '../../assets/images/Product and Platforms2.jpg'


const Products_and_Platforms = () => {
    const slides = [
        {
            image:product1,
            paragraph: "Corporation24x7",
            content: "Corporation24x7 is an innovative ERP solution designed to streamline business operations through three key modules: Human Resource Management (HRM), Customer Relationship Management (CRM), and Accounts. This comprehensive platform is tailored to meet the needs of small to medium-sized businesses, providing essential tools to enhance efficiency and collaboration.",
            link:"/corporation"

        },

        {
            image:product2,
            paragraph: "Edufic Era",
            content: "Edufic Era is a comprehensive ERP solution tailored specifically for the education sector, encompassing schools, colleges, and universities. Our platform is designed to streamline administrative processes, enhance student engagement, and facilitate effective communication within educational institutions",
             link:"/eduficera"

        },
        
       
    ]


    return (

        

        <>
        <div className="container py-lg-5 py-3">
                 <h1 className="main-heading">Product and Platforms</h1><hr className="main-hr"/>
                 <Carousel controls={false} indicators={true}>
                {slides.map((slide, index) => (
                    <Carousel.Item key={index}>
                        <div className="container py-lg-5 py-3 product-slider">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 gx-0">
                                    <div className="carousel-background">
                                        <img className="img-fluid" src={slide.image} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 gx-0 py-lg-0 py-3">
                                    <div className='slider-content px-3'>
                                        <h4>{slide.paragraph}</h4>
                                        <p>{slide.content}</p>
                                        <Link to={slide.link}>Learn More</Link>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
         </div>
           
        </>

    )
}

export default Products_and_Platforms
