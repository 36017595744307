import React from 'react'
import './Card.css'
const Card = ({image,image_name,heading,content}) => {
  return (
    <div className="col-lg-4 col-md-4 col-sm-6">
        <div className="eduficera-content">
            <img className='img-fluid mb-3' src={image} alt={image_name} />
            <h4>{heading}</h4>
            <p>{content}</p>
        </div>
    </div>
  )
}

export default Card
