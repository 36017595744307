import React from 'react'
import './Map.css'

const Map = () => {
    return (
        <div className='container-fluid map'>
            {/* <div className='container map-container'> */}
                <div className='responsive-map'>
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.580072541351!2d79.45593797456345!3d28.401748694525057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a0070009661593%3A0xe67b6969edbfef88!2sTechTorch%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1732884664191!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            {/* </div> */}
        </div>
    )
}

export default Map
