import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import logo from "../../assets/images/Logo.png"
import footerImage from '../../assets/images/Footer/footer_image.jpg'
import PdfDownloadButton from '../PdfDownloadButton/PdfDownloadButton';
import QuickBrochure from '../Quick Brochure/Quick_Brochure';

const Footer = () => {
  const handleEmailClick = () => {
    const email = "contact@techtorch.solutions";
    const subject = "Consultation for my Business need";
    const body = "";
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };
  return (
    <>
      <div className="container-fluid g-0">
        <div className="row g-0 footer">
          <div className="col-lg-5 col-md-6">
            <img className='img-fluid' src={footerImage} alt="" />
          </div>
          <div className="col-lg-7 col-md-6 ps-lg-5 ps-4 py-3 d-flex flex-column justify-content-center">
            <h2>Unlock you business potential now.</h2>
            <p>Discover the key to your business success.</p>
            <span><button onClick={handleEmailClick} className='main-btn'>Talk to our experts</button></span>
          </div>
        </div>
      </div><hr className='footer-hr'/>
      
      <div className='container-fluid footer'>
        <div className='container'>
          <div className='row'>
            <p className='mt-lg-5 mt-3'>SOCIAL</p>
            <h2>Follow us for the latest updates</h2>
            <div className='footer-head-icon'>
              <a href='https://www.facebook.com/techtorchsol?mibextid=ZbWKwL' target="_blank"  rel="noopener noreferrer" ><i className="fa-brands fa-facebook-f"></i><span>facebook</span></a>

              <a href='https://x.com/techtorch_sol/' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-x-twitter"></i><span>Twitter</span></a>

              <a href='https://www.youtube.com/@techtorch_sol' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-youtube"></i><span>YouTube</span></a>

              <a href='https://www.instagram.com/techtorch_sol/' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i><span>Instagram</span></a>

              <a href='https://in.linkedin.com/company/techtorch_sol' target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i><span>Linkedin</span></a>
            </div>
            <hr />
          </div>
          <div className='row py-4'>
            <div className='col-lg-2 col-md-3 col-sm-6 mb-3 '>
              <h6>Industries</h6>
              <ul className='ps-0  list-unstyled footer-links-container'>
                <li><Link to="/finance" className='footer-link'>Finance</Link> </li>
                <li><Link to="/education" className='footer-link'>Education</Link> </li>
                <li><Link to="/healthcare" className='footer-link'>Healthcare</Link> </li>
                <li><Link to="/e-commerce" className='footer-link'>E-Commerce</Link> </li>
                <li><Link to="/it" className='footer-link'>Information Technology</Link> </li>
                <li><Link to="/manufacturing" className='footer-link'>Manufacturing</Link> </li>
                <li><Link to="/telecommunications" className='footer-link'>Telecommunications</Link> </li>
                <li><Link to="/energy" className='footer-link'>Energy</Link> </li>
                <li><Link to="/transportation" className='footer-link'>Transportation</Link> </li>
                <li><Link to="/fmcg" className='footer-link'>FMCG</Link> </li>
              

              </ul>

            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3 '>
              <h6>Services</h6>
              <ul className='ps-0  list-unstyled footer-links-container'>
                <li><Link to="/it-consultancy" className='footer-link'>IT Consultancy</Link> </li>
                <li><Link to="/artificial-intelligence" className='footer-link'>Artificial Intelligence (AIaas)</Link> </li>
                <li><Link to="/cloud-infrastructure" className='footer-link'>Cloud Infrastructure Services </Link> </li>
                <li><Link to="/cyber-security" className='footer-link'>Cyber Security </Link> </li>
                <li><Link to="/software-engineering-services" className='footer-link'>Software Engineering Services </Link> </li>
                <li><Link to="/business-process-outsourcing" className='footer-link'>Business Process Outsourcing (BPO)</Link> </li>
                <li><Link to="/software-developer" className='footer-link'>Software Development & Support</Link> </li>
                <li><Link to="/resource-staffing" className='footer-link'>Resource and Staffing</Link> </li>
                <li><Link to="/" className='footer-link'>Web Solutions (SaaS)</Link> </li>
                <li><Link to="/" className='footer-link'>Tech Courses</Link> </li>

              </ul>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <h6>Digital Solutions</h6>
              <ul className='ps-0  list-unstyled footer-links-container'>
              <li><Link to="/erp" className='footer-link'>ERP</Link> </li>
              <li><Link to="/operations-management" className='footer-link'>Operations Management</Link> </li>
              <li><Link to="/supply-chain" className='footer-link'>Supply Chain Management</Link> </li>
              <li><Link to="/aviation-management" className='footer-link'>Aviation Management</Link> </li>
              <li><Link to="/people-resources" className='footer-link'>People Resources</Link> </li>
              <li><Link to="/web-portals" className='footer-link'>Web Portals</Link> </li>
              <li><Link to="/financial-management" className='footer-link'>Financial Management</Link> </li>
              <li><Link to="/payment-management" className='footer-link'>Payment Management</Link> </li>
              <li><Link to="/healthcare-management" className='footer-link'>HealthCare & Hospital Management</Link> </li>
              <li><Link to="/crm" className='footer-link'>CRM</Link> </li>
              <li><Link to="/ecommerce" className='footer-link'>Ecommerce</Link> </li>
              <li><Link to="/project-management" className='footer-link'>Project Management</Link> </li>
            
              </ul>
            </div>

            <div className='col-lg-2 col-md-3 col-sm-6 mb-3 '>
              <h6>Products & Platforms</h6>
              <ul className='ps-0  list-unstyled footer-links-container'>
              <li><Link to="/corporation" className='footer-link'>Corporation24x7</Link> </li>
              <li><Link to="/eduficera" className='footer-link'>Eduficera</Link> </li>
              <li><Link to="/tech-mate" className='footer-link'>TechMate</Link> </li>
              </ul>
            </div>
            <div className='col-lg-2 col-md-3 col-sm-6 mb-3 '>
              <h6>Important Links</h6>
              <ul className='ps-0  list-unstyled footer-links-container'>
              <li><Link to="/case-studies" className='footer-link'>Case Studies</Link></li>
              <li><Link to="/career" className='footer-link'>Career</Link></li>
              <PdfDownloadButton btn_name="Company Profile" class_name='footer-link'  />
              <QuickBrochure btn_name="Quick Brochure" class_name='footer-link'/>
              {/* <li><Link to="/" className='footer-link'>News</Link> </li> */}
              {/* <li><Link to="/" className='footer-link'>Events</Link> </li> */}
              {/* <li><Link to="/" className='footer-link'>Views</Link> </li> */}
              {/* <li><Link to="/" className='footer-link'>Analyst</Link> </li> */}

              </ul>
            </div>
          </div>
        </div>

      </div>
      <div className='container-fluid footer-bottom'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-2 col-md-3 col-sm-0 mb-3 '>
              <Link to='/'>
                <img src={logo}
                  className='img-fluid footer-logo' alt='TechTorch Soutions' />
              </Link>
            </div>
            <div className='col-lg-10 col-md-9 mt-3'>
            <span className='footer-copyright me-lg-5 me-md-3 me-sm-3'>©2024 TechTorch Solutions Private Limited</span>
              <ul className='footer-bottom-links '>
                <li><Link to="/terms-of-use">Terms of Use</Link></li>
                <li><Link to="accessibility">Accessibility</Link></li>
                <li><Link to="privacy">Privacy</Link></li>
                <li><Link to="cookie">Cookie</Link></li>
              </ul>
    
            </div>


          </div>
        </div>
      </div>

    </>
  )
}

export default Footer
