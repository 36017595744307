import React from "react";

const PdfDownloadButton = ({btn_name,class_name}) => {
 
    const companyProfile = () => {
    // Path to your PDF file
    const filePath = "/company_profile.pdf";

    // Create a temporary link and trigger the download
    const link = document.createElement("a");
    link.href = filePath;
    link.download = "techtorch_profile.pdf"; // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    };


  return (
        <li onClick={companyProfile} className={class_name}>{btn_name}</li>
  );
};

export default PdfDownloadButton;
