import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Manufacturing/hero.jpg'
import image from '../../assets/images/Manufacturing/image.jpg';
import Industries from '../../components/Industries/Industries';
const Manufacturing = () => {
  return (
    <div>
        {/* start hero section  */}
        <Hero
            image={heroImage}
            image_name="Manufacturing"
            page_name="Manufacturing"
            heading="Precision Manufacturing, Reliable Solutions"
        />
      {/* end hero section  */}

         <div className="container py-lg-5 py-md-4 py-3">
        <Industries
            heading1="Services For "
            heading2="Manufacturing"
            content="Our manufacturing solutions optimize production processes, enhance supply chain management, and improve quality control. We help manufacturers leverage technology to increase efficiency and reduce operational costs."
            image={image}
            image_name="Services for Manufacturing"
        />
         </div>
    </div>
  )
}

export default Manufacturing
