import React from 'react'

const Terms = () => {
  return (
    <>
        <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                <h1 className='fw-bold'>Terms of Use</h1>
                <h6>Effective Date: Oct,01,2024</h6>
                <p>Welcome to TechTorch Solutions! These Terms of Use govern your access to and use of our website, products, and services. By accessing or using our website, you agree to comply with these terms. If you do not agree, please refrain from using our services.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>1. Acceptance of Terms</h5>
                <ul className='list-unstyled'>
                  <li>By accessing our website, you confirm that:</li>
                  <li>You are at least 18 years of age or have parental/guardian consent.</li>
                  <li>You will comply with all applicable laws and regulations while using our website.</li>
                </ul>
               

                <h5 className='fw-bold mt-lg-4 mt-3'>2. Intellectual Property Rights</h5>
                <p>All content on this website, including text, graphics, logos, images, videos, and software, is the intellectual property of TechTorch Solutions or its licensors. Unauthorized use, reproduction, or distribution of this content is strictly prohibited.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>3. Use of Website</h5>
                <ul className='list-unstyled'>
                  <li>You agree to use this website only for lawful purposes and refrain from:</li>
                  <li>Posting or sharing unlawful, harmful, or offensive content.</li>
                  <li>Attempting to hack, disrupt, or compromise the website’s security.</li>
                  <li>Using the website for any unauthorized commercial purposes.</li>
                </ul>
                

                <h5 className='fw-bold mt-lg-4 mt-3'>4. User Accounts</h5>
                <ul className='list-unstyled'>
                  <li> Certain features may require you to create an account. By creating an account, you agree to:</li>
                  <li>Provide accurate and up-to-date information.</li>
                  <li>Maintain the confidentiality of your account credentials.</li>
                  <li>Notify us immediately of any unauthorized use of your account.</li>
                </ul>
                
                <h5 className='fw-bold mt-lg-4 mt-3'>5. Third-Party Links</h5>
                <p>Our website may include links to third-party websites for your convenience. We do not endorse or control these websites and are not responsible for their content or practices.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>6. Limitation of Liability</h5>
                <ul className='list-unstyled'>
                  <li>To the fullest extent permitted by law, TechTorch Solutions shall not be held liable for:</li>
                  <li>Any indirect, incidental, or consequential damages arising from your use of the website.</li>
                  <li>Any errors, interruptions, or data loss due to technical issues or external factors.</li>
                </ul>
                
                <h5 className='fw-bold mt-lg-4 mt-3'>7. Disclaimer of Warranties</h5>
                <p>
                Our website and services are provided "as is" without any warranties, express or implied. While we strive to ensure the accuracy of our content, we do not guarantee its completeness, reliability, or suitability for any purpose.
                </p>

                <h5 className='fw-bold mt-lg-4 mt-3'>8. Indemnification</h5>
                <ul className='list-unstyled'>
                  <li>You agree to indemnify and hold harmless TechTorch Solutions, its employees, and affiliates from any claims, damages, or losses arising from:</li>
                  <li>Your violation of these Terms of Use.</li>
                  <li>Your misuse of the website or services.</li>
                </ul>
                
                <h5 className='fw-bold mt-lg-4 mt-3'>9. Termination</h5>
                <p>We reserve the right to terminate or suspend your access to the website, without prior notice, for any breach of these Terms of Use or other misconduct.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>10. Changes to Terms</h5>
                <p>TechTorch Solutions may update these Terms of Use periodically. Your continued use of the website signifies your acceptance of any changes. We encourage you to review these terms regularly.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>11. Governing Law</h5>
                <p>These Terms of Use are governed by and construed in accordance with the laws of  Bareilly Uttar Pradesh, and any disputes shall be subject to the exclusive jurisdiction of courts in  Bareilly Uttar Pradesh.</p>

                <h5 className='fw-bold mt-lg-4 mt-3'>12. Contact Information</h5>
                <ul className='list-unstyled'>
                  <li>If you have any questions or concerns about these Terms of Use, please contact us:</li>
                  <li><span className='fw-bold'>Email: </span>contact@techtorch.solutions</li>
                 
                </ul>
               
            </div>
        </div>
    </>
  )
}

export default Terms
