import React from 'react'

const Accessibility = () => {
  return (
    <>
        <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                <h1 className='fw-bold'>Accessibility Statement</h1>
                <h6>Effective Date: Oct,01,2024</h6>
                <p>At TechTorch Solutions, we are committed to ensuring that our website and services are accessible to everyone, including individuals with disabilities. We believe in creating an inclusive digital experience and continuously work to improve accessibility across all our platforms.</p>

                <h6 className='fw-bold mt-lg-4 mt-3'>Our Commitment</h6>
                <ul className='list-unstyled'>
                    <li>We aim to meet or exceed the requirements of applicable accessibility laws and guidelines, including:</li>
                    <li>Web Content Accessibility Guidelines (WCAG) 2.1 Level AA.</li>
                    <li>Regional and international accessibility regulations.</li>
                    <li>Our goal is to ensure that individuals of all abilities can easily access our content, navigate our site, and benefit from our services.</li>
                </ul>

                <h6 className='fw-bold mt-lg-4 mt-3'>Accessibility Features</h6>
                <ul className='list-unstyled'>
                    <li>To enhance accessibility, our website includes the following features:</li>
                    <li><span className='fw-bold'>Keyboard Navigation: </span> Fully operable using a keyboard for individuals with motor disabilities.</li>
                    <li><span className='fw-bold'>Screen Reader Compatibility: </span>Optimized for assistive technologies such as screen readers.</li>
                    <li><span className='fw-bold'>Text Alternatives: </span>Descriptive text for images and multimedia.</li>
                    <li><span className='fw-bold'>Adjustable Text Size: </span>Flexible font sizes for users with visual impairments.</li>
                    <li><span className='fw-bold'>High-Contrast Mode: </span>Designed to support readability for users with low vision.</li>
                 
                </ul>
                

                <h6 className='fw-bold mt-lg-4 mt-3'>Ongoing Efforts</h6>
                <ul className='list-unstyled'>
                    <li>We understand that accessibility is an ongoing process. To maintain and improve accessibility, we:</li>
                    <li>Regularly test our website for accessibility compliance.</li>
                    <li>Train our team on accessibility best practices.</li>
                    <li>Welcome feedback from users to identify and address accessibility barriers.</li>
                  
                </ul>

                <h6 className='fw-bold mt-lg-4 mt-3'>Third-Party Content</h6>
                <p>While we strive to ensure accessibility on our website, some third-party content or plugins may not fully meet accessibility standards. We are working with these providers to enhance their accessibility features.</p>

                <h6 className='fw-bold mt-lg-4 mt-3'>Feedback and Assistance</h6>
                <ul className='list-unstyled'>
                    <li>We value your input in making our website more accessible. If you encounter any issues or have suggestions for improvement, please contact us:
                    </li>
                    <li><span className='fw-bold'>Email: </span>contact@techtorch.solutions</li>
                    <li>If you need assistance with accessing any part of our website, our support team is here to help.</li>
                </ul>

                <h6 className='fw-bold mt-lg-4 mt-3'>Future Plans</h6>
                <p>TechTorch Solutions is dedicated to evolving our accessibility efforts. We will continue to integrate new technologies and adopt industry standards to enhance user experience for everyone.</p>
            </div>
        </div>
    </>
  )
}

export default Accessibility
