import React from 'react'

const Quick_Brochure = ({btn_name,class_name}) => {
    const quickBrochure = () => {
        // Path to your PDF file
        const filePath = "/quick_brochure.pdf";
    
        // Create a temporary link and trigger the download
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "quick_brochure.pdf"; // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        };
  return (
    <li onClick={quickBrochure} className={class_name}>{btn_name}</li>
  )
}

export default Quick_Brochure
