import React from 'react'
import { Link } from 'react-router-dom'
import './CEO.css'
import image from '../../assets/images/Leadership/image4.jpg'
const CEO = () => {
  return (
          <div className="container py-lg-5 py-md-4 py-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 d-flex align-items-center">
                    <img className='img-fluid' src={image} alt="Siddharth Pathak" />
                </div>
                <div className="col-lg-6 col-md-6 ">
                <div className="ceo">
                    <h6>Leadership</h6>
                    <h1 className='fw-bold mb-3'>Siddharth Pathak</h1>
                    <h4>Founder & CEO TechTorch Solutions</h4>
                    <div className='social-media'>
                    {/* <Link  className='link'><i className="bi bi-twitter-x"></i></Link> */}
                    <Link to="https://www.linkedin.com/in/siddharth-pathak-19b025149?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className='link'><i className="bi bi-linkedin"></i></Link>
                   
                    </div>
                    <p>
                        With more than seven years of considerable experience in the IT industry, Siddharth Pathak has demonstrated the ability to drive innovation across a wide range of business categories and technology. In his capacity as TechTorch Solutions' inspirational CEO and Founder, Siddharth has played a key role in directing the corporation's strategic business choices, carrying out successful expansion plans, and establishing TechTorch as a reputable and dynamic name in the technology services industry.

                    </p>
                    <p>
                    The company has successfully negotiated challenging industry landscapes under his guidance, growing both its clientele and its global reach. Building trust and autonomy among his teams is at the core of Siddharth's leadership style. He feels that motivated workers bring the most value and creativity to the company.
                    </p>

                </div>
                </div>
            </div>
        </div>
  
  )
}

export default CEO
