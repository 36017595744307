import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Project Management/Project Management.jpg'
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/Project Management/Project Management1.jpg'
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';


const Project_Management = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Project Management"
        page_name="Project Management"
        heading="Ensuring seamless coordination and successful delivery."
      />


      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Driving Project Success with Powerful Management Solutions "
          content="TechTorch Solutions provides powerful Project Management solutions designed to enhance collaboration, streamline workflows, and drive project success. Our SaaS platform equips teams with the tools needed to plan, execute, and monitor projects effectively, ensuring that deadlines are met and objectives are achieved."
          image={TopImage}
          image_name="Project Management"
          paras={[
            <p>
              <span>Industries We Serve with Project Management Solutions: </span>
              Our Project Management solutions
              cater to a wide range of industries, including construction, IT, healthcare, marketing, and
              product development, each tailored to meet specific project management needs and challenges.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions Private Limitedfor Project Management? </span>
              With a
              commitment to enhancing productivity and team performance, our Project Management
              solutions are designed to adapt to the unique requirements of each organization. We provide
              comprehensive implementation support, training, and ongoing updates, ensuring clients can
              fully leverage our platform. By partnering with TechTorch Solutions, organizations can improve
              project visibility, enhance collaboration, and achieve greater operational efficiency.
            </p>
          ]}

        />
      </div>
      {/* End Top Section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Project Management Solutions:"
          features={[
            <li>
              <span>Task and Milestone Tracking: </span>
              Easily create, assign, and monitor tasks and milestones,
              allowing teams to stay organized and focused on project deliverables and timelines.
            </li>,
            <li>
              <span>Collaboration Tools: </span>
              Facilitates real-time communication and collaboration among team
              members through integrated messaging, file sharing, and discussion forums, fostering a
              cohesive working environment.
            </li>,
            <li>
              <span>Resource Management: </span>
              Provides insights into resource allocation and availability,
              enabling project managers to optimize team capacity and ensure the right skills are
              applied to the right tasks.
            </li>,
            <li>
              <span>Gantt Charts and Kanban Boards: </span>
              Visualize project timelines and workflows with
              intuitive Gantt charts and Kanban boards, simplifying project planning and tracking
              progress.
            </li>,
            <li>
              <span>Reporting and Analytics: </span>
              Generates detailed reports and analytics on project
              performance, resource utilization, and budget tracking, helping teams make informed
              decisions and identify areas for improvement.
            </li>

          ]}
        />
      </div>
      {/* end Key Features section   */}

      <DeliveringSolutions/>

    </>
  )
}

export default Project_Management
