import React from 'react'
import Card from './Card'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import service3 from '../../assets/images/services/service3.png'
import service4 from '../../assets/images/services/service4.png'
import service5 from '../../assets/images/services/service5.png'
import service6 from '../../assets/images/services/service6.png'
import service7 from '../../assets/images/services/service7.png'
import service8 from '../../assets/images/services/service8.png'
const Cards = () => {
  return (
    <div className='container-fluid py-4 card-container'>
      <div className='container'>
        <div className="row py-3">
          <h1 className='main-heading'> Our Services</h1><hr className='main-hr'/>
        </div>
        <div className="row text-center">
              <Card 
              image={service1}
              image_name="IT Consultancy"
              heading="IT Consultancy"
              text="At TechTorch Solutions, our Information Technology Consulting service is designed to guide businesses through their digital transformation journey"
              link="/it-consultancy"
              />
              
              <Card 
              image={service2}
              image_name="Artificial Intelligence"
              heading="Artificial Intelligence"
              text="At TechTorch Solutions, we provide cutting-edge Artificial Intelligence as a Service (IaaS) that enables businesses to harness the power of AI without the complexity of building and maintaining their own infrastructure."
              link="/artificial-intelligence"
              />

              
              <Card 
              image={service5}
              image_name="Cloud Infrastructure Services (IaaS)"
              heading="Cloud Infra (IaaS)"
              text="At TechTorch Solutions, we provide robust Cloud Infrastructure as a Service (IaaS) solutions that empower businesses to scale and optimize their IT operations seamlessly."
              link="/cloud-infrastructure"
              />
              
              <Card 
              image={service3}
              image_name="Cyber Security"
              heading="Cyber Security"
              text="At TechTorch Solutions, we prioritize the protection of your digital assets through our comprehensive cybersecurity services. In an era where cyber threats are ever-evolving, our solutions are designed to safeguard your organization against"
              link="/artificial-intelligence"
              />
  
            <Card 
              image={service4}
              image_name="Software Engineering Services"
              heading="Software Engineering Services"
              text="At TechTorch Solutions, we offer comprehensive Engineering Services designed to support businesses across various sectors in achieving their technical and operational goals."
              link="/software-engineering-services"
              />



              <Card 
              image={service8}
              image_name="Bussiness Process Outsourcing"
              heading="Bussiness Process Outsourcing"
              text="At TechTorch Solutions, we specialize in Business Process Outsourcing (BPO) services designed to enhance operational efficiency and allow businesses to focus on their core competencies"
              link="/business-process-services"
              />

            <Card 
              image={service6}
              image_name="Software Development & Support"
              heading="Software Development & Support"
              text="At TechTorch Solutions, we provide end-to-end software development and support services to help businesses achieve operational excellence and drive innovation."
               link="/software-developer"
              />

              <Card 
              image={service7}
              image_name="Resource and Staffing"
              heading="Resource and Staffing"
              text="TechTorch Solutions offers comprehensive Resource and Staffing services designed to provide businesses with skilled professionals and flexible workforce solutions."
               link="/resource-staffing"
              />
          
        </div>
      </div>
    </div>
  )
}

export default Cards
