import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Case Studies/Hero.jpg';
import Top from '../../components/Top Section/Top/Top';
import Accordion from '../../components/Accordion/Accordion';
const Case_Studies = () => {
    const items = [
        {
             title: 'Case Study 1: E-Commerce Transformation',
             content:[
                {
                    heading:"Client Challenges",
                    text:"A retail client struggled with an outdated online store, leading to poor user experience, high cart abandonment rates, and stagnant sales growth. They needed a solution that would modernize their e-commerce platform and improve customer engagement."
                },
                {
                    heading:"Solutions Implemented",
                    text:"TechTorch Solutions Private Limitedimplemented a state-of-the-art E-Commerce platform with enhanced UI/UX design, mobile responsiveness, and integrated marketing tools. We provided comprehensive training and ongoing support to ensure a smooth transition."
                },
               {
                 heading:"Results Achieved",
                text:"The client saw a 50% decrease in cart abandonment rates and a 40% increase in online sales within the first six months post-implementation. Customer satisfaction ratings improved significantly, contributing to brand loyalty."
               }
            ]
         },
         {
            title: 'Case Study 2: Streamlined Project Management',
            content:[
               {
                   heading:"Client Challenges",
                   text:"An IT services company faced difficulties in managing multiple projects simultaneously, resulting in missed deadlines and resource conflicts. They required a more efficient way to monitor progress and collaborate across teams."
               },
               {
                   heading:"Solutions Implemented",
                   text:"We introduced our Project Management solution, incorporating Gantt charts and Kanban boards for better visualization of workflows. The solution included task assignment features and realtime collaboration tools to enhance team communication."
               },
              {
                heading:"Results Achieved",
               text:"As a result, the client reported a 35% improvement in project delivery times and a 25% increase in team productivity. They were able to manage projects more effectively, leading to higher client satisfaction."
              }
           ]
        },
        {
            title: 'Case Study 3: Enhanced Marketing ROI',
            content:[
               {
                   heading:"Client Challenges",
                   text:"A marketing agency was struggling with low engagement rates in their campaigns and inefficient use of marketing resources. They needed a solution to streamline their marketing efforts and maximize ROI."
               },
               {
                   heading:"Solutions Implemented",
                   text:"TechTorch implemented an integrated Marketing & Advertising platform that automated campaign management and audience segmentation. The agency received training on utilizing data analytics for targeted marketing strategies."
               },
              {
                heading:"Results Achieved",
               text:"Within three months, the agency experienced a 60% increase in campaign engagement and a 50% rise in leads generated. The improved analytics capabilities allowed for more informed decision-making, significantly enhancing marketing performance."
              }
           ]
        },
        {
            title: 'Case Study 4: Operational Efficiency in Supply Chain',
            content:[
               {
                   heading:"Client Challenges",
                   text:"A logistics company faced challenges in inventory management and delivery schedules, leading to increased costs and customer dissatisfaction. They needed a solution to optimize their supply chain processes."
               },
               {
                   heading:"Solutions Implemented",
                   text:"We deployed our Supply Chain Management solution, which included real-time inventory tracking and predictive analytics for demand forecasting. Our team worked closely with theirs to integrate the new system with existing processes."
               },
              {
                heading:"Results Achieved",
               text:"Post-implementation, the company achieved a 30% reduction in inventory holding costs and improved delivery times by 20%. Customer satisfaction scores increased due to more reliable service and timely deliveries."
              }
           ]
        }

      ];
  return (
    <>
         {/* start hero section  */}
            <Hero
                image={heroImage}
                image_name="Case Studies"
                page_name="Case Studies"
                heading="Real Results: Our Success Stories"
            />
      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-md-4 py-3">
      <Top
        heading="Tailored Solutions, Tangible Results: Our Case Studies"
        content="At TechTorch Solutions, we understand that each client presents unique challenges that require tailored solutions. Below are some case studies highlighting our approach, the solutions we implemented, and the impressive results achieved."
      />
      </div>
      {/* End Top Section  */}

      {/* Start Accordion Section  */}
      <div className="container py-3">
        <div className="row">
            <h1 className='mb-lg-4 mb-3 fw-bold text-dark-beetroot'>Driving Business Success Through Tailored Tech Solutions</h1>
        </div>
        <div className="row">
            <div className="col-lg-8">
            <Accordion items={items} />
            </div>
        </div>
      </div>
      {/* End Accordion Section  */}
    </>
  )
}

export default Case_Studies
