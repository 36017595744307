import React from 'react'
import Digital from '../../components/Digital Solutions/Digital_Solutions'
import Product from '../../components/Products and Platforms/Products_and_Platforms'
import Map from '../../components/Map/Map'
import Card from '../../components/Card/Cards'
import BackgroundVideo from '../../components/BackgroundVideo/BackgroundVideo'
const Home = () => {
  return (
    <div>
      <BackgroundVideo/>
      <Digital/>
      <Card/>
      <Product/>
      <Map/>

    </div>
  )
}

export default Home
