import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/People Resources/People Resources.jpg'
import Top from '../../components/Top Section/Top'
import TopImage from '../../assets/images/People Resources/People Resources1.jpg'
import Features from '../../components/Features/Features'
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';



const People_Resources = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="People Resources"
        page_name="People Resources"
        heading="Human Resources and Development"
      />

      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Empowering Your Workforce with Innovative People Resources Solutions"
          content="At TechTorch Solutions, our People Resources solutions are designed to streamline and enhance the management of human capital within organizations. We understand that people are a company’s greatest asset, and our SaaS platform empowers businesses to attract, develop, and retain top talent while fostering a positive work culture."
          image={TopImage}
          image_name="People Resources"
          paras={[
            <p>
              <span>Industries We Serve with People Resources Solutions:</span>
              Our People Resources solutions cater to a diverse range of industries, including manufacturing, technology, healthcare, retail, and education, providing industry-specific functionalities to meet unique workforce challenges.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions Private Limitedfor People Resources? </span>
              We combine advanced technology with a deep understanding of human resource management to deliver solutions that drive results. Our team provides end-to-end support, from implementation to ongoing optimization, ensuring that organizations can maximize the potential of their workforce. With our user-friendly interface and customizable features, clients can adapt the platform to meet their specific HR needs, fostering a culture of continuous improvement and employee engagement.

            </p>

          ]}

        />
      </div>
      {/* End Top Section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our People Resources Solutions:"
          features={[
            <li>
              <span>Recruitment Management: </span>
              Automates the recruitment process, from job posting and
              applicant tracking to interview scheduling and onboarding, ensuring a seamless
              experience for both candidates and HR teams.
            </li>,
            <li>
              <span>Employee Management: </span>
              Provides comprehensive tools for managing employee data,
              performance evaluations, training, and career development, supporting employee growth
              and satisfaction.
            </li>,
            <li>
              <span>Payroll and Benefits Administration: </span>
              Simplifies payroll processing, benefits
              enrollment, and compliance with tax regulations, ensuring timely and accurate
              compensation for employees.
            </li>,
            <li>
              <span>Time and Attendance Tracking: </span>
              Monitors employee attendance, leaves, and work
              hours, enabling accurate reporting and analysis of workforce productivity.
            </li>,
            <li>
              <span>Performance Management: </span>
              Facilitates goal setting, feedback collection, and
              performance reviews, aligning employee objectives with organizational goals to drive
              engagement and accountability.
            </li>,




          ]}
        />
      </div>
      {/* end Key Features section   */}

        <DeliveringSolutions/>

    </>

  )
}

export default People_Resources
