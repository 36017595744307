import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/TechMate/Hero.jpg'
import Top from '../../components/Top Section/Top/Top';
import image from '../../assets/images/TechMate/image.jpg';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const TechMate = () => {
    return (
        <>
            {/* start hero section  */}
            <Hero
                image={heroImage}
                image_name="TechMate"
                page_name="TechMate"
                heading="TechMate: Your All-in-One CRM and Lead Management Solution"
            />

            {/* end hero section  */}

            {/* start top section  */}
            <div className="container py-lg-5 py-md-4 py-3">
                <Top
                    heading="Empower Customer Relationships and Accelerate Sales with TechMate"
                    content="TechMate is an advanced integrated CRM solution designed to empower
businesses in managing customer relationships and streamlining lead
management processes. This comprehensive platform combines essential CRM
functionalities with powerful lead management tools, helping organizations
enhance customer engagement and drive sales success.
"
                />
            </div>
            {/* end top section  */}

            {/* Start Image Section  */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid' src={image} alt="Empower Customer Relationships and Accelerate Sales with TechMate" />
                    </div>
                    <div className="col-lg-6 col-md-6 font-size">
                        <p><span className='fw-bold'>Lead Capture and Tracking: </span>
                            Automatically capture leads from multiple sources,
                            including web forms, social media, and email campaigns. Our system allows for
                            seamless tracking of lead interactions, ensuring that every opportunity is captured
                            and nurtured.</p>
                        <p><span className='fw-bold'>Lead Qualification: </span>Efficiently prioritize and segment leads based on criteria
                            such as engagement levels and demographics. This targeted approach enables
                            sales teams to focus on high-potential leads, boosting conversion rates
                        </p>
                        <p><span className='fw-bold'>Sales Pipeline Management: </span>Visualize your sales pipeline with intuitive
                            dashboards that provide real-time insights into lead status and progression.
                            Manage deals effectively, guiding them from initial contact to successful closure.</p>
                        <p><span className='fw-bold'>Automated Follow-Ups: </span>Enhance communication with automated follow-up
                            reminders and customizable email templates. Maintain consistent engagement
                            with leads, ensuring timely responses to inquiries.
                        </p>
                        <p><span className='fw-bold'>Analytics and Reporting: </span>Access actionable insights through advanced analytics
                            and customizable reporting features. Track Key Performance Indicators (KPIs) to
                            evaluate the effectiveness of your lead management strategies and inform
                            decision-making.
                        </p>

                    </div>
                </div>
                <div className="row py-lg-5 py-md-4 py-3">
                    <p className='font-size'>With TechMate, businesses can improve their sales processes, enhance customer
                        satisfaction, and drive growth. This solution is designed to fit seamlessly into your existing workflows, providing the flexibility and scalability needed for success.</p>
                </div>
            </div>
            {/* End Image Section  */}

            <DeliveringSolutions/>

        </>
    )
}

export default TechMate
