import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Ecommerce/Ecommerce.jpg';
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/Ecommerce/Ecommerce1.jpg';
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const Ecommerce = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Ecommerce"
        page_name="Ecommerce"
        heading="Comprehensive Ecommerce Platforms"
      />

      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Empowering Online Success with Innovative E-Commerce Solutions"
          content="TechTorch Solutions Private Limitedprovides innovative E-Commerce solutions designed to empower businesses to establish and grow their online presence. Our SaaS platform offers a comprehensive suite of tools for managing online stores, enhancing customer experiences, and driving sales, making it easier for businesses to succeed in the digital marketplace."
          image={TopImage}
          image_name="Ecommerce"
          paras={[
            <p>
              <span>Industries We Serve with E-Commerce Solutions: </span>
              Our E-Commerce solutions cater to a diverse range of industries, including retail, fashion, electronics, health and beauty, and food and beverage, providing tailored functionalities to meet industry-specific needs.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions for E-Commerce? </span>
              With a focus on innovation and scalability, our E-Commerce solutions are designed to grow alongside your business. We provide end-to-end support, from implementation and training to ongoing maintenance and updates, ensuring that clients can adapt to changing market demands. By partnering with TechTorch Solutions, businesses can build a robust online presence, drive sales growth, and deliver exceptional customer experiences.
            </p>

          ]}

        />
      </div>
      {/* End Top Section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our E-Commerce Solutions:"
          features={[
            <li>
              <span>User-Friendly Storefront Design: </span>
              Create visually appealing and responsive online
              storefronts that provide seamless browsing experiences across all devices, enhancing
              customer engagement and conversion rates.
            </li>,
            <li>
              <span>Product Management: </span>
              Easily manage product listings, inventory levels, pricing, and
              promotions, ensuring that your online store is always up-to-date and aligned with
              business goals.
            </li>,
            <li>
              <span>Secure Payment Processing: </span>
              Offers multiple secure payment gateways, allowing
              customers to complete transactions with confidence while protecting sensitive financial
              information.

            </li>,
            <li>
              <span>Customer Relationship Tools: </span>
              Integrates CRM functionalities to track customer
              behavior, preferences, and purchase history, enabling personalized marketing and
              improved customer service.
            </li>,
            <li>
              <span>Analytics and Reporting: </span>
              Provides in-depth analytics tools to monitor sales
              performance, customer trends, and website traffic, allowing businesses to make datadriven decisions and optimize their strategies.
            </li>

          ]}
        />
      </div>
      {/* end Key Features section   */}

          <DeliveringSolutions/>
    </>
  )
}

export default Ecommerce
