import React,{useRef} from 'react'
import { Link } from 'react-router-dom'
import './Corporation.css'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/corporation/corporation.jpg'
import data from '../../assets/images/corporation/data.png'
import Card from '../../components/Corporation/Card'
import reliability from '../../assets/images/corporation/reliability.png'
import flexibility from '../../assets/images/corporation/flexibility.png'
import user from '../../assets/images/corporation/user.png'
import oreus from '../../assets/images/corporation/Oreus.png';
import dm from '../../assets/images/corporation/Logo-DM1.png';
import em from '../../assets/images/corporation/em.png';
import a from '../../assets/images/corporation/A.png';
import Card1 from '../../components/Eduficera Card/Card'
import MA from '../../assets/images/corporation/Modular Architecture.png';
import RTC from '../../assets/images/corporation/Real-Time Collaboration.png';
import IWA from '../../assets/images/corporation/Intelligent Workflow Automation.png';
import CM from '../../assets/images/corporation/Compliance Management.png';
import IOC from '../../assets/images/corporation/Interactive Org Chart.png';
import CKT from '../../assets/images/corporation/Customizable KPI Tracking.png';
import DRD from '../../assets/images/corporation/Dynamic Reporting & Dashboards.png';
import CMH from '../../assets/images/corporation/Client Management Hub.png';
import MLMCS from '../../assets/images/corporation/Multi-Language and Multi-Currency Support.png';
import RBP from '../../assets/images/corporation/Role-Based Personalization.png';
import ICCT from '../../assets/images/corporation/Integrated Chat and Communication Tools.png';
import VPM from '../../assets/images/corporation/Vendor and Partner Management.png';
import ATL  from '../../assets/images/corporation/Audit Trails and Logs.png';
import ABT from '../../assets/images/corporation/Advanced Budgeting Tools.png';
import AFF  from '../../assets/images/corporation/Adaptive Financial Forecasting.png';
import RSA  from '../../assets/images/corporation/Resource Scheduling and Allocation.png';
import BCF from '../../assets/images/corporation/Business Continuity Features.png';
import ESG from '../../assets/images/corporation/ESG (Environmental, Social, Governance) Monitoring.png';
import CDR from '../../assets/images/corporation/Centralized Document Repository.png';
import GEE  from '../../assets/images/corporation/Gamified Employee Engagement.png';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';



const Corporation = () => {
  const featuresSection=useRef(null);
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleEmailClick = () => {
    const email = "contact@techtorch.solutions";
    const subject = "Want to know more and demo of corporation 24x7";
    const body = "";
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };


  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Corporation24x7"
        page_name="Corporation24x7"
        heading="Empowering Businesses, Anytime, Anywhere"
      />
      {/* end hero section  */}

      <div className="container py-lg-5 py-md-4 py-3">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-lg-6 col-md-6">
            <h3 className='mb-lg-4 mb-3'>Corporation24x7: A Smart ERP for HRM, CRM, and Accounting Excellence</h3>
            <p className='mb-3 font-size'>Corporation24x7 is an innovative mini ERP solution designed to streamline business operations through three key modules: Human Resource Management (HRM), Customer Relationship Management (CRM), and Accounts. This comprehensive platform is tailored to meet the needs of small to medium-sized businesses, providing essential tools to enhance efficiency and collaboration.</p>
            <button onClick={handleEmailClick} className='main-btn-second'>Talk to our experts</button>
          </div>
          <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
            <img className='img-fluid' src={data} alt="data" />
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light-beetroot">
        <div className="container py-lg-5 py-md-4 py-3">
          <div className="row font-size">
            <ul>
              <li className='mb-3'><span className='fw-bold'>HRM Module: </span> Manage employee data, track performance, and simplify payroll processes
                with our user-friendly HRM module. It allows businesses to streamline recruitment,
                onboarding, and employee engagement, ensuring a productive workforce.
              </li>
              <li className='mb-3'> <span className='fw-bold'>CRM Module: </span> Enhance customer relationships and drive sales growth with our CRM
                module. It provides tools for managing client interactions, tracking sales activities, and
                automating marketing processes, enabling businesses to nurture leads and improve
                customer satisfaction.
              </li>
              <li><span className='fw-bold'>Accounts Module: </span> Simplify financial management with our Accounts module, designed to
                handle invoicing, expense tracking, and financial reporting. This module offers real-time
                insights into your financial health, helping you make informed decisions.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <div className="container py-lg-5 py-md-4 py-3">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <h2 className='mb-lg-5 mb-md-4 mb-2'>Track Record Performance, Unmacteched Affordability.</h2>
              <button className='main-btn-second mb-lg-5' onClick={()=>scrollToSection(featuresSection)}>Explore Us</button>
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <h1 className='text-dark-beetroot fw-bold'>9.2x</h1>
                  <p>faster than market alternatives </p>
                </div>
                <div className="col-lg-6 col-md-6">
                  <h1 className='text-dark-beetroot fw-bold'>96%</h1>
                  <p>Affordable than the competition</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Start Card Section  */}
      <div className="container-fluid bg-light-beetroot">
        <div className="container py-lg-5 py-md-4 py-3">
          <div className="row mb-lg-5 mb-md-4 mb-3 text-center">
            <h2>Why TechTorch Solutions?</h2>
            <h5>Building the Foundation for Data-Driven Growth</h5>
          </div>

          <div className="row">
            <Card
              image={reliability}
              image_name="Reliable"
              heading="Reliable"
              content="Over 100+ users acros various industries in Asia, Europe and US Countries."
            />

            <Card
              image={flexibility}
              image_name="Customizable"
              heading="Customizable"
              content="End-to-end customization according to business need and oprations managements."
            />

            <Card
              image={user}
              image_name="Easy-to-Use"
              heading="User Friendly"
              content="Easy to use and human-centric interface as well as third-party integrations and extensions adaptable solution."
            />


          </div>
        </div>
      </div>
      {/* End Card Section  */}


      <div className="container py-lg-5 py-md-4 py-3">
        <div className="row font-size">
          <h5>Corporation24x7: Uniting Business Functions for Productivity and Growth</h5>
          <p>With Corporation24x7, businesses can integrate these essential functions into a single platform, improving overall productivity and fostering better decision-making. Our solution is designed to adapt to the unique requirements of each organization, ensuring seamless implementation and scalability as your business grows.</p>
        </div>
      </div>

      {/* Start Trusted Organizations Section  */}
      <div className="container-fluid bg-light-beetroot">
        <div className="container py-lg-5 py-md-4 py-3 ">
          <div className="row mb-lg-5 mb-md-4 mb-3">
            <h2 className='text-center'>Brands Who Trust Us Around the Globe.</h2>
          </div>
            <div className="row mb-lg-5 mb-md-4 mb-3">
              <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-lg-0 mb-4">
                <img className='img-fluid' src={oreus} alt="" />
              </div>



              <div className="col-lg-1 col-md-3 col-sm-4 col-6 mb-lg-0 mb-4">
                <img className='img-fluid' src={dm} alt="dm" />
              </div>



              <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-lg-0 mb-4">
                <img className='img-fluid' src={em} alt="em" />
              </div>

              <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb-lg-0 mb-4">
                <img className='img-fluid' src={a} alt="astroscope" />
              </div>


            </div>
        </div>
      </div>
      {/* End Trusted Organizations Section  */}



      {/* Start Related Products Section  */}
      <div className="container-fluid carporation-product">
        <div className="container py-lg-5 py-md-4 py-3">
          <div className="row mb-lg-5 mb-md-4 mb-3">
            <h1>Related Products</h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="product-card p-4 mb-lg-0 mb-3">
                <h6 className='mb-4'>TechMate</h6>
                <p className='mb-5'>CRM Tool with intregation abilibity.</p>
                <Link to="/tech-mate" className='link'>Learn More</Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* End Related Products Section  */}


      {/* Start Complete Features Section  */}
      <div className="bg-light-beetroot" ref={featuresSection}>
        <div className="container py-lg-5 py-md-4 py-3 ">
          <div className="row">
            <h1 className='main-heading'>Complete Features</h1><hr className='main-hr' />
          </div>
          <div className="row">
            <Card1
              image={MA}
              image_name="Modular Architecture"
              heading='Modular Architecture'
              content="Pick and choose modules like HRM, CRM, or Accounts, with the flexibility to scale as your business grows."
            />

            <Card1
              image={RTC}
              image_name="Real-Time Collaboration"
              heading='Real-Time Collaboration'
              content="Enable seamless collaboration between teams through shared workspaces and instant updates."
            />

            <Card1
              image={IWA}
              image_name="Intelligent Workflow Automation"
              heading='Intelligent Workflow Automation'
              content="Automate repetitive tasks like approvals, notifications, and scheduling, boosting productivity."
            />

            <Card1
              image={CM}
              image_name="Compliance Management"
              heading='Compliance Management'
              content="Built-in tools to ensure adherence to corporate policies, labor laws, and tax regulations."
            />

            <Card1
              image={IOC}
              image_name="Interactive Org Chart"
              heading='Interactive Org Chart'
              content="Visualize your organization's structure with an interactive, drag-and-drop org chart."
            />

            <Card1
              image={CKT}
              image_name="Customizable KPI Tracking"
              heading='Customizable KPI Tracking'
              content="Track team and individual performance with KPIs tailored to your business goals."
            />

            <Card1
              image={DRD}
              image_name="Dynamic Reporting & Dashboards"
              heading='Dynamic Reporting & Dashboards'
              content="Real-time analytics with interactive dashboards for deeper insights into operations and financials.
"
            />

            <Card1
              image={CMH}
              image_name="Client Management Hub"
              heading='Client Management Hub'
              content="Manage client portfolios, contracts, billing cycles, and support tickets from a single interface."
            />

            <Card1
              image={MLMCS}
              image_name="Multi-Language and Multi-Currency Support"
              heading='Multi-Language and Multi-Currency Support'
              content="Operate across borders with features that support diverse languages and currencies."
            />

            <Card1
              image={RBP}
              image_name="Role-Based Personalization"
              heading='Role-Based Personalization'
              content="Offer customized views and functionalities based on user roles (e.g., managers, employees, or executives)."
            />

            <Card1
              image={ICCT}
              image_name="Integrated Chat and Communication Tools"
              heading='Integrated Chat and Communication Tools'
              content="Secure internal chat system to enhance communication without relying on third-party apps."
            />

            <Card1
              image={VPM}
              image_name="Vendor and Partner Management"
              heading='Vendor and Partner Management'
              content="Centralized tracking of vendor agreements, payments, and performance metrics."
            />

            <Card1
              image={ATL}
              image_name="Audit Trails and Logs"
              heading='Audit Trails and Logs'
              content="Maintain detailed records of all system changes for accountability and transparency."
            />

            <Card1
              image={ABT}
              image_name="Advanced Budgeting Tools"
              heading='Advanced Budgeting Tools'
              content="Allocate budgets across departments, track spending, and generate variance reports."
            />

            <Card1
              image={AFF}
              image_name="Adaptive Financial Forecasting"
              heading='Adaptive Financial Forecasting'
              content="AI-driven tools to predict cash flow, revenue trends, and operational expenses."
            />

            <Card1
              image={RSA}
              image_name="Resource Scheduling and Allocation"
              heading='Resource Scheduling and Allocation'
              content="Optimize resource utilization with tools to assign projects, track timelines, and balance workloads."
            />

            <Card1
              image={BCF}
              image_name="Business Continuity Features"
              heading='Business Continuity Features'
              content="Automated backups and disaster recovery plans to ensure uninterrupted operations."
            />

            <Card1
              image={ESG}
              image_name="ESG (Environmental, Social, Governance) Monitoring"
              heading='ESG (Environmental, Social, Governance) Monitoring'
              content="Track and report on sustainability metrics like carbon footprint, community impact, and compliance."
            />

            <Card1
              image={CDR}
              image_name="Centralized Document Repository"
              heading='Centralized Document Repository'
              content="Secure storage and easy access to corporate policies, contracts, and important documents."
            />

            <Card1
              image={GEE}
              image_name="Gamified Employee Engagement"
              heading='Gamified Employee Engagement'
              content="Gamification elements like rewards, leaderboards, and challenges to boost employee motivation."
            />
          </div>
        </div>
      </div>
      {/* End Complete FeaturesSection  */}

      <DeliveringSolutions/>
    </>
  )
}

export default Corporation
