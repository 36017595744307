import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Financial Management/Financial Management.jpg';
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/Financial Management/Financial Management1.jpg';
import Features from '../../components/Features/Features';
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';


const Financial_Management = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Financial Management"
        page_name="Financial Management"
        heading="Financial Operations and Strategy"
      />
      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Streamlined Financial Management for Smarter Business Decisions"
          content="TechTorch Solutions Private Limitedoffers comprehensive Financial Management solutions designed to streamline financial operations, improve accuracy, and enhance decision-making for businesses of all sizes. Our SaaS platform provides the tools necessary for effective financial planning, analysis, and reporting, helping organizations maintain financial health and compliance."
          image={TopImage}
          image_name="Financial Management"
          paras={[
            <p>
              <span>Industries We Serve with Financial Management Solutions: </span>
              Our Financial Management solutions cater to diverse industries, including manufacturing, retail, healthcare, non-profits, and professional services, each tailored to meet specific financial challenges and regulatory requirements.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions for Financial Management? </span>
              Our solutions combine cutting-edge technology with deep financial expertise, providing businesses with the tools needed to navigate complex financial landscapes. We offer comprehensive support, from initial implementation and training to ongoing optimization and updates, ensuring that clients can leverage the full potential of our platform. By partnering with TechTorch Solutions, organizations can achieve greater financial clarity, control, and strategic advantage.
            </p>
          ]}

        />
      </div>
      {/* End Top Section  */}

      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Financial Management Solutions:"
          features={[
            <li>
              <span>Automated Accounting Processes: </span>
              Simplifies key accounting tasks, including
              invoicing, accounts payable/receivable, and reconciliations, reducing manual effort and
              minimizing errors.
            </li>,
            <li>
              <span>Budgeting and Forecasting:  </span>
              Provides robust tools for creating budgets and forecasts,
              enabling businesses to plan for the future and allocate resources effectively.
            </li>,
            <li>
              <span>Financial Reporting and Analytics: </span>
              Generates real-time financial reports and analytics,
              offering insights into cash flow, profitability, and financial performance, empowering
              informed decision-making.
            </li>,
            <li>
              <span>Compliance Management: </span>
              Ensures adherence to financial regulations and standards,
              with features that support tax compliance and financial auditing processes.
            </li>,
            <li>
              <span>Integrated Expense Management: </span>
              Streamlines expense tracking and approval
              processes, allowing organizations to monitor spending and control costs effectively.
            </li>
          ]}
        />
      </div>
      {/* end Key Features section   */}

          <DeliveringSolutions/>


    </>
  )
}

export default Financial_Management
