import React from 'react'
import nc from '../../assets/images/AI/nc logo.svg'
import dell from '../../assets/images/AI/Dell_Logo.svg.png'
const Delivering_solutions = () => {
  return (
    <div className='ai-container py-lg-5 py-md-4 py-3  mb-0'>
        <div className="container">
          <div className="row ">
            <h1 className='mb-lg-5'>Delivering solutions powered by</h1>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src="https://img.icons8.com/androidL/200/FFFFFF/amazon-web-services.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img google-img' src="https://white.logodownload.org/wp-content/uploads/2020/11/google-white-logo.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img microsoft-img' src="https://logodix.com/logo/1069296.png" alt="" />
            </div>
            
            
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={nc} alt="nc" />
            </div>

            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={dell} alt="dell" />
            </div>
          </div>
        </div>
      </div>
  )
}

export default Delivering_solutions
