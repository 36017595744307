import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css'
const Card = ({image,image_name,name,position,link,linkedin_link,class_name}) => {
  return (
                <div className="col-lg-6 col-md-6 mb-lg-0 mb-3">
                <div className="leadership-card">
                    <div className="row gx-0">
                        <div className="col-lg-6 col-md-6"><img className='img-fluid' src={image} alt={image_name} /></div>
                        <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <h4>{name}</h4>
                        <h5 className='mb-3'>{position}</h5>
                        <p>
                          <Link to={linkedin_link} className='linkedin_link'><i className="bi bi-linkedin"></i></Link>
                          </p>
                        <Link to={link} className={`home-btn link ${class_name}`}>Read More</Link>
                        </div>
                    </div>
                 </div>
                </div>
  )
}

export default Card
