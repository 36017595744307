import React from 'react'
import './Image_Section.css'
const Image_Section = ({image,image_name,heading,content}) => {
  return (
         <div className='image-section'>
            <img className='img-fluid' src={image} alt={image_name} />
            <div className="container content-container">
                <div className="row">
               <div className="content">
               <h2>{heading}</h2>
               <p>{content}</p>
               </div> 
                </div>
            </div>
         </div>
       
        
  )
}

export default Image_Section
