import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/Web Portals/Web Portals.jpg'
import Top from '../../components/Top Section/Top'
import TopImage from '../../assets/images/Web Portals/Web Portals1.jpg'
import Features from '../../components/Features/Features'
import DeliveringSolutions from '../../components/Delivering solutions/Delivering_solutions';

const Web_Portals = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Web Portals"
        page_name="Web Portals"
        heading="Digital Access Portals"
      />

      {/* end hero section  */}


      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Centralized Web Portals for Seamless Interaction and Efficiency"
          content="TechTorch Solutions Private Limitedoffers robust Web Portal solutions that empower businesses to create engaging, user-friendly interfaces for their clients, partners, and employees. Our customizable web portals serve as centralized hubs for information, communication, and collaboration, enhancing user experiences and driving operational efficiency."
          image={TopImage}
          image_name="Web Portals"
          paras={[
            <p>
              <span>Industries We Serve with Web Portal Solutions: </span>
              Our web portals cater to a variety of sectors, including education, healthcare, e-commerce, manufacturing, and finance, each tailored to meet the specific needs and compliance requirements of the industry.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions for Web Portals? </span>
              With a commitment to innovation and user-centric design, our web portal solutions provide businesses with the tools they need to enhance communication and collaboration. Our dedicated team offers full implementation support, user training, and ongoing maintenance, ensuring that clients can maximize the value of their portal investment. By choosing TechTorch Solutions, organizations can create dynamic web environments that foster engagement and streamline operations.
            </p>
          ]}

        />
      </div>
      {/* End Top Section  */}


      {/* start Key Features section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Web Portal Solutions:"
          features={[
            <li>
              <span>Customizable User Interfaces: </span>
              Tailor the look and feel of the portal to align with your brand identity, ensuring a cohesive and professional presentation to users.
            </li>,
            <li>
              <span>Role-Based Access Control: </span>
              Implement granular access controls to ensure that users only see the information and tools relevant to their roles, enhancing security and user experience.

            </li>,
            <li>
              <span>Integration with Existing Systems: </span>
              Seamlessly connect with existing enterprise systems (such as CRM, ERP, and HRMS) to provide users with a unified experience and access to essential data in one place.
            </li>,
            <li>
              <span>Self-Service Capabilities: </span>
              Enable users to access information, submit requests, and
              manage tasks independently, reducing the burden on support teams and increasing user
              satisfaction.
            </li>,
            <li>
              <span>Analytics and Reporting: </span>
              Leverage built-in analytics tools to track user engagement,
              monitor portal performance, and gain insights that drive continuous improvement.
            </li>


          ]}
        />
      </div>
      {/* end Key Features section   */}

          <DeliveringSolutions/>
    </>
  )
}

export default Web_Portals
