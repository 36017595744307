import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CarouselComponent.css';


const slides = [
  {
    title: "Innovative Technology",
    description: "Transforming your business with cutting-edge technology.", 
  
  },
  {
    title: "Streamlined Processes", 
    description: "Enhance productivity with our smart solutions.", 
    
  },
  {
    title: "Data-Driven Decisions", 
    description: "Utilize analytics for better business outcomes.", 
   
  },
  {
    title: "Futuristic Technology", 
    description: "Embrace the future with our solutions.", 
   
  },
  {
    title: "Innovation at Its Best", 
    description: "Leading the way in technology.", 
   
  },
  {
    title: "Powerful Insights", 
    description: "Make informed decisions.", 
    
  },
  {
    title: "Unleashing Potential", 
    description: "Unlock your business's full potential.", 
     
  },
  {
    title: "Innovative Solutions", 
    description: "Innovate for a better tomorrow.", 
   
  },
  {
    title: "Excellence in Service", 
    description: "Delivering quality and reliability.", 
   
  },
  {
    title: "Driving Transformation", 
    description: "Transform your business landscape.", 
    
  },
  {
    title: "Global Leadership", 
    description: "Leading the charge in technology.", 
    
  },
  {
    title: "Future-Ready Solutions", 
    description: "Solutions for a changing world.", 
    
  },
];

const CarouselComponent = () => {
  return (
    <Carousel controls={false} indicators={false}>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <div className="carousel-background text-center">
            <div className='slider-content'>
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
              <Link to='/contact'><button className="home-btn">Connect With Us!</button></Link>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
