import React from 'react'
import './Card.css'

const Card = ({image,image_name,video_url,client_name,description}) => {
    const openYouTubeVideo =()=>{
        const windowFeatures = "width=500,height=400,scrollbars=no,resizable=no,top=100,left=100 status=no";
        window.open(video_url, "_blank", windowFeatures);

    }
  return (
    <div className="col-lg-3 col-md-4 col-sm-4 col-6">
    <div className="youtube-card">
        <img className='img-fluid' src={image} alt={image_name} />
        <i className="bi bi-play-circle" onClick={openYouTubeVideo}></i>
    </div>
      <h6>{client_name}</h6>
        <p><i>{description}</i></p>
    </div>
  )
}

export default Card
