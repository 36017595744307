import React from 'react'
import './Hero.css'
const Hero = ({image,image_name,page_name,heading,class_name}) => {
  return (
    <div className="hero it-hero">
         <img className={`hero-img ${class_name}`} src={image} alt={image_name} />
            <div className="hero-content-container">
                <div className="hero-content">
                <span>{`TechTorch / ${page_name}`}</span><hr/>
                <h1 className='hero-heading'>{heading}</h1>
                </div>
            </div>
      </div>
  )
}

export default Hero
