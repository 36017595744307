import React from 'react'
import './Card.css'

const Card = ({heading,content}) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className='card cloud-card'>
        <h5 className='mb-3'>{heading}</h5>
        <p>{content}</p>
        
      </div>
    </div>
  )
}

export default Card
