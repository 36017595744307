import React from 'react';
import { Link } from 'react-router-dom'
import './Card.css';

const Card = ({ image,image_name, text, heading,link}) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 xs-card-center text-center">
        <div className="card main-card mb-4">
          <div className='image-container'>
            <img className='img-fluid card-img' src={image} alt={image_name} />
            <h5 className='card-heading'>{heading}</h5> 
          </div>
          <div className='card-content'>
            <div className='content'>
              <p>{text}</p>
              <Link to={link} className='link main-btn'>Expand</Link>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Card;
