import React from 'react'
import Hero from '../../components/Hero/Hero';
import Industries from '../../components/Industries/Industries';
import heroImage from '../../assets/images/Energy/Hero.jpg';
import image from '../../assets/images/Energy/image.jpg'
const Energy = () => {
  return (
    <div>
    {/* start hero section  */}
    <Hero
        image={heroImage}
        image_name="Energy"
        page_name="Energy"
        heading="Reliable Energy for Every Need"
    />
  {/* end hero section  */}


{/* Start Content Section  */}
  <div className="container py-lg-5 py-md-4 py-3">
    <Industries
        heading1="SERVICES For "
        heading2="Energy"
        content="We enable energy companies to optimize resource management, enhance operational efficiency, and adopt sustainable practices. Our solutions empower organizations in the energy sector to meet regulatory demands, integrate renewable energy sources, and deliver reliable services to customers."
        image={image}
        image_name="Servies for energy"
    />
  </div>
  {/* End Content Section  */}

    </div>
  )
}

export default Energy
