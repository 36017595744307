import React from 'react'
import Hero from '../../components/Hero/Hero';
import Top from '../../components/Top Section/Top'
import TopImage from '../../assets/images/Operations Management/OM1.jpg'
import heroImage from '../../assets/images/Operations Management/Operations Management.jpg';
import Item from '../../components/Our Approach/Item';
import Features from '../../components/Features/Features'
import nc from '../../assets/images/AI/nc logo.svg'
import dell from '../../assets/images/AI/Dell_Logo.svg.png'

const Operations_Management = () => {
  return (
    <>
      {/* start hero section  */}
      <Hero
        image={heroImage}
        image_name="Operations Management"
        page_name="Operations Management"
        heading="Optimizing Efficiency in Operations Management"
      />
      {/* end hero section  */}

      {/* Start Top Section  */}
      <div className="container py-lg-5 py-3">
        <Top
          heading="Streamline Your Operations with TechTorch Solutions"
          content="At TechTorch Solutions, our Operations Management solutions empower businesses tostreamline, monitor, and optimize their operational processes. Designed to improve efficiency, reduce costs, and enhance productivity, these tools help organizations achieve seamless workflows and superior operational control."
          image={TopImage}
          image_name="Operations Management"
          paras={[
            <p>
              <span>Industries We Serve with Operations Management Solutions: </span>
              Our solutions cater to a wide range of industries, including manufacturing, logistics, healthcare, retail, and service sectors. Each solution is tailored to meet the operational needs specific to the industry, ensuring a precise fit and maximum value.
            </p>,
            <p>
              <span>Why Choose TechTorch Solutions Private Limitedfor Operations Management? </span>
              With our
              deep industry expertise, we guide businesses through every step of operational transformation.
              Our team provides comprehensive support from initial deployment to continuous
              improvement. Clients benefit from our customizable modules, user-friendly interfaces, and
              integration capabilities with existing ERP systems, making our solutions a natural extension of
              their current operations.
            </p>

          ]}

        />
      </div>

      {/* End Top Section  */}

     {/* Start Our approch section  */}
     <div className="bg-light-beetroot py-lg-5 py-3">
        <div className="container">
          <div className="row">
            <h5 className='our-approach-heading'>Our Approach</h5>
          </div>
          <div className="row">
            <Item
            icon={<i class="bi bi-cloud"></i>}
            text="Enable business vision"
            />

          <Item
            icon={<i class="bi bi-box"></i>}
            text="Empower data-led decisions"
            />

          <Item
            icon={<i class="bi bi-clouds"></i>}
            text="Build resilience"
            />
             <Item
            icon={<i class="bi bi-person"></i>}
            text="Accelerate innovation"
            />
            
          </div>
        </div>
      </div>
      {/* End Our approch section  */}

    

      {/* start Key Features o fourth section  */}
      <div className="container py-lg-5 py-3">
        <Features
          heading1="Benefits"
          heading2="Key Features of Our Operations Management Solutions:"
          features={[
            <li>
              <span>Workflow Automation: </span>
              Automates repetitive tasks and processes, allowing teams to focus on high-value activities and reducing manual intervention.
            </li>,
            <li>
              <span>Resource Allocation & Scheduling: </span>
              Provides powerful tools to allocate resources effectively, manage schedules, and optimize workforce productivity
            </li>,
            <li>
              <span>Real-Time Monitoring & Analytics: </span>
              Offers real-time insights into operational metrics, enabling proactive decision-making and early identification of bottlenecks
            </li>,
            <li>
              <span>Inventory and Supply Chain Management:  </span>
              Ensures that inventory levels are optimized, and supply chains are efficiently managed to meet demand without interruptions.

            </li>,
            <li>
              <span>Quality Control & Compliance Tracking: </span>
              Integrates quality management and compliance tracking features to help businesses maintain high standards and adhere to regulatory requirements.
            </li>
          ]}
        />

      </div>
      {/* end Key Features o fourth section */}

       {/* Start Partners in change Section  */}
       <div className='ai-container py-lg-5 py-md-4 py-3  mb-0'>
        <div className="container">
          <div className="row ">
            <h1 className='mb-lg-5'>Delivering solutions powered by</h1>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src="https://img.icons8.com/androidL/200/FFFFFF/amazon-web-services.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img google-img' src="https://white.logodownload.org/wp-content/uploads/2020/11/google-white-logo.png" alt="" />
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img microsoft-img' src="https://logodix.com/logo/1069296.png" alt="" />
            </div>
            
            
            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={nc} alt="nc" />
            </div>

            <div className="col-lg-3 col-sm-4 col-6">
              <img className='img-fluid ai-partners-img' src={dell} alt="dell" />
            </div>
          </div>
        </div>
      </div>
       {/* End Partners in change Section  */}


    </>
  )
}

export default Operations_Management
