import React from 'react'
import Hero from '../../components/Hero/Hero'
import heroImage from '../../assets/images/E-Commerce/hero.jpg'
import Industries from '../../components/Industries/Industries';
import image from '../../assets/images/E-Commerce/image.jpg'
const ECommerce = () => {
  return (
   <>

           {/* start hero section  */}
           <Hero
            image={heroImage}
            image_name="E-Commerce Solutions Made Simple"
            page_name="E-Commerce"
            heading="E-Commerce Solutions Made Simple"
        />
      {/* end hero section  */}

         <div className="container py-lg-5 py-md-4 py-3">
        <Industries
            heading1="Services For "
            heading2="E-Commerce"
            content="We provide innovative e-commerce solutions that enhance user experiences, streamline operations, and drive sales growth. Our platforms are designed to improve customer engagement and facilitate seamless transactions."
            image={image}
            image_name="Services For E-Commerce"
        />
         </div>
   </>
  )
}

export default ECommerce
