import React,{useRef} from 'react';
import './BPS.css'
import { Link } from 'react-router-dom';
import heroImage from '../../assets/images/BPS/Hero.jpg'
import Top from '../../components/Top Section/Top';
import TopImage from '../../assets/images/BPS/Top.jpg'
import Slider from '../../components/BPO Slider/Slider';

import Card from '../../components/Eduficera Card/Card';
import availability from '../../assets/images/BPS/availability.png';
import customer from '../../assets/images/BPS/customer.png';
import solutions from '../../assets/images/BPS/solutions.png';
import proactive from '../../assets/images/BPS/proactive.png';
import analysis from '../../assets/images/BPS/analysis.png';
import omnichannel from '../../assets/images/BPS/omnichannel.png';
import planning from '../../assets/images/BPS/planning.png';
import analysis1 from '../../assets/images/BPS/analysis1.png';
import multiChanner from '../../assets/images/BPS/multi-channel.png'


import bps1 from '../../assets/images/BPS/BPS1.jpg';
import bps2 from '../../assets/images/BPS/BPS2.jpg';
import bps3 from '../../assets/images/BPS/BPS3.jpg';
import bps4 from '../../assets/images/BPS/BPS4.jpg';
import bps5 from '../../assets/images/BPS/BPS5.jpg';
import bps6 from '../../assets/images/BPS/BPS6.jpg';
import bps7 from '../../assets/images/BPS/BPS7.jpg';
import bps8 from '../../assets/images/BPS/BPS8.jpg';
import bps9 from '../../assets/images/BPS/BPS9.jpg';
import bps10 from '../../assets/images/BPS/BPS10.jpg';
import bps11 from '../../assets/images/BPS/BPS11.jpg';
import bps12 from '../../assets/images/BPS/BPS12.jpg';
import bps13 from '../../assets/images/BPS/BPS13.jpg';
import bps14 from '../../assets/images/BPS/BPS14.jpg';
import Content from '../../components/BPO Section/Content';
import bottom from '../../assets/images/BPS/bottom.png'

const BPS = () => {
    const serviceSection=useRef(null);
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);
    const section6Ref = useRef(null);
    const section7Ref = useRef(null);
    const section8Ref = useRef(null);
    const section9Ref = useRef(null);
    const section10Ref = useRef(null);
    const section11Ref = useRef(null);
    const section12Ref = useRef(null);
    const section13Ref = useRef(null);
    const section14Ref = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
      };

    return (
        <>
            {/* Start Hero Section  */}
            <div className='bpo-hero'>
                <img className='img-fluid' src={heroImage} alt="BPO" />
                <div className="bpo-hero-content">
                    <h1 className='mb-4 text-center fw-bold display-2'>Outsource your Business  <span className='d-block'>Process Operations</span></h1>
                    <div>
                       <Link to="/contact"> <button className='main-btn fw-bold bpo-btn1'>Connect With Us</button> </Link>
                        <button className='main-btn fw-bold bpo-btn2' onClick={() => scrollToSection(serviceSection)}>All Service</button>
                    </div>

                </div>
            </div>
            {/* End Hero Section  */}

            {/* Start Top Section  */}
            <div className="container py-lg-5 py-3">
                <Top
                    heading="Streamlining Success with Tailored BPO Solutions"
                    content="At TechTorch Solutions, we specialize in Business Process Outsourcing (BPO) services designed to enhance operational efficiency and allow businesses to focus on their core competencies. Our BPO solutions encompass a wide range of functions, including customer service, administrative support, and IT management, tailored to meet the specific needs of each client. "
                    image={TopImage}
                    image_name="Business Process Outsourcing"
                    paras={[
                        <p className='bpo-top-content'>
                            By partnering with us, organizations can leverage our expertise and resources to reduce operational
                            costs, improve service quality, and achieve greater flexibility in their operations. Our dedicated
                            teams are committed to maintaining high standards of performance and delivering exceptional
                            results, enabling you to optimize your processes and enhance customer satisfaction. With
                            TechTorch’s BPO services, you gain a strategic advantage in today’s competitive marketplace.

                        </p>

                    ]}

                />
            </div>

            {/* End Top Section  */}

            

            {/* Start Link Section  */}
            <div className="container py-lg-5 py-md-4 py-3" ref={serviceSection}>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <h1>Elevate Your  Customer Support Service </h1>
                        <ul className='bpo-list'>
                           <li>
                            <span>Streamlined Communication Channels: </span>
                            Implement multi-channel support including phone, email, chat, and social media to ensure customers can reach you through their preferred platform.

                           </li>

                           <li>
                            <span>Personalized Customer Experience: </span>
                            Use data and CRM tools to tailor support interactions, making each customer feel valued and understood.
                           </li>
                           <li>
                            <span>Faster Response Times: </span>
                            Leverage AI chatbots and automated systems to provide instant replies, reducing wait times and increasing efficiency.
                           </li>
                           <li>
                            <span>Proactive Problem Solving: </span>
                            Anticipate issues before they arise with proactive alerts and regular follow-ups, ensuring seamless experiences for customers.
                           </li>
                           <li>
                            <span>Omnichannel Integration: </span>
                            Ensure all support channels are integrated, allowing for consistent communication and a seamless transition between different platforms.
                           </li>
                           <li>
                            <span>Continuous Improvement: </span>
                            Use feedback and analytics to constantly refine your support processes, training staff and adopting new technologies to stay ahead of customer expectations.
                           </li>
                           
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="card bpo-link-card p-3 ">
                            <h1 className='mb-3'>Explore Our Service</h1>
                            <ul className='bpo-link'>
                                <li onClick={() => scrollToSection(section1Ref)}>Customer Support and Call Center Services</li>
                                <li onClick={() => scrollToSection(section2Ref)}>Data Entry and Management</li>
                                <li onClick={() => scrollToSection(section3Ref)}>Accounting and Finance Outsourcing</li>
                                <li onClick={() => scrollToSection(section4Ref)}>IT Helpdesk and Technical Support</li>
                                <li onClick={() => scrollToSection(section5Ref)}>Back-Office Operations</li>
                                <li onClick={() => scrollToSection(section6Ref)}>Telemarketing and Lead Generation</li>
                                <li onClick={() => scrollToSection(section7Ref)}>Marketing and Digital Services</li>
                                <li onClick={() => scrollToSection(section8Ref)}>Legal Process Outsourcing (LPO)</li>
                                <li onClick={() => scrollToSection(section9Ref)}>Procurement and Supply Chain Management</li>
                                <li onClick={() => scrollToSection(section10Ref)}>Healthcare BPO</li>
                                <li onClick={() => scrollToSection(section11Ref)}>Knowledge Process Outsourcing (KPO)</li>
                                <li onClick={() => scrollToSection(section12Ref)}>Content Moderation and Management</li>
                                <li onClick={() => scrollToSection(section13Ref)}>Sales Support and Order Management</li>
                                <li onClick={() => scrollToSection(section14Ref)}>Virtual Assistance and Administrative Support</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Link Section  */}

            {/* Start Slider Section  */}
            <Slider />
            {/* End Slider Section  */}


            {/* Start End-to-End eCommerce Customer Service  */}
            <div className="container py-lg-5 py-md-4 py-3">
                <div className="row mb-lg-5 mb-md-4 mb-3">
                    <h1 className='text-center'>Comprehensive Coustomer Suport Services</h1>
                </div>
                <div className="row">
                    <Card
                        image={availability}
                        image_name="24/7 Availability"
                        heading='24/7 Availability'
                        content="We provide round-the-clock customer support, ensuring that your customers receive assistance whenever they need it—day or night."
                    />

                    <Card
                        image={customer}
                        image_name="Expert and Trained Support Team"
                        heading='Expert and Trained Support Team'
                        content="Our team of highly skilled professionals delivers knowledgeable, courteous, and efficient support to help resolve customer inquiries quickly and effectively."
                    />

                    <Card
                        image={solutions}
                        image_name="Scalable Solutions"
                        heading='Scalable Solutions'
                        content="Whether you need support for a small business or a large enterprise, our BPO services are flexible and scalable, tailored to meet your specific needs."
                    />

                    <Card
                        image={proactive}
                        image_name="Proactive Customer Service"
                        heading='Proactive Customer Service'
                        content="Our team is trained to proactively address issues before they become problems, helping you maintain smooth operations and improve customer satisfaction."
                    />
                     <Card
                        image={omnichannel}
                        image_name="Omnichannel Integration"
                        heading='Omnichannel Integration'
                        content="Our integrated support platform allows us to provide a seamless customer experience across all channels, from chat to phone calls, while maintaining the context of each interaction."
                    />
                     <Card
                        image={analysis}
                        image_name="Cost-Effective Services"
                        heading='Cost-Effective Services'
                        content="By outsourcing customer support, you can reduce costs, optimize resources, and focus on core business functions, while we take care of your customers’ needs efficiently."
                    />
                     <Card
                        image={planning}
                        image_name="Customizable Support Plans"
                        heading='Customizable Support Plans'
                        content="We offer tailored support plans that can be customized to suit your business requirements, ensuring that you receive the right level of service for your unique needs."
                    />
                     <Card
                        image={analysis1}
                        image_name="Comprehensive Analytics and Reporting"
                        heading='Comprehensive Analytics and Reporting'
                        content="We provide detailed performance analytics and reporting to help you track customer interactions, measure satisfaction, and make data-driven decisions for continuous improvement."
                    />

                    <Card
                        image={multiChanner}
                        image_name="Integrated Multi-Channel Support"
                        heading='Integrated Multi-Channel Support'
                        content="Our BPO services offer support across various channels including phone, email, live chat, and social media, ensuring your customers can reach you through their preferred method of communication."
                    />

                 
                </div>
            </div>
            {/* End End-to-End eCommerce Customer Service  */}

            {/* Start  Link Section  */}
            <div className='container-fluid'>
            <div className="container py-lg-5 py-md-4 py-3" ref={section1Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps1} alt="BPO" />
                    </div>
                    <Content
                    heading="Customer Support and Call Center Services"
                    content="Offer 24/7 customer support, helpdesk, and call center services, including inbound/outbound customer service, technical support, and complaint resolution."
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section2Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="Data Entry and Management"
                    content="Provide services for data entry, data processing, and database management to help clients maintain accurate and up-to-date records."
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps2} alt="BPO" />
                    </div>
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section3Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps3} alt="BPO" />
                    </div>
                    <Content
                    heading="Accounting and Finance Outsourcing"
                    content="Offer bookkeeping, accounts payable/receivable, financial reporting, tax preparation, and payroll processing services."
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section4Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="IT Helpdesk and Technical Support:"
                    content=" Provide outsourced IT support for hardware, software, and network troubleshooting, as well as remote technical assistance for clients"
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps4} alt="BPO" />
                    </div>
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section5Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps5} alt="BPO" />
                    </div>
                    <Content
                    heading="Back-Office Operations"
                    content="Handle administrative tasks such as document management, order processing, claims processing, and billing support to allow businesses to focus on their core activities."
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section6Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="Telemarketing and Lead Generation"
                    content="Offer telemarketing, outbound sales calls, lead generation, and appointment-setting services to help businesses boost their sales pipeline."
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps6} alt="BPO" />
                    </div>
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section7Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps7} alt="BPO" />
                    </div>
                    <Content
                    heading="Marketing and Digital Services"
                    content="Provide digital marketing support, including SEO, social media management, content creation, email marketing, and PPC campaign management"
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section8Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="Legal Process Outsourcing (LPO)"
                    content="Offer legal support services such as document review, legal research, contract management, and compliance monitoring for law firms and legal departments."
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps8} alt="BPO" />
                    </div>
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section9Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps9} alt="BPO" />
                    </div>
                    <Content
                    heading="Procurement and Supply Chain Management"
                    content="Provide procurement services, supplier management, inventory tracking, and supply chain optimization to improve client operational efficiency."
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section10Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="Healthcare BPO"
                    content="Provide specialized services for healthcare providers, including medical billing, claims processing, patient scheduling, and health information management."
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps10} alt="BPO" />
                    </div>
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section11Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps11} alt="BPO" />
                    </div>
                    <Content
                    heading="Knowledge Process Outsourcing (KPO)"
                    content="Offer high-value knowledge services such as market research, data analysis, financial research, and risk management consulting."
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section12Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="Content Moderation and Management:"
                    content="Provide content moderation services for online platforms, ensuring that user-generated content adheres to policies and community guidelines."
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps12} alt="BPO" />
                    </div>
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section13Ref}>
                <div className="row" >
                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps13} alt="BPO" />
                    </div>
                    <Content
                    heading="Sales Support and Order Management:"
                    content="Manage sales-related activities like order processing, customer inquiries, inventory management, and post-sale support.
"
                    />
                </div>
            </div>

            <div className="container py-lg-5 py-md-4 py-3" ref={section14Ref}>
                <div className="row flex-column-reverse flex-md-row" >
                   
                    <Content
                    heading="Virtual Assistance and Administrative Support"
                    content="Provide virtual assistants for tasks such as scheduling, email management, travel arrangements, and other administrative dutie"
                    />

                    <div className="col-lg-6 col-md-6">
                        <img className='img-fluid rounded-4' src={bps14} alt="BPO" />
                    </div>
                </div>
            </div>

            </div>
            {/* End  Link Section  */}

            {/* Start Bottom Section  */}
            <div className="container-fuid py-lg-5 py-md-4 py-3 bg-light-beetroot">
                    <div className="row gx-0">
                        <div className="col-lg-6 col-md-6 d-flex aligh-items-center justify-content-center">
                            <img className='img-fluid' src={bottom} alt="" />
                        </div>
                        <div className="col-lg-6 col-md-6 ps-5">
                        <h1 className='mb-3'>Explore Our Service</h1>
                            <ul className='bpo-link'>
                                <li onClick={() => scrollToSection(section1Ref)}>Customer Support and Call Center Services</li>
                                <li onClick={() => scrollToSection(section2Ref)}>Data Entry and Management</li>
                                <li onClick={() => scrollToSection(section3Ref)}>Accounting and Finance Outsourcing</li>
                                <li onClick={() => scrollToSection(section4Ref)}>IT Helpdesk and Technical Support</li>
                                <li onClick={() => scrollToSection(section5Ref)}>Back-Office Operations</li>
                                <li onClick={() => scrollToSection(section6Ref)}>Telemarketing and Lead Generation</li>
                                <li onClick={() => scrollToSection(section7Ref)}>Marketing and Digital Services</li>
                                <li onClick={() => scrollToSection(section8Ref)}>Legal Process Outsourcing (LPO):</li>
                                <li onClick={() => scrollToSection(section9Ref)}>Procurement and Supply Chain Management</li>
                                <li onClick={() => scrollToSection(section10Ref)}>Healthcare BPO</li>
                                <li onClick={() => scrollToSection(section11Ref)}>Knowledge Process Outsourcing (KPO)</li>
                                <li onClick={() => scrollToSection(section12Ref)}>Content Moderation and Management</li>
                                <li onClick={() => scrollToSection(section13Ref)}>Sales Support and Order Management</li>
                                <li onClick={() => scrollToSection(section14Ref)}>Virtual Assistance and Administrative Support</li>

                            </ul>
                        </div>
                    </div>
            </div>
            {/* End Bottom Section  */}


        </>
    )
}

export default BPS
