import React from 'react'
import Hero from '../../components/Hero/Hero';
import heroImage from '../../assets/images/Finance/Hero.jpg';
import Industries from '../../components/Industries/Industries';
import image from '../../assets/images/Finance/image1.jpg'
const Finance = () => {
  return (
   <>
     {/* start hero section  */}
     <Hero
        image={heroImage}
        image_name="Finance"
        page_name="Finance"
        heading="Solutions for Your Financial Needs"
      />
      {/* end hero section  */}

      <div className="container py-lg-5 py-md-4 py-3">
        <Industries
            heading1="SERVICES For "
            heading2="FINANCE"
            content="We deliver financial management solutions that enhance reporting, budgeting, and compliance for financial institutions and businesses. Our tools help organizations gain better control over their financial data and make informed decisions. "
            image={image}
            image_name="FINANCIAL SERVICES"
        />
      </div>
   </>
  )
}

export default Finance
