import React, { useState,useRef} from 'react'
import './Digital_Solutions.css'
import TabCard from '../IndustriesTab/TabCard/TabCard';


const Digital_Solutions = () => {
    const [activeTab, setActiveTab] = useState('ERP');
    const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    const scrollAmount = 200; // Adjust this value as needed

    if (direction === "left") {
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    } else if (direction === "right") {
      container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };


    return (
        <div className='container py-lg-5 py-md-4 py-3 nav-container'>
            <h1 className='main-heading'>Digital Solutions</h1><hr className='main-hr' />
            <div className='main-tab-container'>

            <ul  ref={scrollContainerRef} className="nav home-nav" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link ${activeTab === 'ERP' ? 'active' : ''}`}
                        id="ERP-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="ERP"
                        aria-selected={activeTab === 'ERP'}
                        onClick={() => setActiveTab('ERP')}>ERP</button>

                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Operations Management' ? 'active' : ''}`}
                        id="Operations-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Operations Management"
                        aria-selected={activeTab === 'Operations Management'}
                        onClick={() => setActiveTab('Operations Management')}
                    >  Operations Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Supply Chain Management' ? 'active' : ''}`}
                        id="Supply-Chain-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Supply Chain Management"
                        aria-selected={activeTab === 'Supply Chain Management'}
                        onClick={() => setActiveTab('Supply Chain Management')}
                    >  Supply Chain Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Aviation Management' ? 'active' : ''}`}
                        id="Aviation-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Aviation Management"
                        aria-selected={activeTab === 'Aviation Management'}
                        onClick={() => setActiveTab('Aviation Management')}
                    >  Aviation Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'People Resources' ? 'active' : ''}`}
                        id="People-Resources-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="People Resources"
                        aria-selected={activeTab === 'People Resources'}
                        onClick={() => setActiveTab('People Resources')}
                    >  People Resources
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Web Portals' ? 'active' : ''}`}
                        id="Web-Portals-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Web Portals"
                        aria-selected={activeTab === 'Web Portals'}
                        onClick={() => setActiveTab('Web Portals')}
                    >  Web Portals
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Financial Management' ? 'active' : ''}`}
                        id="Financial-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Financial Management"
                        aria-selected={activeTab === 'Financial Management'}
                        onClick={() => setActiveTab('Financial Management')}
                    >  Financial Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Payment Management' ? 'active' : ''}`}
                        id="Payment-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Payment Management"
                        aria-selected={activeTab === 'Payment Management'}
                        onClick={() => setActiveTab('Payment Management')}
                    >  Payment Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'HealthCare & Hospital Management' ? 'active' : ''}`}
                        id="HealthCare-Hospital-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="HealthCare & Hospital Management"
                        aria-selected={activeTab === 'HealthCare & Hospital Management'}
                        onClick={() => setActiveTab('HealthCare & Hospital Management')}
                    >  HealthCare & Hospital Management
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'CRM' ? 'active' : ''}`}
                        id="CRM-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="CRM"
                        aria-selected={activeTab === 'CRM'}
                        onClick={() => setActiveTab('CRM')}
                    >  CRM
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Ecommerce' ? 'active' : ''}`}
                        id="Ecommerce-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Ecommerce"
                        aria-selected={activeTab === 'Ecommerce'}
                        onClick={() => setActiveTab('Ecommerce')}
                    >  Ecommerce
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link tab-link  ${activeTab === 'Project Management' ? 'active' : ''}`}
                        id="Project-Management-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="Project Management"
                        aria-selected={activeTab === 'Project Management'}
                        onClick={() => setActiveTab('Project Management')}
                    >  Project Management
                    </button>
                </li>

            </ul>

            <div className="ds-icon">
                    <i className="bi bi-chevron-left" onClick={() => handleScroll("right")}></i>
                    <i className="bi bi-chevron-right" onClick={() => handleScroll("left")}></i>
            </div>
            </div>
           

            <div className="tab-content pt-3" id="myTabContent">
                <div
                    className={`tab-pane fade ${activeTab === 'ERP' ? 'show active' : ''}`}
                    id="ERP"
                    role="tabpanel"
                    aria-labelledby="EERP-tab"
                >
                    <div className='d-flex'>
                        <TabCard
                            heading="Enterprise Resource Planning"
                            content="Our ERP solutions at TechTorch Solutions Private Limitedare designed to streamline and integrate various business processes into a single, unified system."
                            link='/erp'

                        />

                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Operations Management' ? 'show active' : ''}`}
                    id="Operations Management"
                    role="tabpanel"
                    aria-labelledby="Operations-Management-tab" >
                    <div className='d-flex'>
                        <TabCard
                            heading="Operations Management"
                            content="At TechTorch Solutions, our Operations Management solutions empower businesses to streamline, monitor, and optimize their operational processes."
                            link="/operations-management"
                        />

                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Supply Chain Management' ? 'show active' : ''}`}
                    id="Supply Chain Management"
                    role="tabpanel"
                    aria-labelledby="Supply-Chain-Management-tab" >
                    <div className='d-flex'>
                        <TabCard
                            heading="Supply Chain Management"
                            content="TechTorch Solutions Private Limitedoffers comprehensive Supply Chain Management (SCM) solutions designed to enhance visibility, improve efficiency, and reduce costs throughout the supply chain."
                            link="/supply-chain"
                        />

                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Aviation Management' ? 'show active' : ''}`}
                    id="Aviation Management"
                    role="tabpanel"
                    aria-labelledby="Aviation-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Aviation Management"
                            content="TechTorch Solutions Private Limitedprovides innovative Aviation Management solutions designed to enhance operational efficiency, safety, and compliance within the aviation industry."
                            link="/aviation-management"
                         />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'People Resources' ? 'show active' : ''}`}
                    id="People Resources"
                    role="tabpanel"
                    aria-labelledby="People Resources-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="People Resources"
                            content="At TechTorch Solutions, our People Resources solutions are designed to streamline and enhance the management of human capital within organizations. "
                            link="/people-resources"
                         />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Web Portals' ? 'show active' : ''}`}
                    id="Web Portals"
                    role="tabpanel"
                    aria-labelledby="Web-Portals-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Web Portals"
                            content="TechTorch Solutions Private Limitedoffers robust Web Portal solutions that empower businesses to create engaging, user-friendly interfaces for their clients, partners, and employees" 
                            link="/web-portals"
                            />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Financial Management' ? 'show active' : ''}`}
                    id="Financial Management"
                    role="tabpanel"
                    aria-labelledby="Financial-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Financial Management"
                            content="TechTorch Solutions Private Limitedoffers comprehensive Financial Management solutions designed to streamline financial operations, improve accuracy, and enhance decision-making for businesses of all sizes." 
                            link="/financial-management"
                            />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Payment Management' ? 'show active' : ''}`}
                    id="Payment Management"
                    role="tabpanel"
                    aria-labelledby="Payment-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Payment Management"
                            content="TechTorch Solutions Private Limitedprovides advanced Payment Management solutions that simplify and streamline the payment process for businesses across various industries."
                            link="/payment-management"
                         />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'HealthCare & Hospital Management' ? 'show active' : ''}`}
                    id="HealthCare & Hospital Management"
                    role="tabpanel"
                    aria-labelledby="HealthCare-Hospital-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="HealthCare & Hospital Management"
                            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo odio quis arcu bibendum ullamcorper. Suspendisse sed arcu nec ante convallis semper. Quisque facilisis et arcu sit amet semper." />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'CRM' ? 'show active' : ''}`}
                    id="CRM"
                    role="tabpanel"
                    aria-labelledby="CRM-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Customer Relationship Management"
                            content="TechTorch Solutions Private Limitedoffers a comprehensive Customer Relationship Management (CRM) platform designed to help businesses build and maintain strong relationships with their customers." 
                            link="/crm"
                            />
                        
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Ecommerce' ? 'show active' : ''}`}
                    id="Ecommerce"
                    role="tabpanel"
                    aria-labelledby="Ecommerce-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="E-Commerce"
                            content="TechTorch Solutions Private Limitedprovides innovative E-Commerce solutions designed to empower businesses to establish and grow their online presence"
                            link="/ecommerce"
                            
                            />
                       
                    </div>
                </div>

                <div
                    className={`tab-pane fade ${activeTab === 'Project Management' ? 'show active' : ''}`}
                    id="Project Management"
                    role="tabpanel"
                    aria-labelledby="Project-Management-tab" >
                    <div className="d-flex">
                        <TabCard
                            heading="Project Management"
                            content="TechTorch Solutions Private Limitedprovides powerful Project Management solutions designed to enhance collaboration, streamline workflows, and drive project success." 
                            link="/project-management"
                            
                        />
                        
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Digital_Solutions
